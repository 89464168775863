import React, {useState, useEffect} from 'react'
import API_REQUEST from '../../../config/client';
import Get_direct_image from '../../../config/get_direct_image';
import {useLocation} from 'react-router-dom';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
// import AWS from 'aws-sdk';

function ProductImageGallery(props){

  const [items,getItem] = useState(null);
  const [list1, getList] = useState(null);
  const id = props.product_id

  useEffect(() => {
    var getimageResults = async () => {
      await API_REQUEST('single_product',{action:'get_images_path',product_id:id}).then((response) => getItem(response))
    }
    getimageResults()
    //console.log("items ",items);
  },[id])
 

  var list = [];

  useEffect(() => {
    if(items != null){ 
        items.Data.map(i => (
            list.push({
              original: Get_direct_image("products/" + id, i.path),
              thumbnail: Get_direct_image("products/" + id, i.path)
            },)
          ))


        getList(list);
        getItem(null);
        
    }
    //console.log("Pranav")
    //console.log(list1)
  },[items]);
  
    const images = [
      {
        original: 'https://picsum.photos/id/1018/1000/600/',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
      },
      {
        original: 'https://picsum.photos/id/1015/1000/600/',
        thumbnail: 'https://picsum.photos/id/1015/1000/600/',
      },
      {
        original: 'https://picsum.photos/id/1019/1000/600/',
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
      },
    ];

    return(
      <>{
          list1? <ImageGallery items={list1} showPlayButton={false}/> : <br />
        }
       </>
    )
}

export default ProductImageGallery
