import React,{ useState } from 'react';

import "../../../assets/css/style.css";
import "../../../assets/js/main.js";
import "../../../assets/css/font-awesome.min.css";
import "../../../assets/css/Cards.css";
import act from "../../../assets/img/action.jpg" 
import adv from "../../../assets/img/adventure.jpg" 
import fig from "../../../assets/img/fighting.jpg" 
import fps from "../../../assets/img/fps.jpg" 
import rac from "../../../assets/img/racing 2.jpg" 
import rp3 from "../../../assets/img/roleplay 3.jpg" 

import {CloseOutlined } from '@ant-design/icons';

import API_REQUEST from '../../../config/client';
import {useSelector} from "react-redux";

function Foryou(){
   const img_path = "../../assets/img";
   const {
      isLoggedIn
   } = useSelector(state => state.auth);
   const {
      user
   } = useSelector(state => state.auth);

   var id = 0;

   if (isLoggedIn) {
      id = user.id;
      if (!id) {
         id = JSON.parse(user).id;
      }
   } else {
      id = 0;
   }


   function sleeper(ms) {
      return function(x) {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
      };
    }

  const [items,setItem] = useState({type1:0,type2:0,type3:0,type4:0,type5:0,type6:0});

  const toggle1 = (id) => {
   if(id.target.value!==items.type1){
      items.type1 = id.target.value;
      setItem(items)
   }
   else{
      items.type1 = 0;
      setItem(items)
   }
}

const toggle2 = (id) => {
   if(id.target.value!==items.type2){
      items.type2 = id.target.value;
      setItem(items)
   }
   else{
      items.type2 = 0;
      setItem(items)
   }
}

const toggle3 = (id) => {
   if(id.target.value!==items.type3){
      items.type3 = id.target.value;
      setItem(items)
   }
   else{
      items.type3 = 0;
      setItem(items)
   }
}


const toggle4 = (id) => {
   if(id.target.value!==items.type4){
      items.type4 = id.target.value;
      setItem(items)
   }
   else{
      items.type4 = 0;
      setItem(items)
   }
}


const toggle5 = (id) => {
   if(id.target.value!==items.type5){
      items.type5 = id.target.value;
      setItem(items)
   }
   else{
      items.type5 = 0;
      setItem(items)
   }
}

const toggle6 = (id) => {
   if(id.target.value!==items.type6){
      items.type6 = id.target.value;
      setItem(items)
   }
   else{
      items.type6 = 0;
      setItem(items)
   }
}


const handleReq = async (e) => {
   e.preventDefault();
   var request = {action:'foryou','items':items, 'custId':id}

   await API_REQUEST('home', request).then(sleeper(30)).then((response) => setItem(null));
   window.location.reload();
   }


return(
<>

    {/* <section className="fag-product-page section_100">
         <div className="container">
            <div className="row">
    <div className="search-block active">
         <a href="#" className="search-toggle">
                     <UserOutlined />
                     </a>
                     <form onSubmit={handleReq}>
                     <div className="filter">
                        <h2 className="filter_title">FOR YOU</h2>
                        
                        <div class="filter_group">
                           <label class="filter_label">GENRE:</label>
                           <ul class="filter_checkboxes">
                              <li class="custom-checkbox">
                                 <input id="type1" type="checkbox" name="type1" value={76} onChange={toggle1} />
                                 <label for="type1">ACTION</label>
                                 <span class="checkbox"></span>
                              </li>
                              <li class="custom-checkbox">
                                 <input id="type2" type="checkbox" name="type2" value={77} onChange={toggle2} />
                                 <label for="type2">ADVENTURE</label>
                                 <span class="checkbox"></span>
                              </li>
                              <li class="custom-checkbox">
                                 <input id="type3" type="checkbox" name="type3" value={82} onChange={toggle3} />
                                 <label for="type3">RACING</label>
                                 <span class="checkbox"></span>
                              </li>
                              <li class="custom-checkbox">
                                 <input id="type4" type="checkbox" name="type4" value={84} onChange={toggle4} />
                                 <label for="type4">FIGHTING</label>
                                 <span class="checkbox"></span>
                              </li>
                              <li class="custom-checkbox">
                                 <input id="type5" type="checkbox" name="type5" value={78} onChange={toggle5} />
                                 <label for="type5">ROLE PLAING</label>
                                 <span class="checkbox"></span>
                              </li>
                              <li class="custom-checkbox">
                                 <input id="type6" type="checkbox" name="type6" value={79} onChange={toggle6} />
                                 <label for="type6">FPS</label>
                                 <span class="checkbox"></span>
                              </li>
                           </ul>
                        </div>
                        
                        <div class="filter_group">
                           <button class="fag-btn" type="submit">APPLY FILTER</button>
                        </div>
                     </div>
                  
                     </form>
    </div>
    </div>
    </div>
    </section> */}
      <section className="fag-product-page section_100"  >
         <div className="container_f">
            <div className="row">
               <div className="search-block active">
                  
                  <div className="wrapper_f">
                     <div className="title">
                        FOR YOU
                     </div>
                     
                     <div className="genre"> 
                        PLEASE SELECT YOUR GENRE 
                        <a href="/" className="search-toggle">
                           <CloseOutlined/>
                        </a>
                     </div>
                     
                     <form onSubmit={handleReq}>
                        <div className="container_f container">
                           <label className="option_item">
                              <input type="checkbox" className="checkbox" id="type1" name="type1" value={76} onChange={toggle1} />
                              <div className="option_inner action">
                                 <div className="tickmark"></div>
                                 <div className="image-2">
                                    <img src={act}></img>
                                 </div>
                                 <div className="name"> ACTION </div>
                              </div>
                           </label>

                           <label className="option_item">
                              <input type="checkbox" className="checkbox" id="type2" name="type2" value={77} onChange={toggle2} />
                              <div className="option_inner adventure">
                                 <div className="tickmark"></div>
                                 <div className="image-2">
                                    <img src={adv} ></img>
                                 </div>
                                 <div className="name"> ADVENTURE </div>
                              </div>
                           </label>

                           <label className="option_item">
                              <input type="checkbox" className="checkbox" id="type4" name="type4" value={84} onChange={toggle4} />
                              <div className="option_inner fighting">
                                 <div className="tickmark"></div>
                                 <div className="image-2">
                                    <img src={fig}></img>
                                 </div>
                                 <div className="name"> FIGHTING </div>
                              </div>
                           </label>

                           <label className="option_item">
                              <input type="checkbox" className="checkbox" id="type3" name="type3" value={82} onChange={toggle3} />
                              <div className="option_inner racing">
                                 <div className="tickmark"></div>
                                 <div className="image-2">
                                    <img src={rac} ></img>
                                 </div>
                                 <div className="name"> RACING </div>
                              </div>
                           </label>

                           <label className="option_item">
                              <input type="checkbox" className="checkbox" id="type5" name="type5" value={78} onChange={toggle5} />
                              <div className="option_inner roleplay">
                                 <div className="tickmark"></div>
                                 <div className="image-2">
                                    <img src={rp3} ></img>
                                 </div>
                                 <div className="name"> ROLEPLAY </div>
                              </div>
                           </label>

                           <label className="option_item">
                              <input type="checkbox" className="checkbox" id="type6" name="type6" value={79} onChange={toggle6} />
                              <div className="option_inner fps">
                                 <div className="tickmark"></div>
                                 <div className="image-2">
                                    <img src={fps} ></img>
                                 </div>
                                 <div className="name"> FPS </div>
                              </div>
                           </label>
                        </div>
                        <button className="hero-btn" type="submit">APPLY FILTER</button>
                        <br></br>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
</>
);


}

export default Foryou;