import React, {useState, useEffect, useCallback } from 'react';
import { Slider } from 'antd';
import Spinner from 'react-bootstrap/Spinner'
import { Form , Col, Row } from 'react-bootstrap';
import Topic from "../ui/topic";
import H5 from "../ui/h5";
import API_REQUEST from '../../config/client';
import Cardi from './productCard';
import Cardis from './prodCard';
import ReactPaginate from 'react-paginate';
import {useLocation} from 'react-router-dom';
import { Collapse } from 'antd';



function Shop(){
    const { Panel } = Collapse;
    const [platforms, setPlatforms] = useState({
        c1: 0,
        c2: 0,
        c3: 0,
        c4: 0,
        c10: 0,
        c11: 0,
        c12: 0,
        c13: 0,
        c14: 0,
        c15: 0,
        c16: 0,
    });
    const [genre,setGenre] = useState({c5:0,c6:0,c7:0,c8:0,c9:0});
    const [priceRange, setPriceRange] = useState ({pr1:10,pr2:3000000});
    const [items, getItems] = useState(null);
    const [data, setData] = useState("");
    var DisplayProdDetails = null;
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(12);
    const [pageCount, setPageCount] = useState(0);
    const [search_, setSearch_] = useState("");
    const [sort, setSort] = useState("");
    const loc = useLocation().search
    const prodId = new URLSearchParams(loc).get('id');  
    const [productQty, getProductQty] = useState(null);
    var searchString = new URLSearchParams(loc).get('s1');
    if (!searchString){
        searchString = "";
    }

    function sleeper(ms) {
        return function (x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }

    // Scroll to top when page renders
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    // const onChangeS3 = (id) => {
    //     if (id.target.value !== filter.s3) {
    //         filter.s3 = id.target.value;
    //         setFilter(filter)
    //     } else {
    //         filter.s3 = null;
    //         setFilter(filter)
    //     }
    // }

    function onChangePR1(value) {
        //console.log('onChange: ', value);
    }

    function onAfterChangePR1(value) {
        priceRange.pr1 = value[0];
        priceRange.pr2 = value[1];
        setPriceRange(priceRange);
    }

    
    const onChangeC1 = (id) => {
        if(id.target.value!==platforms.c1){ 
            //console.log(id.target.value);
            platforms.c1 = id.target.value;
            setPlatforms(platforms)
        }
        else{
            platforms.c1 = 0;
            setPlatforms(platforms)
           }
        }

    const onChangeC2 = (id) => {
        if(id.target.value!==platforms.c2){
            //console.log(id.target.value);
            platforms.c2 = id.target.value;
            setPlatforms(platforms)
        }
        else{
            platforms.c2 = 0;
            setPlatforms(platforms)
            }
        }    

    const onChangeC10 = (id) => {
        if (id.target.value !== platforms.c10) {
            //console.log(id.target.value);
            platforms.c10 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c10 = 0;
            setPlatforms(platforms)
        }
    }

    const onChangeC11 = (id) => {
        if (id.target.value !== platforms.c11) {
            //console.log(id.target.value);
            platforms.c11 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c11 = 0;
            setPlatforms(platforms)
        }
    }

    const onChangeC12 = (id) => {
        if (id.target.value !== platforms.c12) {
            //console.log(id.target.value);
            platforms.c12 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c12 = 0;
            setPlatforms(platforms)
        }
    }

    const onChangeC13 = (id) => {
        if (id.target.value !== platforms.c13) {
            //console.log(id.target.value);
            platforms.c13 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c13 = 0;
            setPlatforms(platforms)
        }
    }
    const onChangeC14 = (id) => {
        if (id.target.value !== platforms.c14) {
            //console.log(id.target.value);
            platforms.c14 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c14 = 0;
            setPlatforms(platforms)
        }
    }
    const onChangeC15 = (id) => {
        if (id.target.value !== platforms.c15) {
            //console.log(id.target.value);
            platforms.c15 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c15 = 0;
            setPlatforms(platforms)
        }
    }
    const onChangeC16 = (id) => {
        if (id.target.value !== platforms.c16) {
            //console.log(id.target.value);
            platforms.c16 = id.target.value;
            setPlatforms(platforms)
        } else {
            platforms.c16 = 0;
            setPlatforms(platforms)
        }
    }

    const onChangeC3 = (id) => {
        if(id.target.value!==platforms.c3){
            //console.log(id.target.value);
            platforms.c3 = id.target.value;
            setPlatforms(platforms)
        }
        else{
            platforms.c3 = 0;
            setPlatforms(platforms)
            }
        }
        
    const onChangeC4 = (id) => {
        if(id.target.value!==platforms.c4){
            //console.log(id.target.value);
            platforms.c4 = id.target.value;
            setPlatforms(platforms)
        }
        else{
            platforms.c4 = 0;
            setPlatforms(platforms)
            }
        }  

    const onChangeC5 = (id) => {
        if (id.target.value !== genre.c5) {
            //console.log(id.target.value);
            genre.c5 = id.target.value;
            setGenre(genre)
        }
        else{
            genre.c5 = 0;
            setGenre(genre)
            }
        } 

    const onChangeC6 = (id) => {
        if (id.target.value !== genre.c6) {
            //console.log(id.target.value);
            genre.c6 = id.target.value;
            setGenre(genre)
        } else {
            genre.c6 = 0;
            setGenre(genre)
        }
    }

    const onChangeC7 = (id) => {
        if (id.target.value !== genre.c7) {
            //console.log(id.target.value);
            genre.c7 = id.target.value;
            setGenre(genre)
        } else {
            genre.c7 = 0;
            setGenre(genre)
        }
    }

    const onChangeC8 = (id) => {
        if (id.target.value !== genre.c8) {
            //console.log(id.target.value);
            genre.c8 = id.target.value;
            setGenre(genre)
        } else {
            genre.c8 = 0;
            setGenre(genre)
        }
    }

    const onChangeC9 = (id) => {
        if (id.target.value !== genre.c9) {
            //console.log(id.target.value);
            genre.c9 = id.target.value;
            setGenre(genre)
        } else {
            genre.c9 = 0;
            setGenre(genre)
        }
    }
    
    const onChangeS1 = (id) => {
        const fs1 = id.target.value;
        setSearch_(fs1);
    };

    const onChangeS2 = (id) => {
        const fs2 = id.target.value;
        setSort(fs2);
    };
    
    useEffect(() => {
        
        var getData = async () => {
             var request = {
                 action: 'getProdDetails',
                 searchString: searchString,
                 sortString: sort,
                 categoryString: prodId,
                 priceMin: priceRange.pr1,
                 priceMax: priceRange.pr2,
                 plat1: platforms.c1,
                 plat2: platforms.c2,
                 plat3: platforms.c3,
                 plat4: platforms.c4,
                 plat5: platforms.c10,
                 plat6: platforms.c11,
                 plat7: platforms.c12,
                 plat8: platforms.c13,
                 plat9: platforms.c14,
                 plat10: platforms.c15,
                 plat11: platforms.c16,
                 gen1: genre.c5,
                 gen2: genre.c6,
                 gen3: genre.c7,
                 gen4: genre.c8,
                 gen5: genre.c9,
            } 
            await API_REQUEST('fetchData',  request ).then((response) => getItems(response));
        }
        getData();
        setSearch_("");
    }, [offset]);

         if(items != null){
             if (items.status === 404){
            window.location.href="/shop";
        }
            var slices = items.Data.slice(offset, offset + perPage)
                DisplayProdDetails = slices.map(item => (
                     <Cardis  key={item.ProductID} 
                             Name={item.Name} 
                             ProdImg={item.Image} 
                             desc={item.desc} 
                             prodId ={item.sProductID} 
                             offerprice={item.offerprice} 
                             price={item.price} 
                             DiscountPerc={item.DiscountPerc}
                             RatingAvg={item.RatingAvg}
                             Quantity={item.Quantity} 
                             CategoryName={item.CategoryName}
                             CategoryID={item.CategoryID}
                             Slug={item.Slug}/>
                    
                ));
                setPageCount(Math.ceil(items.Data.length / perPage))
                getItems(null);
                setData(DisplayProdDetails);
            }

        const handlePageClick = (e) => {        
            const selectedPage = e.selected + 1;
            setOffset((selectedPage - 1) * perPage) 
        };

        const onClickApply1 = async (e) => {
            getItems(null);
            var request = {
                action: 'getProdDetails',
                searchString: search_,
                sortString: sort,
                categoryString: null,
                priceMin: priceRange.pr1,
                priceMax: priceRange.pr2,
                plat1: platforms.c1,
                plat2: platforms.c2,
                plat3: platforms.c3,
                plat4: platforms.c4,
                plat5: platforms.c10,
                plat6: platforms.c11,
                plat7: platforms.c12,
                plat8: platforms.c13,
                plat9: platforms.c14,
                plat10: platforms.c15,
                plat11: platforms.c16,
                gen1: genre.c5,
                gen2: genre.c6,
                gen3: genre.c7,
                gen4: genre.c8,
                gen5: genre.c9,
            }
            await API_REQUEST('fetchData', request).then((response) => getItems(response));
            //console.log(request);
    };


    if(items != null){         
        if (items.status === 404){
            window.location.href="/shop";
        }
            var slices = items.Data.slice(offset, offset + perPage)
                DisplayProdDetails = slices.map(item => (
                     <Cardis key={item.ProductID} 
                             Name={item.Name} 
                             ProdImg={item.Image} 
                             desc={item.desc}
                             prodId ={item.ProductID} 
                             offerprice={item.offerprice} 
                             price={item.price} 
                             DiscountPerc={item.DiscountPerc}
                             RatingAvg={item.RatingAvg}
                             Quantity={item.Quantity} 
                             CategoryName={item.CategoryName}
                             CategoryID={item.CategoryID}
                             Slug={item.Slug}/>
                    
                ));
                setPageCount(Math.ceil(items.Data.length / perPage))
                 getItems(null);
                setData(DisplayProdDetails);
            }

    return (
        <>
            <div className="row mt-70" style={{marginTop: 60+'px'}}>
                <Col sm={12} md={12} lg={12} className="col-md-12">
                    <div className="row">
                        <Col sm={6} md={3} lg={3} className="col-md-3" id="fag-filter-main">
                            <Topic name1={'Filter '} name2={' Here'} />
                            <Form>
                                <Form.Group>
                                    <div className="form-shop">
                                        <Form.Control className='inpText' 
                                                      type="name" 
                                                      id="s1" 
                                                      value={search_} 
                                                      onChange={onChangeS1} 
                                                      name="s1" 
                                                      placeholder="Search your Product"/>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <div className="form-shop">
                                        <select className="inpText"  id="s2" value={sort} onChange={onChangeS2} name="s2"  style={{ color: "white" }} >
                                            <option value="" disabled selected>Sort</option>
                                            <option style={{ color: "black" }} >Latest</option>
                                            <option style={{ color: "black" }} >Lowest Price to Highest Price</option>
                                            <option style={{ color: "black" }} >Highest Price to Lowest Price</option>
                                            <option style={{ color: "black" }} >Heavy Discounts</option>
                                        </select>
                                    </div>
                                    
                                </Form.Group>
                                {/* <Form.Group>
                                    <div className="form-shop">
                                        <DropDown />
                                    </div>
                                </Form.Group> */}
                                <Form.Group>
                                    <div className="form-shop">
                                        <H5 name1={'Price'} name2={'Range:'} />
                                        <Slider
                                            range
                                            step={50}
                                            max={30000}
                                            min={10}
                                            defaultValue={[priceRange.pr1, priceRange.pr2]}
                                            onChange={onChangePR1}
                                            onAfterChange={onAfterChangePR1}
                                            className="fag-slider-shop"
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <H5 name1={''} name2={'Games:'}/>
                                    <div className="form-shop-checkbox">
                                        <Form.Check custom id="c1" name="c1" onChange={onChangeC1} value={48} type="checkbox" label="PS 4" />
                                        <Form.Check custom id="c2" name="c2" onChange={onChangeC2} value={103} type="checkbox" label="PS 5"/>
                                        <Form.Check custom id="c10" name="c10" onChange={onChangeC10} value={72} type="checkbox" label="Xbox One"/>
                                        <Form.Check custom id="c11" name="c11" onChange={onChangeC11} value={88} type="checkbox" label="Xbox 360"/>
                                        <Form.Check custom id="c3" name="c3" onChange={onChangeC3} value={60} type="checkbox" label="Nintendo Switch" />
                                        <Form.Check custom id="c12" name="c12" onChange={onChangeC12} value={65} type="checkbox" label="Wii"/>
                                        <Form.Check custom id="c4" name="c4" onChange={onChangeC4} value={86} type="checkbox" label="PC"/>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <H5 name1={''} name2={'Accesories:'} />
                                    <div className="form-shop-checkbox">
                                        <Form.Check custom id="c13" name="c13" onChange={onChangeC13} value={48} type="checkbox" label="Playstation" />
                                        <Form.Check custom id="c14" name="c14" onChange={onChangeC14} value={73} type="checkbox" label="Xbox"/>
                                        <Form.Check custom id="c15" name="c15" onChange={onChangeC15} value={59} type="checkbox" label="Switch"/>
                                        <Form.Check custom id="c16" name="c16" onChange={onChangeC16} value={36} type="checkbox" label="PC"/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <H5 name1={''} name2={'Categories:'} />
                                    <div className="form-shop-checkbox">
                                        <Form.Check custom id="c5" name="c5" onChange={onChangeC5} value={23}  type="checkbox" label="Mcube Exclusive"/>
                                        <Form.Check custom id="c6" name="c6" onChange={onChangeC6} value={27}  type="checkbox" label="Latest Release"/>
                                        <Form.Check custom id="c7" name="c7" onChange={onChangeC7} value={20}  type="checkbox" label="Best Selling"/>
                                        <Form.Check custom id="c9" name="c9" onChange={onChangeC9} value={37}  type="checkbox" label="Pre-Order"/>
                                    </div>
                                </Form.Group>
                                
                            </Form>
                            <div  className='form-row'>
                                    <button className='fag-btn' onClick={onClickApply1} id="fag-apply-btn" variant="primary">Apply</button>
                            </div>
                        </Col>

                        <Col sm={6} md={9} lg={9} className="col-md-9" id="fag-shop-main">
                            <div  className='form-row'>
                                    <Collapse defaultActiveKey={['0']} >
                                     <Panel className = 'fag-btn'
                                     header = "Filter"
                                     id = "fag-filter-btn"
                                    key = "1" style={{backgroundColor: '#0d0e1b'}}>
                            {/* <Form  onSubmit={onClickApply1}> */}
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <div className="form-shop">
                                        <Form.Control className='inpText' 
                                                      type="name" 
                                                      id="s1" 
                                                      value={search_} 
                                                      onChange={onChangeS1} 
                                                      name="s1" 
                                                      placeholder="Search your Product"/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <div className="form-shop">
                                        < select className="inpText"  id="s2" value={sort} onChange={onChangeS2} name="s2"  style={{ color: "white" }} >
                                            <option value="" disabled selected>Sort</option>
                                            {/* <option style={{ color: "black" }} >Oldest</option> */}
                                            <option style={{ color: "black" }} >Latest</option>
                                            <option style={{ color: "black" }} >Lowest Price to Highest Price</option>
                                            <option style={{ color: "black" }} >Highest Price to Lowest Price</option>
                                            <option style={{ color: "black" }} >Heavy Discounts</option>
                                        </select>
                                    </div>
                                </Form.Group>
                                {/* <Form.Group controlId="formBasicEmail">
                                    <div className="form-shop">
                                        <DropDown />
                                    </div>
                                </Form.Group> */}
                                <Form.Group controlId="formBasicEmail">
                                    <div className="form-shop">
                                        <H5 name1={'Price'} name2={'Range:'} />
                                        <Slider
                                            range
                                            step={50}
                                            max={30000}
                                            min={10}
                                            defaultValue={[priceRange.pr1, priceRange.pr2]}
                                            onChange={onChangePR1}
                                            onAfterChange={onAfterChangePR1}
                                            className="fag-slider-shop"
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <H5 name1={''} name2={'Games:'}/>
                                    <div className="form-shop-checkbox">
                                        <Form.Check custom id="c40" name="c1" onChange={onChangeC1} value={48} type="checkbox" label="PS 4" />
                                        <Form.Check custom id="c41" name="c2" onChange={onChangeC2} value={103} type="checkbox" label="PS 5"/>
                                        <Form.Check custom id="c42" name="c10" onChange={onChangeC10} value={72} type="checkbox" label="Xbox One"/>
                                        <Form.Check custom id="c43" name="c11" onChange={onChangeC11} value={88} type="checkbox" label="Xbox 360"/>
                                        <Form.Check custom id="c44" name="c3" onChange={onChangeC3} value={60} type="checkbox" label="Nintendo Switch" />
                                        <Form.Check custom id="c45" name="c12" onChange={onChangeC12} value={65} type="checkbox" label="Wii"/>
                                        <Form.Check custom id="c46" name="c4" onChange={onChangeC4} value={86} type="checkbox" label="PC"/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <H5 name1={''} name2={'Accessories:'}/>
                                    <div className="form-shop-checkbox">
                                        <Form.Check custom id="c47" name="c13" onChange={onChangeC13} value={48} type="checkbox" label="Playstation" />
                                        <Form.Check custom id="c48" name="c14" onChange={onChangeC14} value={73} type="checkbox" label="Xbox"/>
                                        <Form.Check custom id="c49" name="c15" onChange={onChangeC15} value={59} type="checkbox" label="Switch"/>
                                        <Form.Check custom id="c50" name="c16" onChange={onChangeC16} value={47} type="checkbox" label="PC"/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <H5 name1={''} name2={'Categories:'} />
                                    <div className="form-shop-checkbox">
                                        <Form.Check custom id="c51" name="c5" onChange={onChangeC5} value={23}  type="checkbox" label="Mcube Exclusive"/>
                                        <Form.Check custom id="c52" name="c6" onChange={onChangeC6} value={27}  type="checkbox" label="Latest Release"/>
                                        <Form.Check custom id="c53" name="c7" onChange={onChangeC7} value={20}  type="checkbox" label="Best Selling"/>
                                        <Form.Check custom id="c54" name="c9" onChange={onChangeC9} value={37}  type="checkbox" label="Pre-Order"/>
                                    </div>
                                </Form.Group>
                             </Form>
                             <div  className='form-row'>
                                    <button className='fag-btn' onClick={onClickApply1} id="fag-apply-btn" variant="primary">Apply</button>
                            </div>
                  
                                    </Panel>

                                    </Collapse>
                                        {/* <button className='fag-btn' id="fag-filter-btn" variant="primary">Filter</button> */}
                                </div>
                                {/* <div className="cards-container"> */}
                                <div className="cards-row">
                                {
                                
                                    data ? data : < Spinner animation = "border" variant = "secondary" />
                                
                                }
                                </div>
                                {/* </div> */}
                        </Col>
                         <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                className="pagination"/>  
                    </div>
                </Col>
            </div>
        </>

    );

}


export default Shop;