import React, { useState, useEffect, useCallback } from 'react'
import { Row , Col ,Card , Image, Button} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import API_REQUEST from '../../../config/client';
import Get_image from '../../../config/get_image';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import HandleBuyNowOnSubmit from '../../utils/mcube_helper/buynow_item';
import Img from 'react-cool-img';
import {
  BrowserRouter,
  Switch,
  useLocation
} from "react-router-dom";


import { LoadingOutlined } from '@ant-design/icons';

function Nintendo() {

    function sleeper(ms) {
        return function(x) {
          return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }

    const {isLoggedIn} = useSelector(state => state.auth);
     const { user } = useSelector(state => state.auth);
     if (isLoggedIn) {
        var customerId = user.id;
        if (!customerId) {
           customerId = JSON.parse(user).id;
        }
     } else {
        customerId = null;
     }
    
      const [jcard,getJcard] = useState(null);
      let history = useHistory();

      var Displayjdata = null

      useEffect(() => {
        var fetchjCard = async () => {
        await API_REQUEST('home', {action:'preorder','id':'60'}).then(sleeper(1)).then((response) => getJcard(response));
        }
        fetchjCard();
    }, []);

    const handlebuynow = useCallback((data)=>{
        HandleBuyNowOnSubmit(data)
    },[])


    if(jcard != null){
        
        Displayjdata =  jcard.Data.map(item => (

            <div className="games-item mobile" key={item.id}>
            <div className="game-single-item img-contain-isotope">
                <div className="games-thumb">
                    <div className="games-thumb-image">
                        <Link to={'/product/'+item.Slug}>
                             <Img src={Get_image("products",item.id,item.img_url)} alt="Xbox Game" debounce={1000} />
                                {/* <Image src={Get_image("products",item.id,item.img_url)} alt="Playstation Game"  /> */}
                        </Link>
                    </div>
                    {/* <div className="game-overlay">
                        <div className="popup-youtube">
                            <p>out of stock</p>
                        </div>
                    </div> */}
                </div>
                <div className="games-desc">
                    <h3 className="name-up"><Card.Link href={'/product/'+item.Slug}>{item.name}</Card.Link></h3>
                    {/* <p className="game-meta">Action | Desktop</p>
                    <p className="game-meta">Release date:<span> 07.12.2015</span></p>
                    <div className="game-rating">
                        <h4>4.5</h4>
                        <ul>
                            <li><span className="fa fa-star"></span></li>
                            <li><span className="fa fa-star"></span></li>
                            <li><span className="fa fa-star"></span></li>
                            <li><span className="fa fa-star"></span></li>
                            <li><span className="fa fa-star-o"></span></li>
                        </ul>
                    </div> */}
                    <div className="game-action">
                        <div className="game-price">
                            <h4>AED {(item.offerprice > 0) ? item.offerprice : item.price}</h4>
                            {
                            item.DiscPerc ?  <p className="off">{item.DiscPerc}% Off</p>
                            : <div></div>
                        }
                        </div>
                        <div className="game-buy" id="buy-now-up">
                           {item.PreOrder === "0" ?
                            <Card.Link  className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' onClick={data => handlebuynow({id: item.id, isLoggedIn: isLoggedIn, customer_id: customerId, qty:1 })} href = {"/checkout?src="+item.id}>
                                    Buy Now
                            </Card.Link>:
                            <Card.Link  className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' onClick={data => handlebuynow({id: item.id, isLoggedIn: isLoggedIn, customer_id: customerId, qty:1 })} href = {"/checkout?src="+item.id}>
                                    Pre Order
                            </Card.Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        ));


    }
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <><br />
            <Col lg={12} className="preorder-col-12">
                
                <div className="site-heading site-head-mobile">
                    <h2 className="heading_animation">Nintendo <span>Switch</span></h2>
                </div>
            </Col>
            <Col lg={12} className="col-12">
                <div className="games-masonary">
                    <div className="clearfix gamesContainer">
                        <Row xs={2} md={3} lg={6} className="g-6-up">
                        
                            {
                                    Displayjdata  ? Displayjdata : <Spin indicator={antIcon} />
                            }

                        </Row>
                    </div>
                </div>
                <div className="game-buy btn-explore">
                    <Card.Link href="/shop?id=60" className="fag-btn-outline">SEE MORE</Card.Link>
                </div>
            </Col>
        </>
    )
}

export default Nintendo
