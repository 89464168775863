import React from "react";
import { MDBFooter } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import {
  CaretRightOutlined,
  EnvironmentOutlined,
  EditOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import logo from "../../assets/img/mg-1.png";
import { Link } from "react-router-dom";
import { BackTop } from "antd";

import SocialIcons from "../utils/social/social_icon";
function FooterDesktop() {

//   const style = {
//     height: 40,
//     width: 40,
//     lineHeight: "65px",
//     borderRadius: 50,
//     backgroundColor: "#ff7a21",
//     color: "#060423",
//     textAlign: "center",
//     fontSize: 50,
//     marginLeft: "80px",
//     marginTop:"20px",
    
//   };
  
  return (
    <MDBFooter className="fag-footer">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={4} md={6} xs={12}>
              <div className="single-footer">
                <h3>About us</h3>
                <p>
                  Mcube Games offers a wide variety of gaming products,
                  including consoles, accessories, headphones, bundles, and the
                  latest releases.In addition, we offer the most competitive
                  prices. We are present at all of UAE's most popular
                  e-commerce sites, including Amazon, Flipkart, Snapdeal, among
                  others.
                </p>
          <Link to = "/privacy-policy" > Privacy Policy </Link> <br></br>
          <Link to = "/refund-policy" > Refund Policy </Link>
              </div>
            </Col>


            <Col lg={5} md={6} xs={12}>
              <div className="widget-content">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <div className="single-footer">
                      <h3>Careers</h3>
                      <p>We have various Openings at Mcubegames.ae, Send us your Resume on letsplay@mcubegames.ae We will go through it and if your profile matches
                        our requirement we will surely get back to you!
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              
            </Col>


            <Col lg={3} md={6} xs={12}>
              <div className="single-footer">
                <h3>Contact Us</h3>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <EnvironmentOutlined
                      style={{ color: "#ff7a21" }}
                    /> Address{" "}
                  </h4>
                  <p>
                    Deira, Dubai - UAE
                  </p>
                </div>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <EditOutlined style={{ color: "#ff7a21" }} /> Email Address
                  </h4>
                  <p>letsplay@mcubegames.ae</p>
                </div>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <PhoneOutlined style={{ color: "#ff7a21" }} /> Phone{" "}
                  </h4>
                  <p>0564273652</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="footer-bottom-inn">
                <div className="footer-logo">
                  <img
                    alt=""
                    src={logo}
                    width="240px"
                    height="160px"
                    className="navbar-brand logo"
                  />{" "}
                </div>
                <SocialIcons />
                <div className="copyright">
                  <p>&copy; Copyrights 2020 Mcube Games - All Rights Reserved</p>
                </div>
              </div>
            </Col>
          </Row>

          <BackTop>
            <div className="mybtn"><i class="fa fa-chevron-up" aria-hidden="true"></i></div>
          </BackTop>

        </Container>
      </div>
    </MDBFooter>
  );
}

export default FooterDesktop;

// style={style}     ⌃