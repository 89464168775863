import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import Gameplay from './gameplay_tab'

import ProductDescripton from './product_description'
import ProductQuery from './product_query'

function OptionTabs(props){
  //console.log(props)
  const [activeTab, setActiveTab] = useState(props.activeTab)

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab)
  }
  
    return (
        <Tabs activeKey={activeTab} onSelect={handleSelect}>
            <Tab eventKey={1} title="description">
                <ProductDescripton content = {props.tabsData.Description}/>
            </Tab>
            <Tab eventKey={2} title="reviews">
              <ProductQuery reviews = {props.reviewData} meta_info = {props.meta_info}/>
            </Tab>
            <Tab eventKey={3} title="gameplay">
              <Gameplay videolink = {props.tabsData.VideoLink} />
            </Tab>
        </Tabs>
    );

}

export default OptionTabs