import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import { Button } from "antd";
import NavCall from "../../menu/main_header";
import DataTable from 'react-data-table-component';
import { CSVLink, CSVDownload } from "react-csv";

function Customer_listing() {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState(null);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sdata, setData] = useState("");
  const [sPage, setSPage] = useState("");
  const [uPage, setUPage] = useState("");
  const [banner, setBanner] = useState(null);
  const [displayPage, setDisplayPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  var DisplaySms = null;
  var page = null;

  useEffect(() => {
    async function fetchData() {
      await API_REQUEST("fetch_listing", { action: "getCustomer" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, [offset]);

  const columns = [
    {
      name: 'Customer ID',
      selector: row => row.CustomerID,
      sortable: true,
    },
    {
      name: 'First Name',
      selector: row => row.FirstName,
      sortable: true,
    },
    {
      name: 'LastName',
      selector: row => row.LastName,
      sortable: true,
    },
    {
      name: 'Email ID',
      selector: row => row.EmailID,
      sortable: true,
    },
    {
      name: 'Mobile Number',
      selector: row => row.MobileNumber,
      sortable: true,
    },
    {
      name: 'IsActive',
      selector: row => row.IsActive,
      sortable: true,
    },
    {
      name: 'User Name',
      selector: row => row.UserName,
      sortable: true,
    },
    {
      name: 'Address1',
      selector: row => row.Address1,
      sortable: true,
    },
    {
      name: 'Address2',
      selector: row => row.Address2,
      sortable: true,
    },
    {
      name: 'city',
      selector: row => row.city,
      sortable: true,
    },
    {
      name: 'StateName',
      selector: row => row.StateName,
      sortable: true,
    },
    {
      name: 'Country',
      selector: row => row.Country,
      sortable: true,
    },
    // {
    //     name: 'Action',
    //     selector: row => <Button value={row.CustomerID} onClick={handleUpdate}>  <i value={row.CustomerID} class="fa fa-edit"></i> </Button>,
    //     sortable: true,
    // },
  ];

  const handleUpdate = async (e) => {
    e.preventDefault();
    page = "updatebanner" + e.currentTarget.value
    setDisplayPage(2);
    setUPage(NavCall(page))
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setOffset((selectedPage - 1) * perPage);
  };

  const handleList = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(1);
    var page = "banner"
    setSPage(NavCall(page))
  });

  const handleBack = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(0);
  });

   const handleClick = (e) => {
     setSearchTerm(e.target.value);
   }

  if (displayPage === 0) {
    return (
      posts &&
      <>

        <CSVLink  style={{ marginRight: 1 + "em", marginTop: 40, border: "none", backgroundColor: "darkorange", color: "white", }} 
         class="btn btn-primary button" data={posts.data}>Export to CSV</CSVLink>

        <div>
          <input type="text" value={searchTerm} onChange={handleClick} placeholder="Search..." className="searchbox_admin" />
        </div>

        <DataTable
          columns={columns}
          data={posts.data}
          pagination
          highlightOnHover
          data={posts.data.filter((i) => {
            if (searchTerm === "") {
              return i;
            } else if (
              (i.CustomerID + i.FirstName).toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return i;
            }
          })}
        />

      </>
    );
  } else if (displayPage === 1) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {sPage ? sPage : <p>No Data </p>}</>
    );
  } else if (displayPage === 2) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {uPage ? uPage : <p>No Data </p>}</>
    );
  }
}
export default Customer_listing;
