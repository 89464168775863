import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { Rate} from 'antd';


// import profile from '../../../assets/img/4.jpg';
// import profile1 from '../../../assets/img/5.jpg';
import profile from '../../../assets/img/user.png';

const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

function ProductQueryListing(props){
    return (
        <div>
            <div className="tab-gamess-details">
                <Row>
                    <Col md={12}>
                        <div className="tab-body">
                            <div className="fab-comment-list">
                                <div className="single-comment-item">
                                    <div className="single-comment-box">
                                        <div className="main-comment">
                                            <div className="author-image1">
                                                <img
                                                    alt=""
                                                    src={profile}
                                                    width="80"
                                                    height="40"
                                                    className="navbar-brand logo"
                                                />{' '}
                                            </div>
                                            <div className="comment-text">
                                                <div className="comment-info">
                                                    <h4>{props.review_meta.CustomerName} </h4>
                                                    <p>&ensp;{formatDate(props.review_meta.CreatedOn)}</p>
                                                    <div className="tour-rating">
                                                        <Rate
                                                            allowHalf
                                                            defaultValue={Number(props.review_meta.star)}
                                                            style={{ color: '#ffa11a' }}
                                                        /> 
                                                    </div>
                                                </div>
                                                <div className="comment-text-inner">
                                                    <p>{props.review_meta.Text}</p>
                                                </div>
                                            </div>
                                        </div>
                                       { props.review_meta.review_reply? <div className="single-comment-box comment_reply">
                                            <div className="main-comment">
                                                <div className="author-image">
                                                    <img
                                                        alt=""
                                                        src={profile}
                                                        width="140"
                                                        height="60"
                                                        className="navbar-brand logo"
                                                    />{' '}
                                                </div>
                                                <div className="comment-text">
                                                    <div className="comment-info">
                                                        <h4>admin</h4>
                                                    </div>
                                                    <div className="comment-text-inner">
                                                        <p>{props.review_meta.review_reply}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>:
                                                <p></p>
                                                }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ProductQueryListing