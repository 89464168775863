import React, { useState, useEffect } from "react";
import API_REQUEST from "../../../config/client";
import axios from 'axios'
import { Alert } from 'antd';
import { Select, Option } from 'antd';
import CONST_URL from "../../../config/url";


function UpdateWallet(props) {
  const [walletResult, getWalletResult] = useState(null)
  const [fetchWallet, getFetchWallet] = useState("");
  const { walletId } = props;
  const {
    Option
  } = Select;

  const [data, setData] = useState({
    CustomerID: "",
    Amount: "",
    Type: "",
    Details: "",
    action: "process_wallet"
  });

  useEffect(() => {
    async function fetchData() {
      var request = {
        action: 'fetchWallet',
        walletId: walletId
      }
      await API_REQUEST('fetch', request).then((response) => getFetchWallet(response));
    }
    fetchData();

  }, []);

  useEffect(() => {
    if (fetchWallet) {
      console.log(fetchWallet)
      setData({
        TransactionId: fetchWallet.TransactionId,
        Type: fetchWallet.Type,
        Amount: fetchWallet.Amount,
        Details: fetchWallet.Details,
        action: "updatewallet",
        walletId: walletId

      })


    }
  }, [fetchWallet]);

  console.log(data)

  function handle2(e) {
    setData(data => ({
      ...data,
      Type: e
    }));
  }

  function handle(e) {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
    console.log(newdata);
  }

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append('request_data', JSON.stringify(data))
    async function processWalletForm() {
      console.log(file_data)
      var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
      var fetchData = axios.post(CONST_URL + 'orders.php', file_data).then((response) => getWalletResult(response.data))

    }
    processWalletForm()
  }

  return (
    <div className="container_admin admin_body form">
      <form onSubmit={(e) => submit(e)}>
        <br></br>

        <div className="col-sm-5">
          <label>Customer ID*</label>
          <br />
          <input onChange={(e) => handle(e)} id="CustomerID" value={data.CustomerID} type="number" className="form-control" name="CustomerID" required />
          <br />
        </div>

        <div className="col-sm-5">
          <label htmlFor="Amount">Amount ( AED )*</label> <br />
          <input onChange={(e) => handle(e)} id="Amount" value={data.Amount} name="Amount" className="form-control" type="number" required />
          <br />
        </div>

       <div className="col-sm-5">
        <label>Add/Deduct</label>
        <br></br>
        <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onChange={handle2}
                value={data.Type}
                defaultValue={data.Type}
                name="Type"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="credit">  Add</Option>
                <Option value="debit">  Deduct</Option>
              </Select>
              </div>
              <br></br>

        <div className="col-sm-5">
          <label>Details</label> <br />
          <textarea onChange={(e) => handle(e)} id="Details" value={data.Details} type="text" className="form-control" rows="3" name="Details" required />
        </div>
        <br />


        <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Update </button>
        <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
        {
          walletResult ? <Alert message={walletResult.message} type="success" /> : <p></p>
        }
      </form>
    </div>
  )
}

export default UpdateWallet
