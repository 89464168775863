import React from 'react'
import Preorder from './preorder';
import JustLaunched from './justLaunched';
import LatestRelease from './latestRelease';
import NewForYou from './newForYou';
import ListGroup from 'react-bootstrap/ListGroup'
import { Tab , Row , Col} from 'react-bootstrap';
import { useSelector } from "react-redux";
import Carousel from 'react-bootstrap/Carousel'
import Playstation from './playStation';
import Xbox from './xbox';
import Nintendo from './nintendo';
import Accessories from './accessories';

function Categories() {
    const { isLoggedIn } = useSelector(state => state.auth);
    //console.log(isLoggedIn)
    const {user} = useSelector(state => state.auth);


// return (
//         <>
//             <section className="fag-preorder-area menu-area" >
//                 {/* <div className="top-layer"></div> */}
                
//                     <Row>
                        
//                         <Col lg={12} className="col-lg-12-up">
//                             <Carousel indicators={false}>
                            
//                             <Carousel.Item interval={5000}>
//                                     <Preorder/>
//                              </Carousel.Item>
//                              <Carousel.Item interval={5000}>
//                                     <JustLaunched/>
//                              </Carousel.Item>
//                              <Carousel.Item interval={5000}>
//                                     <Nintendo/>
//                              </Carousel.Item>
//                              <Carousel.Item interval={5000}>
//                                     <Playstation/>
//                              </Carousel.Item>
//                              <Carousel.Item interval={5000}>
//                                     <Xbox/>
//                              </Carousel.Item>
//                              <Carousel.Item interval={5000}>
//                                     <Accessories/>
//                              </Carousel.Item>
//                              { !isLoggedIn?<Carousel.Item interval={5000}>
//                                                  <Nintendo/>
//                                           </Carousel.Item>:
//                            <Carousel.Item interval={3000}>
//                                     <NewForYou/>
//                              </Carousel.Item>
//                           }
                                
//                                 </Carousel>
                            
                            
//                         </Col>
                        
//                     </Row>
//           </section>
//         </>
//     );
// }

// export default Categories;

    return (
        <>
            <section className="fag-preorder-area menu-area" >
                {/* <div className="top-layer"></div> */}
                <Tab.Container defaultActiveKey="#playstation">
                    <Row>
                        
                        <Col lg={12} className="col-lg-12-up">
                        
                            
                            <Carousel indicators={false}>
                            
                            <Carousel.Item interval={9000}>
                            <div className="site-heading">
                                <ListGroup horizontal className="nav-pills-up">

                                    <ListGroup.Item action href="#playstation" className="active-up">Playstation</ListGroup.Item>
                                    <ListGroup.Item action href="#xbox">Xbox</ListGroup.Item>
                                    <ListGroup.Item action href="#nintendo">Nintendo Switch</ListGroup.Item>
                                    { !isLoggedIn?<br />:<ListGroup.Item action href="#foryou">For You</ListGroup.Item>}
                                  
                                </ListGroup>
                                </div>
                                </Carousel.Item>
                                
                                
                                <Carousel.Item interval={5000}>
                                <div className="site-heading">
                                <ListGroup horizontal className="nav-pills-up">

                                    <ListGroup.Item action href="#accessories">Accessories</ListGroup.Item>
                                    <ListGroup.Item action href="#preorder">Preorder</ListGroup.Item>
                                    <ListGroup.Item action href="#justlaunched">Just Launched</ListGroup.Item>
                                    <ListGroup.Item action href="#latestrelease">Latest Release</ListGroup.Item>
                                  
                                </ListGroup>
                                </div>
                                </Carousel.Item>
                                
                                </Carousel>
                            
                            
                        </Col>
                        
                    </Row>
                    <Tab.Content>
                        <Tab.Pane eventKey="#playstation">
                            <Playstation/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#xbox">
                            <Xbox/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#nintendo">
                            <Nintendo/>
                        </Tab.Pane>
                        { !isLoggedIn?<br />:
                        <Tab.Pane eventKey="#foryou">
                            <NewForYou/>
                        </Tab.Pane>
                        }
                        <Tab.Pane eventKey="#accessories">
                            <Accessories/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#preorder">
                            <Preorder/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#justlaunched">
                            <JustLaunched/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#latestrelease">
                            <LatestRelease/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </section>
        </>
    );
}

export default Categories;