// import { getKeyThenIncreaseKey } from 'antd/lib/message';
import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap'
import API_REQUEST from '../../../config/client';


function QuantityButton({handleParentCounter, id , Quantity , ItemsInStock}) {
    // VARIABLES -----------------------------------------------    -------------------------------
    // var { id , Quantity , ItemsInStock} = props;
    let Qty = parseInt(Quantity, 10)
    const [counter, setCounter] = useState(Qty);
    const {isLoggedIn} = useSelector(state => state.auth);
    const {user} = useSelector(state => state.auth);
    
    // API REQUEST ------------------------------------------------------------------------------
    async function increment(){
        if(isLoggedIn){
            await API_REQUEST('cart', {action:'addQty','key':id,customerId: customerId,isLoggedIn:isLoggedIn}).then((response) =>{ setCounter(counter+1); if (handleParentCounter !== ''){ handleParentCounter(counter+1)}});
        }else{
            if(counter<=ItemsInStock){
                setCounter(counter+1)
                handleParentCounter(counter+1)
            }
        }
    }
    async function decrement(){
        if(isLoggedIn){
            await API_REQUEST('cart', {action:'subQty','key':id,customerId: customerId,isLoggedIn:isLoggedIn}).then((response) => {setCounter(counter-1); if (handleParentCounter !== ''){handleParentCounter(counter-1)}});
        }else{
            if(counter>=0){
                setCounter(counter-1)
                handleParentCounter(counter-1)
            }
        }
    }
    //console.log(counter)
    // IF STATEMENTS ------------------------------------------------------------------------------
    if (isLoggedIn) {
        
        var customerId = user.customerId;
        if (!customerId) {
            customerId = JSON.parse(user).id;
        }
    }else {
        customerId = null;
    }
 

    // useEffect(()=>{
    //     if(handleParentCounter){
    //         return handleParentCounter(counter)
    //     }
    // },[counter, handleParentCounter])      

    // RETURN STATEMENT ------------------------------------------------------------------------------
    return (
        <ButtonGroup size="small" aria-label="small outlined button group">
            {
                <Button disabled={counter <= 1} onClick={()=>{decrement();}} className = "minus dis"> 
                -
                </Button>
            }

            {<Button disabled className="in-num" >{counter}</Button>}

            {
                <Button disabled={counter >= ItemsInStock} onClick={()=>{increment()}} className = "plus">
                +
                </Button>
            }
        </ButtonGroup>
    );
}


// function QuantityButtonres(props){
//     // VARIABLES ------------------------------------------------------------------------------
//     var { id , Quantity , ItemsInStock} = props;
//     let Qty = parseInt(Quantity, 10)
//     const [counter, setCounter] = useState(Qty);
//     const [Increment,incre] = useState(null);
//     const [Decrement,decre] = useState(null);
//     const {isLoggedIn} = useSelector(state => state.auth);
//     const {user} = useSelector(state => state.auth);
    
//     // API REQUEST ------------------------------------------------------------------------------
//     var increment = async () => {
//         await API_REQUEST('cart', {action:'addQty','key':id,customerId: customerId}).then((response) => incre(response));
//     }
//     var decrement = async () => {
//         await API_REQUEST('cart', {action:'subQty','key':id,customerId: customerId}).then((response) => decre(response));
//     }

//     // IF STATEMENTS ------------------------------------------------------------------------------
//     if (isLoggedIn) {
//         var customerId = user.customerId;
//         if (!customerId) {
//             customerId = JSON.parse(user).id;
//         }
//     }else {
//         customerId = null;
//     }
    
//     if(Increment != null){
//         setCounter(counter + 1);
//         incre(null);
//         // window.location.href="/cart";
//     }
//     if(Decrement != null){
//         setCounter(counter - 1);
//         decre(null);
//         // window.location.href="/cart";
//     }

//     //console.log(counter)        

//     // RETURN STATEMENT ------------------------------------------------------------------------------
//     return (
//         <ButtonGroup size="small" aria-label="small outlined button group">
//             {
//                 <Button disabled={counter <= 1} onClick={decrement} className = "minus dis">   
//                 -
//                 </Button>
//             }

//             {<Button disabled className="in-num" >{counter}</Button>}

//             {
//                 <Button disabled={counter >= ItemsInStock} onClick={increment} className = "plus">
//                 +
//                 </Button>
//             }
//         </ButtonGroup>
//     );
// }

export default QuantityButton