import React from 'react'
import AdminNav from '../../component/menu/main_nav'

function AdminMainNav(){
    return (
        <div>
            <AdminNav/>
        </div>
    )
}

export default AdminMainNav