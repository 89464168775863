import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import NavCall from "../../menu/main_header";
import { Button } from "antd";
import DataTable from 'react-data-table-component';

function GenreListing() {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState(null);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sdata, setData] = useState("");
  const [sPage, setSPage] = useState("");
  const [uPage, setUPage] = useState("");
  const [genre, setGenre] = useState(null);
  const [displayPage, setDisplayPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  var DisplaySms = null;
  var page = null;

  useEffect(() => {
    async function fetchData() {
      await API_REQUEST("fetch_listing", { action: "getGenre" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, [offset]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.ID,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.Name,
      sortable: true,
    },
    {
      name: 'Slug',
      selector: row => row.Slug,
      sortable: true,
    },
    {
      name: 'IsActive',
      selector: row => row.IsActive,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => <Button value={row.ID} onClick={handleUpdate}>  <i value={row.ID} class="fa fa-edit"></i> </Button>,
      sortable: true,
    },

  ]


  const handleUpdate = async (e) => {
    e.preventDefault();
    page = "updategenre" + e.currentTarget.value
    setDisplayPage(2);
    setUPage(NavCall(page))
  };



  // if (posts != null) {
  //   var slices = posts.data.slice(offset, offset + perPage);
  //   DisplaySms = slices.map((posts) => (
  //     <tbody>
  //       <td>{posts.ID}</td>
  //       <td>{posts.Name}</td>
  //       <td>{posts.Slug}</td>
  //       <td>{posts.IsActive}</td>
  //       <td>
  //         <Button value={posts.ID} onClick={handleUpdate}>  <i value={posts.ID} class="fa fa-edit"></i> </Button>
  //         <Button> <i class="fas fa-trash-alt"></i> </Button>
  //       </td>
  //     </tbody>
  //   ));
  //   setPageCount(Math.ceil(posts.data.length / perPage));
  //   setPosts(null);
  //   setData(DisplaySms);
  // }

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setOffset((selectedPage - 1) * perPage);
  };

  const handleList = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(1);
    page = "genre"
    setSPage(NavCall(page))
  });

  const handleBack = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(0);
  });

  const handleClick = (e) => {
    setSearchTerm(e.target.value);
  }

  if (displayPage === 0) {
    return (
      posts &&
      <div>
        <div className="button">
          <button type="submit" onClick={handleList} className="btn btn-primary button" > Add Genre </button>
        </div>

        <div>
          <input type="text" value={searchTerm} onChange={handleClick} placeholder="Search..." className="searchbox" />
        </div>

        <DataTable
          columns={columns}
          data={posts.data}
          reorder
          defaultSortFieldId={1}
          pagination
          highlightOnHover
          data={posts.data.filter((item) => {
            if (searchTerm === "") {
              return item;
            } else if (
              item.Name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return item;
            }
          })}
        />

      </div>
    );
  } else if (displayPage === 1) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {sPage ? sPage : <p>No Data </p>}</>
    );
  } else if (displayPage === 2) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {uPage ? uPage : <p>No Data </p>}</>
    );
  }
}
export default GenreListing;
