import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


//Header footer template


import HomePage from "./pages/home";
import LoginPage from "./pages/loginPage";
import ForgotPage from "./pages/forgotPage";
import logout from "./components/auth/logout";
import RegisterPage from "./pages/registerPage";
import ProfilePage from "./pages/profilePage";
import MyOrderPage from "./pages/myOrderPage";
import Contact from "./pages/contact";
import Cart from "./pages/cart";
import About from "./pages/about";
import ShopPage from "./pages/shopPage";
import SingleProduct from "./pages/single_products";
import Error from './pages/error404';
import UnderConstruct from './pages/underConstruct';
import Cardi from './components/Shop/productCard';
import PrivacyPolicy from './pages/template/privacy-policy.js';
import RefundPolicy from './pages/template/refund.js';

import CheckoutPage from './pages/checkoutPage';
import Thankyou from './pages/thankyou';
import ProductQuery from './components/content-section/single-product/product_query';
import KnowMore from './components/content-section/single-product/KnowMore';

// admin files
import AdminMainNav from './admin/pages/template/admin_nav'


function App() {

  return (
    <>
      <div id="cursor-large"></div>
      <div id="cursor-small"></div>
      <Router>
        {/* <UniversalNav/> */}
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/contact" component={Contact} />
          <Route path="/cart" component={Cart} />
          <Route path="/product/:slugurl" component={SingleProduct} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/login" component={LoginPage}/>
          <Route path="/checkout" component={CheckoutPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/myorders" component={MyOrderPage} />
          <Route path="/shop" component={ShopPage} />
          <Route path="/about" component={About} />
          <Route path="/logout" component={logout} />
          <Route path="/forgot" component={ForgotPage} />
          <Route path="/error404" component={Error}/>
          <Route path="/underconstruct" component={UnderConstruct}/>
          <Route path="/card" component={Cardi}/>
          <Route path="/thankyou" component={Thankyou}/>
          <Route path="/review" component={ProductQuery}/>
          <Route path="/know-more" component={KnowMore}/>
          <Route path="/privacy-policy" component={PrivacyPolicy}/>
          <Route path="/refund-policy" component={RefundPolicy}/>
          <Route path="/e0txc7ieqo" component={AdminMainNav} />
        </Switch>
      </Router>
   </> 
  );
}

export default App;

