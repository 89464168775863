import React from "react";
// import "../../assets/css/style.css"



const H5=(props) =>{
    
    return(

    
        <div class="tab-content">   
        
        <div class="site-heading-h5">
            <h5 class="heading_animation">{props.name1} <span>{props.name2}</span></h5>
        </div>
        </div>


    );
}

export default H5;