import React from 'react'
import { Row, Col, Divider, Table } from 'antd'
import { jsPDF } from "jspdf";
import mcubelogo from '../../../logo/mg3.png'
import mcubelogosmall from '../../../logo/mg1.png'
import insta from '../../../logo/insta.png'
import fb from '../../../logo/fb.png'
import twitter from '../../../logo/twitter.png'
import gmail from '../../../logo/gmail.png'


function InvoiceGenerator(props) {
    const genrateOrderInvoice = e => {
        // console.log(props)
        e.preventDefault()
        let doc = new jsPDF("potrait", 'pt', 'A4');
        doc.html(document.getElementById('pdf-view'), {
            callback: () => {
                doc.save('invoice_' + props.OrderSummary.data.OrderId + '.pdf');
            }
        });
    }

    // console.log(props.OrderSummary.prodlisting);


    return (
        <div className='containerr'>
            <div id="pdf-view" style={{ padding: 25, paddingRight: 50 + 'px', margin: 25 }}>

                <Row style={{ width: 67 + '%', justifyContent: 'space-between' }}>

                    <Col className='invoice-head-col'>
                        <img src={mcubelogo} alt="MCUBE GAMES" style={{ width: 120 + 'px', height: 55 + 'px', marginTop: -25 + 'px', marginBottom: 0 + 'px', marginRight: 300 + 'px' }}></img>
                        <h4 className='exp_1'>Little Bird Video Games Trading LLC</h4>
                        <h4 className='exp_1'>DEIRA, DUBAI -UAE</h4>
                        <h4 className='exp_1'>CONTACT – 0564273652</h4>
                        <h4 className='exp_1'>TRN – 100485453300003</h4>

                    </Col>
                    <Col className='invoice-head-col' style={{ marginRight: 23 + 'px' }}>
                        <Col style={{ marginLeft: 180 + 'px', marginTop: 50 + 'px' }}>
                            <div className='head_invoice'>TAX INVOICE</div>
                        </Col>
                        <h3 className='invoice'>Invoice:</h3>
                        <h3 className='invoice_id_num'># MG -  {props.OrderSummary.data.OrderId}</h3>
                        <br></br>
                        <h3 className='invoice'>Date:</h3>
                        <h3 className='invoice_id'>{props.OrderSummary.data.OrderDate}</h3>
                        <br></br>
                        <h3 className='invoice'>Payment Via:</h3>
                        <h3 className='invoice_id'>{props.OrderSummary.data.payment_type}</h3>
                        <br></br>
                    </Col>
                </Row>



                <Row>
                    <Col className='invoice-head-col'>
                        <div className='cust_contact'>CUSTOMER CONTACT</div>
                    </Col>
                </Row>

                <Row style={{ width: 67 + '%', marginTop: 20 + 'px' }}>
                    <Col className='invoice-head-col' style={{ width: 50 + '%', }}>
                        <h3 style={{ marginLeft: 0 + 'px', marginBottom: 0 + 'px', marginTop: 0 + 'px' }} className='cust_ship1'>Bill To: {props.OrderSummary.data.ShipTo}</h3>
                        <h3 style={{ marginLeft: 0 + 'px', marginBottom: -50 + 'px' }} className='cust_ship '>Address: {props.OrderSummary.data.sAddress1}  {props.OrderSummary.data.sAddress2} {props.OrderSummary.data.StateName} {props.OrderSummary.data.sPincode}</h3>
                        <h3 style={{ marginLeft: 0 + 'px' }} className='cust_ship '>Mob: {props.OrderSummary.data.bContactNumber}</h3>
                    </Col>
                    <Col className='invoice-head-col' style={{ width: 50 + '%' }}>
                        <h3 style={{ marginLeft: 0 + 'px', marginBottom: 0 + 'px', marginTop: 0 + 'px' }} className='cust_ship1'>Ship To: {props.OrderSummary.data.ShipTo}</h3>
                        <h3 style={{ marginLeft: 0 + 'px', marginBottom: -50 + 'px' }} className='cust_ship '>Address: {props.OrderSummary.data.sAddress1}  {props.OrderSummary.data.sAddress2} {props.OrderSummary.data.StateName} {props.OrderSummary.data.sPincode}</h3>
                        <h3 style={{ marginLeft: 0 + 'px' }} className='cust_ship '>Mob: {props.OrderSummary.data.bContactNumber}</h3>
                    </Col>
                </Row>

                <div className='linee'></div>

                <row>
                    <Table dataSource={props.OrderSummary.prodlisting}
                        pagination={false}>
                        <Table className='exp table middle' title="Item" dataIndex='PName' />
                        <Table className='exp table' title="EAN" dataIndex='HsnNo' />
                        <Table className='exp table' title="Quantity" dataIndex='QTY' />
                        <Table className='exp table' title="VAT" dataIndex='VAT' />
                        {/* <Table className='exp table' title="Price" dataIndex='subtotal' /> */}
                        <Table className='exp table' title="Total" dataIndex='total' />
                    </Table>
                    <h4 className='exp_1'>Narration: {props.OrderSummary.data.SNotes}</h4>
                </row>

                <Row>
                    {
                        props.OrderSummary.data.isWallet === "1" ?
                            <div>{
                                (props.OrderSummary.data.PaymentType).toUpperCase() === "WALLET + COD" ?
                                    <Col style={{ marginLeft: 365 + 'px', marginTop: 0 + 'px' }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='total'>Taxable value :</th>
                                                    <td className='figures'>AED  {((props.OrderSummary.subtotal) - ((props.OrderSummary.subtotal / 1.05) * 5 / 100)).toFixed(2)}  </td>
                                                </tr>
                                                <tr>
                                                    <th className='total'>VAT @5%</th>
                                                    <td className='figures'>AED {(((props.OrderSummary.subtotal / 1.05) * 5 / 100)).toFixed(2)}</td>
                                                </tr>
                                                <tr>  {parseInt(props.OrderSummary.data.DiscountAmount) !== 0 ?
                                                    <> <th className='total'>Discounted Amount :</th>
                                                        <td className='figures'>AED {parseInt(props.OrderSummary.data.DiscountAmount).toFixed(2)}</td></> : <></>
                                                }
                                                </tr>
                                                <tr>
                                                    <th className='total'>Payment via Wallet :</th>
                                                    <td className='figures'>AED {parseInt(props.OrderSummary.data.WalletAmount).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th className='total'>COD Amount :</th>
                                                    <td className='figures'>AED {(parseInt(props.OrderSummary.data.PayableAmount).toFixed(2) - parseInt(props.OrderSummary.data.WalletAmount)).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th className='total'>Total :</th>
                                                    <td className='figures'>AED {parseInt(props.OrderSummary.subtotal).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col> :
                                    <Col style={{ marginLeft: 365 + 'px', marginTop: 0 + 'px' }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th className='total'>Taxable value :</th>
                                                    <td className='figures'>AED  {((props.OrderSummary.subtotal) - ((props.OrderSummary.subtotal / 1.05) * 5 / 100)).toFixed(2)}  </td>
                                                </tr>
                                                <tr>
                                                    <th className='total'>VAT @5%</th>
                                                    <td className='figures'>AED {(((props.OrderSummary.subtotal / 1.05) * 5 / 100)).toFixed(2)}</td>
                                                </tr>
                                                <tr>  {parseInt(props.OrderSummary.data.DiscountAmount) !== 0 ?
                                                    <> <th className='total'>Discounted Amount :</th>
                                                        <td className='figures'>AED {parseInt(props.OrderSummary.data.DiscountAmount).toFixed(2)}</td></> : <></>
                                                }
                                                </tr>
                                                <tr>
                                                    <th className='total'>Payment via Wallet :</th>
                                                    <td className='figures'>AED {parseInt(props.OrderSummary.data.WalletAmount).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th className='total'>Total :</th>
                                                    <td className='figures'>AED {parseInt(props.OrderSummary.data.PayableAmount).toFixed(2) - parseInt(props.OrderSummary.data.WalletAmount).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>}</div> :
                            <Col style={{ marginLeft: 365 + 'px', marginTop: 0 + 'px' }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th className='total'>Taxable value :</th>
                                            <td className='figures'>AED {((props.OrderSummary.subtotal) - ((props.OrderSummary.subtotal / 1.05) * 5 / 100)).toFixed(2)}  </td>
                                        </tr>
                                        <tr>
                                            <th className='total'>VAT @5%</th>
                                            <td className='figures'>AED {(((props.OrderSummary.subtotal / 1.05) * 5 / 100)).toFixed(2)}</td>
                                        </tr>
                                        <tr>  {parseInt(props.OrderSummary.data.DiscountAmount) !== 0 ?
                                            <> <th className='total'>Discounted Amount :</th>
                                                <td className='figures'>AED {parseInt(props.OrderSummary.data.DiscountAmount).toFixed(2)}</td></> : <></>
                                        }
                                        </tr>
                                        <tr>
                                            <th className='total'>Total :</th>
                                            <td className='figures'>AED {parseInt(props.OrderSummary.data.PayableAmount).toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                    }
                </Row>
                <Row style={{ width: 67 + '%' }}>
                    <Col>
                        <h3 className='declaration_1'>*We declare that this invoice shows the actual price of the goods described and that all
                            particulars are true and correct.</h3>

                    </Col>
                </Row>

                <div className='line1'></div>

                <Row style={{ marginTop: 40 + 'px' }}>
                    <Col >
                        <h3 className='mcube_address1'>Deira, Dubai - UAE</h3>
                    </Col>
                </Row>

                <footer className='footer_invoice'>
                    <img src={mcubelogosmall} alt="MCUBE GAMES" style={{ width: 25 + 'px', height: 16 + 'px', marginRight: 3 + 'px' }}></img>mcubegames.ae
                    <img src={insta} style={{ width: 15 + 'px', height: 15 + 'px', marginRight: 3 + 'px' }}></img>mcubegames.ae
                    <img src={fb} style={{ width: 15 + 'px', height: 15 + 'px', marginRight: 3 + 'px' }}></img>mcubegames.ae
                    <img src={twitter} style={{ width: 15 + 'px', height: 15 + 'px', marginRight: 3 + 'px' }}></img>McubegamesA
                    <img src={gmail} style={{ width: 30 + 'px', height: 15 + 'px' }}></img>letsplay@mcubegames.ae
                </footer>
            </div>
            <button type="submit" className="btn btn-primary button" onClick={genrateOrderInvoice} style={{ marginRight: 1 + "em", border: "none", color: "white", }} > Download Invoice </button>
        </div>
    )
}

export default InvoiceGenerator