import React from "react";
import NavBarDesktop from '../../components/header/navBar_component'


export default function UniversalNav() {
  return (
    <div>
      <NavBarDesktop />
    </div>
  );
}