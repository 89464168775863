import API_REQUEST from '../config/client';

function register(request){
  var response =  API_REQUEST('auth', request);
  //console.log(response)
  return response;
};

function login(request){
  return  API_REQUEST('auth', request).then((response) => {
      if (response.data){
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("tok", JSON.stringify(response.accessToken));
        return response.data.data
      }else{
        //console.log(response);
        return response;
      }
  });
};

function  logout(){
  localStorage.removeItem("user");
};

export default { register, login, logout };
