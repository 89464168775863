import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import NavCall from "../../menu/main_header";
import { Button } from "antd";
import DataTable from 'react-data-table-component';

function WalletListing() {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState(null);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sdata, setData] = useState("");
  const [sPage, setSPage] = useState("");
  const [uPage, setUPage] = useState("");
  const [wallet, setWallet] = useState(null);
  const [displayPage, setDisplayPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  var DisplaySms = null;
  var page = null;

  useEffect(() => {
    async function fetchData() {
      await API_REQUEST("fetch_listing", { action: "getWallet" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, [offset]);

  const columns = [
    {
      name: 'Transaction Id',
      selector: row => row.TransactionId,
      sortable: true,
    },
    {
      name: 'Customer ID',
      selector: row => row.CustomerID,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.FirstName,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => row.Type,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.Amount,
      sortable: true,
    },
    {
      name: 'Balance',
      selector: row => row.Balance,
      sortable: true,
    },
  ];

  const handleUpdate = async (e) => {
    e.preventDefault();
    page = "updatewallet" + e.currentTarget.value
    setDisplayPage(2);
    setUPage(NavCall(page))
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setOffset((selectedPage - 1) * perPage);
  };

  const handleList = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(1);
    page = "wallet"
    setSPage(NavCall(page))
  });

  const handleBack = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(0);
  });

  const handleClick = (e) => {
    setSearchTerm(e.target.value);
  }


  if (displayPage === 0) {
    return (
      posts &&
      <>
        <div className="button">
          <button type="submit" onClick={handleList} className="btn btn-primary button" > Add Transactions </button>
        </div>

        <div>
          <input type="text" value={searchTerm} onChange={handleClick} placeholder="Search..." className="searchbox" />
        </div>

        <DataTable
          columns={columns}
          data={posts.data}
          reorder
          defaultSortFieldId={1}
          pagination
          highlightOnHover
          data={posts.data.filter((i) => {
            if (searchTerm === "") {
              return i;
            } else if (
              i.CustomerID.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return i;
            }
          })}
        />
      </>
    );
  } else if (displayPage === 1) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {sPage ? sPage : <p>No Data </p>}</>
    );
  } else if (displayPage === 2) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {uPage ? uPage : <p>No Data </p>}</>
    );
  }
}
export default WalletListing;
