import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux"
import Topic from "../ui/topic";
import avatar from "../../assets/img/mg-1.png";

import { login } from "../../actions/auth";

function Login()  {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  
  const dispatch = useDispatch();
  const { message } = useSelector(state => state.message);
  const { isLoggedIn } = useSelector(state => state.auth);
  var md5 = require("md5");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setSuccessful(false);

    var request = {action:'login', 'email':email,'password':md5(password)}

    dispatch(login(request))
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
    }

 
    if (isLoggedIn) {
     return <Redirect to="/" />;
  }
   
        return (
        <div className="page-404 section--full-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-404__wrap">
                   <div className="login-wrapper">
                       <div  className="form-row">
                         <img className="login_user" src={avatar} alt="login user" />
                       </div>
                        <Topic name1={''} name2={'Login'}/>
                        <Form onSubmit={handleLogin}>
                            <Form.Group controlId="formBasicEmail">
                                <div  className="form-row">
                                    <Form.Control className='inpText' type="name" name="email" value={email} onChange={onChangeEmail}  placeholder="Enter Username or Email" />
                                </div>  
                            </Form.Group>
                            
                            <Form.Group controlId="formBasicPassword">
                            <div  className="form-row">
                                <Form.Control  className='inpText' type="password" name="password"  value={password} onChange={onChangePassword}  placeholder="Password" />
                                <Form.Text> <a className="fa fa-caret-right" href="/forgot">Forget Password?</a></Form.Text>
                            </div>     
                            </Form.Group>
                            <div  className='form-row'>
                                <Button className='fag-btn'  type="submit">
                                    Login
                                </Button>
                            </div>
                            <div className="form-row">
                                <Form.Text>Not have an Account <a className="fa fa-caret-right" href="/register">Register?</a> </Form.Text>
                            </div>
                            {/* <div className="socials-wrapper">
                                <p>Login with your Social Account</p>
                                <ul>
                                    <li><a href="#" className="facebook"><i className="fa fa-facebook-square"></i></a></li>
                                    <li><a href="#" className="google"><i className="fa fa-google-plus"></i></a></li>
                                </ul>
                        </div> */}
                        </Form>
                        {message && (
                          <div className="form-group">
                            <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
               </div>
            </div>
         </div>
        </div>    
        );
    
};

export default Login