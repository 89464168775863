import React,{ useState , useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'bootstrap-css-only/css/bootstrap.min.css';
import "../../../assets/css/style.css";
import Topic from "../../ui/topic";
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import {Row, Col, Container, Button, Card} from 'react-bootstrap';
import API_REQUEST from '../../../config/client';
import PAYMENT_REQUEST from '../../../config/payment';
import Items from './Items';
import Spinner from 'react-bootstrap/Spinner';
import {Redirect,useLocation} from 'react-router-dom';
import logo from '../../../assets/img/master-card.jpg';

function Checkout(){
    // VARIABLES ------------------------------------------------------------------------------
    var Displaycartdata =  null;

    const [items,getItem] = useState(null);
    const [addr,getAddr] = useState(null);
    const [values, setValues] = useState({ firstname: '', lastname: '', email: '', mobile: '', address1: '', 
                                            address2: '', city: '', country: '', state: '', pincode: '', mode:"1"});
    const [notes, setNotes] = useState(null);

    const [vcode,getVCode] = useState(null);
    const [voucherValidation,getVoucherValidation] = useState(null);
    const [voucherFlag,getVoucherFlag] = useState(0);
    const [gt,getGT] = useState(null);
    const [checked, setChecked] = useState(false);
    const [wamount,setwamount] = useState(0);
    const [tempWamount,setTempWamount] = useState(0);
    const [loading , setLoading] = useState(true);
    const [Mode,getMode] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const [vMessage,getVMessage] = useState("");
    const [placeOrd,getPlaceOrder] = useState("");
    const [voucherID,getvoucherID] = useState(0);
    const [discountType,getdiscountType] = useState("");
    const [discountAmount,getdiscountAmount] = useState(0);

    const loc = useLocation().search
    const src = new URLSearchParams(loc).get('src')
    var md5 = require("md5");

    const [source,getSource] = useState(null);

    function sleeper(ms) {
        return function (x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }

    // Scroll to top when page renders
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {isLoggedIn} = useSelector(state => state.auth);
    const {user} = useSelector(state => state.auth);
    if (isLoggedIn) {
        var customerId = user.id;
        if (!customerId) {
           customerId = JSON.parse(user).id;
        }
    } else {
        customerId = null;
    }

     const onChangeTw = (e) => {
    const tw = e.target.value;
    //console.log(tw)
    setTempWamount(tw);
  };

    // API REQUEST ----------------------------------------------------------------------------
    useEffect(() => {
       var fetchCart = async () => {
            if(src === "0"){
                getSource(0)
                await API_REQUEST('cart', {action:'getCart','customerId':customerId}).then((response) => getItem(response));
            }else{
                getSource(src)
                await API_REQUEST('buyNow', {action:'getBuyNow','customerId':customerId,'src':src}).then((response) => getItem(response));
            }
       }
       fetchCart();
    },[]);

    useEffect(() => {
        var fetchAddress = async () => {
           await API_REQUEST('checkout', {action:'fetchAddress','customerId':customerId}).then((response) => getAddr(response));
        }
        fetchAddress();
    },[]);

    const handleVoucher = async (e) => {
        e.preventDefault();
        setSuccessful(false);
        if (voucherFlag === 0) {
            var request = {
                action: 'getVoucherValidation',
                customerId: customerId,
                itemsData: items.Data,
                items: items,
                vcode: vcode,
                grandTotal: gt
            }
            await API_REQUEST('voucher', request).then((response) => getVoucherValidation(response));
            setSuccessful(true);
        }else{
            getVMessage('Coupon Code Already Applied');
        }
    };
    if (voucherValidation) {
        if (voucherValidation.status === 200) {
            getGT(voucherValidation.gt);
            getVoucherFlag(1);
            getVMessage(voucherValidation.message);
            getvoucherID(voucherValidation.voucherId);
            getdiscountType(voucherValidation.discType);
            getdiscountAmount(voucherValidation.discAmount);
            getVoucherValidation(null);
        }else{
            getVMessage(voucherValidation.message);
            getVoucherValidation(null);
        }
        
    }
    //console.log(voucherValidation)

    const placeOrder = async (e) => {
        e.preventDefault();
        setSuccessful(false);
        var request = {
           action: 'updateAddress',
           'FirstName': values.firstname,
           'LastName': values.lastname,
           'EmailID': values.email,
           'MobileNumber': values.mobile,
           'Address1': values.address1,
           'Address2': values.address2,
           'city': values.city,
           'StateName': values.state,
           'country': values.country,
           'Pincode': values.pincode,
           'note': notes,
           customerId: customerId,
           source: source,
           itemsData: items.Data,
           items: items,
           voucherId: voucherID,
           discType: discountType,
           discAmount: discountAmount,
           vcode:vcode,
           vflag:voucherFlag,
           grandTotal: gt,
           walletAmount: wamount,
           checked: checked,
           mode: Mode,
           checkout: 'automatic',
           junk: md5(values.firstname + 'Q2!3W4E5£6R$6T7%Y^88U&I*' + items.subtotal.toString() + gt.toString() + discountAmount.toString() + wamount.toString())
        }
        await API_REQUEST('checkout', request).then((response) => getPlaceOrder(response));
       
        setSuccessful(true);
        setLoading(false);
    };
        
    if(placeOrd.status === 750 && Mode==="1"){
        window.location.href="/thankyou";
    }
    if(placeOrd.status === 750 && Mode==="2"){
         window.location.href = "https://mcubegames.ae:8443/mcube_ecomm/mcubefront/api/Database/payment_telr.php?id=" + customerId;
        //  window.location.href = "http://localhost/mcube_ecomm/mcubefront/api/Database/payment_telr.php?id="+customerId;
    }
    else{
        //console.log(placeOrd.message);
    }

    // IF STATEMENT ----------------------------------------------------------------------------
    useEffect(() => {
        if (addr) {
            setValues({
               firstname: addr.Data.firstname,
               lastname:  addr.Data.lastname,
               email:     addr.Data.email,
               mobile:    addr.Data.mobile,
               address1:  addr.Data.address1,
               address2:  addr.Data.address2,
               city:      addr.Data.city,
               country:   addr.Data.country,
               state:     addr.Data.state,
               pincode:   addr.Data.pincode,
            });
        }
    },[addr]);

    useEffect(() => {
        if (items) {
            getGT(items.grandtotal)
        }
    },[items]);

    if (!isLoggedIn) {
        return <Redirect to = "/login" /> ;
    }
    
    if(items){
        //console.log(items)
        Displaycartdata =  items.Data.map(item => (
            <Items key={item.id} name={item.name} subtotal={item.subtotal} quantity={item.qty}/>
        ));
        if (items.message === "Failed") {

            return (
              <>
                <section className="fag-cart-page-area section_100">
                    <Container className="empty-cart-container">
                        <h2 className="h2-empty-cart">Cart is Empty !</h2>
                        <Button className="fag-btn-redirect" href="/Shop">Back to Shop</Button>
                    </Container>
                </section>
              </>
            );
        } 
    }


    // EVENT HANDLERS ----------------------------------------------------------------------------

    const handleWallet = () => {
        setChecked(!checked);
    };

    const handleWalletAmount = (e) => {
        setwamount(tempWamount);
        getPlaceOrder(null);
        setSuccessful(false);
        //console.log(wamount)
        //console.log(addr.walletbalance)
        if (tempWamount > parseInt(addr.walletbalance)) {
            setSuccessful(true);
            getPlaceOrder({message: "Insufficient Balance"})
        } else if (tempWamount > parseInt(items.subtotal)) {
            setSuccessful(true);
            getPlaceOrder({message: "Wallet Amount Entered cannot be Greater than Grand Total"})
        }else{
            var GrandTotal = 0
            if(voucherFlag === 1){
                GrandTotal = gt;
            }else{
                GrandTotal = parseInt(items.subtotal);
            }
            GrandTotal = GrandTotal - tempWamount;
            getGT(GrandTotal);
            setSuccessful(true);
            getPlaceOrder({message: "Amount Deducted from Grand Total, Please place an order"});
        }
    };

    const handleMode = async (e) => {
        getMode(e.target.id);
    };

    //RETURN STATEMENT --------------------------------------------------------------------------     
    return(
        <>
        <section className="checkout-page-area section_100 mt-70">
            <Container>
                <Row>
                <Col lg={8}>
                    <div className="checkout-left-box">
                        <Topic name1={'Billing'} name2={'Details'} />

                        <Form>
                            <Row className="row checkout-form">
                                <Col lg={6}>
                                    <Form.Control placeholder="Your First Name" type="text" name="firstname" id="name23" 
                                        onChange={e => setValues({firstname: e.target.value, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: values.city, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.firstname} value={values.firstname}/>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control placeholder="Your Last Name" type="text" name="lastname" id="name22" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: e.target.value,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: values.city, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.lastname} value={values.lastname}/>
                                </Col>
                            </Row>

                            <Row className="row checkout-form">
                                <Col lg={6}>
                                    <Form.Control placeholder="Your Email Address" type="email" name="info2" id="info2" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: e.target.value, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: values.city, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.email} value={values.email}/>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control placeholder="Your Mobile Number" type="text" name="info2" id="info12" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: e.target.value, address1: values.address1, address2: values.address2,
                                        city: values.city, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.mobile} value={values.mobile}/>
                                </Col>
                            </Row>

                            <Row className="row checkout-form">
                                <Col>
                                    <Form.Control placeholder="Address 1  (House number and street name)" 
                                        type="text" name="address1" id="addr1" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: e.target.value, address2: values.address2,
                                        city: values.city, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.address1} value={values.address1}/>
                                </Col>
                            </Row>

                            <Row className="row checkout-form">
                                <Col>
                                    <Form.Control placeholder="Address 2  (Apartment, suite, unit, etc.)" 
                                        type="text" name="address2" id="addr2" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: e.target.value,
                                        city: values.city, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.address2} value={values.address2}/>
                                </Col>
                            </Row>

                            <Row className="row checkout-form">
                                <Col lg={6}>
                                    <Form.Control placeholder="State" type="text" name="state" id="stateID" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: values.city, country: values.country, state: e.target.value, pincode: values.pincode})} 
                                        defaultValue={values.state} value={values.state}/>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control placeholder="Country" type="text" name="country" id="cntr2" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: values.city, country: e.target.value, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.country} value={values.country}/>
                                </Col>
                            </Row>

                            <Row className="row checkout-form">
                                <Col lg={6}>
                                    <Form.Control placeholder="Town / City" type="text" name="town" id="Town2" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: e.target.value, country: values.country, state: values.state, pincode: values.pincode})} 
                                        defaultValue={values.city} value={values.city}/>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control placeholder="Pincode" type="text" name="pincode" id="pincodeID" 
                                        onChange={e => setValues({firstname: values.firstname, lastname: values.lastname,
                                        email: values.email, mobile: values.mobile, address1: values.address1, address2: values.address2,
                                        city: values.city, country: values.country, state: values.state, pincode: e.target.value})} 
                                        defaultValue={values.pincode} value={values.pincode}/>
                                </Col>
                            </Row>

                            <Row className="row checkout-form">
                                <Col>
                                    <textarea placeholder="Write Order Note Here..." name="note" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </Col>
                <Col lg={4}>
                    <div className="order-summury-box">
                        <Topic name1={'Order'} name2={'Summary'}/>
                        <div className="summury-inn">
                            <Table className="checkout-table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items && Displaycartdata ? Displaycartdata : <Spinner animation="border" variant="secondary" />
                                    }
                                    <tr>
                                        <td><h5>Subtotal</h5></td>
                                        <td><h5>
                                            AED{
                                            items && Displaycartdata ? items.subtotal : <Spinner animation="border" variant="secondary" />
                                            }
                                        </h5></td>
                                    </tr>
                                    <tr>
                                        <td><p>Shipping Fee:</p></td>
                                        <td><p>
                                            {
                                            gt < 200 ? < p > AED 10 </p> : <p>Free</p >
                                            }
                                        </p></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="booking-right">
                        <Form action="https://mcubegames.ae:8443/mcube_ecomm/mcubefront/razorpay-php-testapp-master/razorpay-php-testapp-master/pay.php" method="POST">
                            <Row className="row-coupon">
                                <Col lg={6} className="col-coupon-code">
                                    <Form.Control placeholder="Enter Coupon Code" 
                                        type="text" className="coupon-code"
                                        name="CouponCode" id="couponcode"
                                        onChange={e => getVCode(e.target.value)}/>
                                </Col>
                                <Col lg={6} className="col-apply-coupon">
                                    <Button variant="primary" className="apply-code-btn" id="apply_btn" onClick={handleVoucher}>APPLY COUPON</Button>
                                </Col>
                            </Row>

                            {vMessage && (
                                <div className="form-group">
                                    <br />
                                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                        {vMessage}
                                    </div>
                                </div>
                            ) }
                            { gt < 200 ? <h3 className="grand-total-h3">
                                            Grand Total : AED{gt+10}
                                        </h3> : 
                            <h3 className="grand-total-h3">
                                Grand Total : AED{gt}
                            </h3>
                            }

                            <div className="fag-payment clearfix" >

                            {addr && addr.walletbalance !== "0.00" ? <div>
                                <Row className="row checkout-form">
                                    <Col lg={6}>
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Wallet Payment?"
                                            className="wallet-payment"
                                            onClick={handleWallet}
                                            checked={checked}
                                        />
                                    </Col>
                                    <Col lg={6} className="wallet-balance">
                                        Wallet Balance - AED{addr ? addr.walletbalance : <p></p> }
                                    </Col>
                                </Row>
                                <Row className="row checkout-form">
                                    <Col lg={6}>
                                        <Form.Control placeholder="Enter Amount" type="number" className="wallet-amount" name="WalletAmount" id="wallamount"
                                            value={tempWamount} onChange={onChangeTw} 
                                            />
                                    </Col>
                                    <Col lg={6} className="use-btn-col" id="fag-paymentt" >
                                        <Image src="https://img.icons8.com/ios-glyphs/30/ffffff/refresh--v2.png" 
                                        onClick={handleWalletAmount}
                                        />
                                    </Col>
                                </Row>
                             </div> :  <div><Col lg={6} className="wallet-balance">
                                        Wallet Balance - AED{addr ? addr.walletbalance : <p></p> }
                                    </Col> </div>}


                                <div>
                                    <FormCheck
                                        type="radio"
                                        label="Cash on delivery"
                                        name="selector"
                                        id="1"
                                        htmlFor="ss-option"
                                        onClick={handleMode}
                                    />
                                </div>
                                <div>
                                    <FormCheck
                                        type="radio"
                                        label="Credit Card/Debit Card/NetBanking"
                                        name="selector"
                                        id="2"
                                        htmlFor="f-option"
                                        onClick={handleMode}
                                    />
                                    <Image src={logo} alt="credit card"/>
                                    <p>Your personal data will be used to process your order,
                                        support your experience throughout this website, 
                                        and for other purposes described in our 
                                        <Card.Link className="privacy-policy" href="/privacy-policy"> privacy policy.</Card.Link>
                                    </p>
                                </div>
                                
                            </div>
                            {placeOrd.message && (
                                <div className="form-group">
                                    <br />
                                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                        {placeOrd.message}
                                    </div>
                                </div>)}
                            <div className="action-btn">
                            <button type="submit" className="fag-btn" onClick={placeOrder}>Place Order</button>
                            </div>
                        </Form>

                    </div>
                </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default Checkout;