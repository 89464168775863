import React from 'react'
import API_REQUEST from '../../../config/client';
import {Redirect} from 'react-router-dom';

function HandleBuyNowOnSubmit(props){
    var storeProps = null

    if(props.isLoggedIn){

        async function getCartDataFromDb() {
            var request = { action: 'handle_add_to_buynow', id: props.id, customer_id: props.customer_id, isLoggedIn:props.isLoggedIn, qty : props.qty }
            //console.log(request)
            storeProps = await API_REQUEST('buyNow', request).then((response) => response)

            return storeProps;
        }
        getCartDataFromDb();
    }
    else{
        return <Redirect to = "/login" /> ;
    }
}

export default HandleBuyNowOnSubmit;