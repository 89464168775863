import React, { useState } from "react";
import API_REQUEST from "../../../config/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";


function Blogs() {

    const [blogsResult, getBlogsResult] = useState(null)
    const [data, setData] = useState({
        // BlogId: "",
        BlogName: "",
        Slug: "",
        Description: "",
        BlogLink: "",
        BlogSlug: "",
        action: "process_blogs"
    });

    const [selectFiles, setSelectedFiles] = useState()

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    function handleClick(e, editor) {
        setData({ ...data, Description: editor.getData() })
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)

    }

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles[0])
        file_data.append('request_data', JSON.stringify(data))
        async function processBlogsForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getBlogsResult(response.data))

        }
        processBlogsForm()
    }


    return (
        <div className="container_admin admin_body form">
            <form onSubmit={(e) => submit(e)}>
                <br />

                <div className="col-sm-5">
                    <label>Blog Name</label> <br />
                    <input onChange={(e) => handle(e)} id="BlogName" name="BlogName" value={data.BlogName} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Slug</label> <br />
                    <input onChange={(e) => handle(e)} id="BlogSlug" name="BlogSlug" value={data.BlogSlug} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description" name="Description" editor={ClassicEditor} onChange={(e, editor) => { handleClick(e, editor) }} requierd />
                    <br /><br />
                </div>


                <div className="col-sm-5">
                    <label>Blog Link</label> <br />
                    <input onChange={(e) => handle(e)} id="BlogLink" value={data.BlogLink} name="BlogLink" className=" form-control" type="text" required />
                    <br />
                </div>


                <div className="col-sm-5">
                    <label>Blog Thumbnail</label> <br />
                    <br></br>
                    <input type="file" accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} required />
                    <br></br> <br></br>
                </div>
                <br></br>


                <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    blogsResult ? <Alert message={blogsResult.message} type="success" /> : <p></p>
                }
            </form>
        </div>
    )
}

export default Blogs
