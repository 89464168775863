import React from "react";
import FooterDesktop from '../../components/footer/footer_component'


export default function UniversalFooter() {
  return (
    <div>
      <FooterDesktop />
    </div>
  )
}