import React from 'react';

import Slider from "../components/content-section/home/slider";

import UniversalNav from './template/header';
import UniversalFooter from './template/footer';

import Categories from '../components/content-section/home/categories';
import ExclusiveDeals from '../components/content-section/home/exclusiveDeals';
import Newsletter from '../components/content-section/home/newsletter';
import Blogs from '../components/content-section/home/blogs';
import { Helmet } from 'react-helmet';
import Jwt_Decode from 'jwt-decode';



if (localStorage.getItem("tok")) {
    const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("tok"));
    if (jwt_Token_decoded.data.exp * 1000 < Date.now()) {
        localStorage.clear();
        window.location.href = "/login";
    }
}


function HomePage(){
return (
    <>
    <Helmet>
            <title>Buy PS5, PS4, Xbox, PC, Nintendo games and accessories online at Best Price in UAE</title>‍
            <meta name="description" conttent="Shop the top, latest, upcoming, best ps4, ps5, xbox, pc and nintendo games online and get best deals and offers on video games and accessories only on Mcube Games."/>
            <meta name="keywords" content="Shopping, Gaming Store, Playstation Games, Top Games Online, Xbox Games, PS5 Games Purchase, PS4 Games Online, Nintendo Switch, PC Games, Gaming Accessories, Action Figures, Bestselling Games, Mcube, Mcubegames, Mcube Games Online"/>
    </Helmet>
    <UniversalNav/>
    <Slider/>
    <ExclusiveDeals/>
    <Categories/>
    <Newsletter/>
    <Blogs/>
    <UniversalFooter />
</>
);
}

export default HomePage;
