import React , {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import { Modal, Button, Rate } from 'antd';

import ProductQueryListing from './query_listing'
import API_REQUEST from '../../../config/client';



function ProductQuery(props){
    //console.log(props);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Please Write Your Review');
    const [reviewText, setReviewText] = useState("")
    const [recordResponse, getRecordedResponse] = useState(0);

    const [handleStar, getHandleStar] = useState(0)
    var commentlist = null;
    function handle(e) {
        setReviewText(e.target.value)
    }

    async function fetchSingleProductDetails() {
        var request_data = {action:"save_recorded_response", reviewText:reviewText,handleStar:handleStar, user_meta : props.meta_info}
        await API_REQUEST('single_product', request_data).then((response) => { getRecordedResponse(response)})
    }

    const showModal = () => {
      setVisible(true);
    };
  
    const handleOk = async () => {
      setModalText('Please wait while we record you response');
      setConfirmLoading(true);  
      await fetchSingleProductDetails()
    };

    useEffect(() => {
        if(recordResponse.status === 1){
            setModalText('Thank you for submitting your valuable feedback')
            setTimeout(() => {
              setVisible(false);
              setConfirmLoading(false);
            }, 2000);
        }
    },[recordResponse])
  
    const handleCancel = () => {
      //console.log('Clicked cancel button');
      setVisible(false);
    };

    function handleStarChange(e) {
        getHandleStar(e)
    }

    if(props.reviews.status !== 401){
         commentlist = props.reviews.Data.userdata.map(item => (
            <ProductQueryListing id = {item.ReviewID} review_meta = {item}/>
        ))
    }
   

    return (
        <div>
            <Row>
                <Col md={6}></Col>
                <Col md={6} className="writeReview">
                { props.reviews.Data.cnt === '0' ? <p></p>:
                    <Button type="primary"onClick={showModal}>
                        Write a review
                    </Button>
                }
                <div  className="cursorr">
                    <Modal
                        className="reviewAll"
                        title="Customer Review"
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        <p>{modalText}</p>

                        <textarea  onChange={(e) => handle(e)} value={reviewText} className="comment" placeholder="Review..." name="comment" rows="5" cols="45"></textarea>
                        <br></br>

                        <Rate
                            allowHalf
                            style={{ color: '#ffa11a' }}
                            onChange={(e) => handleStarChange(e)}
                            value={handleStar}
                        />  
                        
                    </Modal>
                    </div>
                </Col>
                
            </Row>
            {props.reviews.status === 401 ? <p> No reviews found </p>:commentlist}
        </div>
    );
}

export default ProductQuery