import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import API_REQUEST from "../../../config/client";


function Attribute() {
  const [attributeResult, getAttributeResult] = useState(null)
  const [data, setData] = useState({
    pagename: "",
    pagetitle: "",
    description: "",
    image: "",
    metatitle: "",
    metakeyword: "",
    metadescription: "",
    formcheck: "",
  });

  function handleClick(e, editor) {
    setData({ ...data, description: editor.getData() })
    console.log(data)
  }


  function handle(e) {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
  }

  function submit(e) {
    e.preventDefault();
    async function processAttributeForm() {
      var request = { action: 'process_attribute', form_data: data }
      await API_REQUEST('catalogue', request).then((response) => getAttributeResult(response))
    }
    processAttributeForm()
  }


  const attributeprops = {
    beforeUpload: file => {
      if (file.type !== 'image/png') {
        message.error(`${file.name} is not a png file`);
      }
      return file.type === 'image/png' ? false : Upload.LIST_IGNORE;
    },
    onChange: info => {
      console.log(info.fileList);
    },
  };


  return (
    <div className="container_admin admin_body form">
      <br />
      <form onSubmit={(e) => submit(e)} >


        <div className="col-sm-5">
          <label>Page Name*</label> <br />
          <input onChange={(e) => handle(e)} id="pagename" value={data.pagename} name="pagename" className="form-control" type="text" required placeholder="Privacy Policy" />
          <br />
        </div>

        <div className="col-sm-5">
          <label>Page Title *</label>
          <br />
          <input onChange={(e) => handle(e)} id="pagetitle" value={data.pagetitle} type="text" className="form-control" name="pagetitle" required placeholder="Privacy Policy" />
          <br />
        </div>

        <div className="col-sm-5">
          <label>Description</label>
          <CKEditor
            editor={ClassicEditor}
            onChange={(e, editor) => { handleClick(e, editor) }} />
          <br />
        </div>

        <div className="col-sm-5">
          <label>Cms Image</label>
          <br></br>
          <Upload {...attributeprops}>
            <Button icon={<UploadOutlined />}>Choose File</Button>
          </Upload>
          <br></br>
        </div>

        <div className="col-sm-5">
          <label>Meta Title</label> <br />
          <input onChange={(e) => handle(e)} id="metatitle" value={data.metatitle} type="text" className="form-control" name="metatitle" required />
          <br />
        </div>

        <div className="col-sm-5">
          <label>Meta Keyword</label> <br />
          <input onChange={(e) => handle(e)} id="metakeyword" value={data.metakeyword} type="text" className="form-control" name="metakeyword" required />
          <br />
        </div>

        <div className="col-sm-5">
          <label>Meta Description</label> <br />
          <textarea onChange={(e) => handle(e)} id="metadescription" value={data.metadescription} type="text" className="form-control" rows="3" name="metadescription" required />
        </div>
        <br />

        <div className="form-check">
          <input onChange={(e) => handle(e)} id="formcheck" value={data.formcheck} className="form-check-input" type="checkbox"></input>
          <label className="form-check-label" forhtml="flexCheckDefault"> Is Active </label>
        </div>

        <br></br>
        <br></br>

        <button type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
        <button type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
      </form>
    </div>
  );
}

export default Attribute;
