import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faYoutube,faFacebook,faTwitter,faInstagram, faGooglePlus } from '@fortawesome/free-brands-svg-icons';

function SocialIcons(){
    return (
        <div className="footer-social">
            <ul className="fa-ul">
                <li>
                    {/* <a href="https://www.youtube.com/channel/UC_Y8Csqz531Wly5CAqQk9Jw"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faYoutube} size="1x" />
                    </a> */}
                    <a href="https://www.facebook.com/mcubegames.ae/"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </a>
                    <a href="https://mobile.twitter.com/McubegamesA"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </a>
                    <a href="https://www.instagram.com/mcubegames.ae/"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </a>
                    <a href="https://goo.gl/maps/yWiPkxVwc1VewanQ8"
                        className="social" target="_blank" >
                        <FontAwesomeIcon icon={faGooglePlus} size="1x" />
                    </a>
                </li>
            </ul>   
        </div>
    )
}

export default SocialIcons