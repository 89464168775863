import React, { useState } from "react";
import API_REQUEST from "../../../config/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";

function News_Letter() {

    const [newsResult, getNewsResult] = useState(null)
    const [data, setData] = useState({
        Title: "",
        Headline: "",
        Description: "",
        NewsLink: "",
        Priority: "",
        Date: "",
        IsActive: true,
        action: "process_news"
    });

    const [selectFiles, setSelectedFiles] = useState()

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    function handleClick(e, editor) {
        setData({ ...data, Description: editor.getData() })
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)

    }

    

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles[0])
        file_data.append('request_data', JSON.stringify(data))
        async function processNewsForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getNewsResult(response.data))

        }
        processNewsForm()
    }

  return (
    <>
        <div className="container_admin admin_body form">
            <form onSubmit={(e) => submit(e)}>
                <br />

                <div className="col-sm-5">
                    <label>Title</label> <br />
                    <input onChange={(e) => handle(e)} id="Title" name="Title" value={data.Title} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Headline</label> <br />
                    <input onChange={(e) => handle(e)} id="Headline" name="Headline" value={data.Headline} maxlength="45" className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description" name="Description" editor={ClassicEditor} maxlength="55" onChange={(e, editor) => { handleClick(e, editor) }} requierd />
                    <br /><br />
                </div>


                <div className="col-sm-5">
                    <label>News Link</label> <br />
                    <input onChange={(e) => handle(e)} id="NewsLink" value={data.NewsLink} name="NewsLink" className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Priority</label> <br />
                    <input onChange={(e) => handle(e)} id="Priority" value={data.Priority} name="Priority" className=" form-control" type="text" required />
                    <br />
                </div>


                <div className="col-sm-5">
                    <label>News Thumbnail</label> <br />
                    <br></br>
                    <input type="file" accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} required />
                    <br></br> <br></br>
                </div>
                <br></br>

                <div className="col-4">
              <label>Date</label>
              <input
                onChange={(e) => handle(e)}
                id="Date"
                className="form-control"
                type="date"
                name="Date"
                style={{ width: "200px" }}
              />
              <br></br>
            </div>

            <div className="col-sm-5">
              <label for="scales">IsActive</label>
              <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />
            </div>
            <br></br>


                <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    newsResult ? <Alert message={newsResult.message} type="success" /> : <p></p>
                }
            </form>
        </div>
    </>
  )
}

export default News_Letter