import React, { useState, useEffect, useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from "../../../config/client";
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";

function UpdateGenre(props) {

    const [genreResult, getGenreResult] = useState(null)
    const [fetchGenre, getFetchGenre] = useState("");
    const [availstatus, getAvailStatus] = useState(false)

    const { genreId } = props;

    const [data, setData] = useState({
        Name: "",
        Slug: "",
        Description: "",
        action: "process_genre"
    });

    useEffect(() => {
        async function fetchData() {
            var request = {
                action: 'fetchGenre',
                genreId: genreId
            }
            await API_REQUEST('fetch', request).then((response) => getFetchGenre(response));
        }
        fetchData();

    }, []);


    useEffect(() => {
        if (fetchGenre) {
            console.log(fetchGenre)
            setData({
                ID: fetchGenre.ID,
                Name: fetchGenre.Name,
                Slug: fetchGenre.Slug,
                Description: fetchGenre.Description,
                IsActive: fetchGenre.IsActive,
                action: "updategenre",
                genreId: genreId
            })

            getAvailStatus(true)
        }
    }, [fetchGenre]);

    console.log(data)

    function handleClick(e, editor) {
        setData({ ...data, Description: editor.getData() })
        console.log(data)
    }

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }


    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append('request_data', JSON.stringify(data))
        async function processGenreForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getGenreResult(response.data))

        }
        processGenreForm()
    }

    return (
        availstatus &&
        <div className="container_admin admin_body form">
            <br />

            <form onSubmit={(e) => submit(e)}>
                <br></br>

                <div className="col-sm-5">
                    <label>Name*</label>
                    <br></br>
                    <input onChange={(e) => handle(e)} id="Name" value={data.Name} name="Name" className="form-control" type="text" required placeholder="Puzzle" />
                    <br />
                </div>


                <div className="col-sm-5">
                    <label>Slug</label>
                    <br />
                    <input onChange={(e) => handle(e)} id="Slug" value={data.Slug} type="text" className="form-control" name="Slug" required placeholder="Puzzle" />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description"
                        editor={ClassicEditor}
                        name="Description" onChange={(e, editor) => { handleClick(e, editor); }}
                        onReady={editor => {
                            editor.setData(data.Description)
                        }}
                    />
                    <br /><br />
                </div>



                <div className="col-sm-5">

                    <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Update </button>
                    <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                    {
                        genreResult ? <Alert message={genreResult.message} type="success" /> : <p></p>
                    }
                </div>
            </form>
        </div>
    );
}

export default UpdateGenre
