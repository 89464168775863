import React,{ useState , useEffect,useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row , Col , Container , Button , Table , Card } from 'react-bootstrap';

// import "../../../assets/css/style.css";
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import '../../../assets/css/style.css'
import Spinner from 'react-bootstrap/Spinner'
import Topic from "../../ui/topic";
import { useHistory } from 'react-router-dom';

import API_REQUEST from '../../../config/client';
import Items from './Items';


function CartDetails(){
   // VARIABLES ------------------------------------------------------------------------------
   var Displaycartdata = null;
   const [items,getItem] = useState({success: 1, status: 400, Data: '', message: "Failed", grandtotal: 0});
   const [Clear,clear] = useState(null);
   const {user} = useSelector(state => state.auth);
   const {isLoggedIn} = useSelector(state => state.auth);
   const [gtcount, handlegtcount] = useState(0)
   let history = useHistory();

   function sleeper(ms) {
      return function (x) {
         return new Promise(resolve => setTimeout(() => resolve(x), ms));
      };
   }

   // Scroll to top when page renders
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   // API REQUEST ----------------------------------------------------------------------------
   useEffect(() => {
      if(isLoggedIn){
         var customerId = user.id;
         //console.log('custcart ' + customerId)
         if (!customerId) {
            customerId = JSON.parse(user).id;
         }
         //console.log('custcart ' + customerId)
         var fetchCart = async () => {
            await API_REQUEST('cart', {action:'getCart',customerId: customerId}).then((response) => getItem(response));
         }
         fetchCart();
      }else{
         var localstorage = localStorage.getItem('cartlist')
         if(localstorage !== null){
            localstorage = JSON.parse(localStorage.cartlist)
            //console.log(localstorage)
         var nonlogItems = {success: 0, status: 200, message: "success", grandtotal: 0}
            var localarray = []
            var offerp = []
            var gt = 0;
            for (let value of Object.values(localstorage)) {
               var item_qty = value['qty']
               if(gtcount !== 0){
                  item_qty = gtcount
               }
               if (value['OfferPrice'] === 0){
                  value['subtotal'] = value['price']*item_qty;
               } else{
                  value['subtotal'] = value['OfferPrice']*item_qty;
               }
               gt += value['subtotal']
               localarray.push(value);
            }
            //console.log(localarray)
            nonlogItems["Data"] = localarray;
            nonlogItems["grandtotal"] = gt 
            getItem(nonlogItems)
         }else{
            getItem({success: 1, status: 400, Data: '', message: "Failed", grandtotal: 0})
         }
      }
      
   },[isLoggedIn, user, gtcount]);

   var clearCart = async () => {
      
      if(isLoggedIn){
         var customerId = user.id;
         if (!customerId) {
            customerId = JSON.parse(user).id;
         }
         await API_REQUEST('cart', {action:'clearAll',customerId: customerId}).then((response) => clear(response));
      }else{
         clear(localStorage.removeItem("cartlist"));
         getItem({success: 1, status: 400, Data: '', message: "Failed", grandtotal: 0})
      }  
      
   }
 
   const handlegt = useCallback(data => {
      //console.log(data)
      handlegtcount(data);
   },[])

   //console.log(items)

   if(items.status === 200){
      //console.log(items)
      Displaycartdata =  items.Data.map(item => (<Items key={item.id} 
                                                        id={item.id} 
                                                        pid={item.pid} 
                                                        image={item.image} 
                                                        name={item.name} 
                                                        price={item.price} 
                                                        handlegt = {data=>handlegt(data)} 
                                                        subtotal={item.subtotal} 
                                                        quantity={item.qty} 
                                                        itemsInStock={item.Quantity} 
                                                        OfferPrice={item.OfferPrice} 
                                                        Slug={item.Slug} />
      ));
      if (items.Data.length === 0) {

         return (
           <>
             <section className="fag-cart-page-area section_100">
                 <Container className="empty-cart-container">
                     <h2 className="h2-empty-cart">Cart is Empty !</h2>
                     <Button className="fag-btn-redirect" href="/Shop">Back to Shop</Button>
                 </Container>
             </section>
           </>
         );
      } 
   }

   if(Clear != null|| items.status !== 200){
      return (
           <>
             <section className="fag-cart-page-area section_100">
                 <Container className="empty-cart-container">
                     <h2>Cart is Empty !</h2>
                     <Button className="fag-btn-redirect" href="/Shop">Back to Shop</Button>
                 </Container>
             </section>
           </>
      );
   }

   //RETURN STATEMENT --------------------------------------------------------------------------
   return( 
      <>
      <section className="fag-cart-page-area section_100 mt-70">
         <Container>
            <Row>
               <Col lg={8}>
                  <div className="cart-table-left">
                  <Topic name1={'Shopping'} name2={'Cart'}/>
                     <div className="table-responsive cart_box">
                        <Table className="table">
                           <thead>
                              <tr>
                                 <th>Preview</th>
                                 <th>Product</th>
                                 <th>Price</th>
                                 <th>Quantity</th>
                                 <th>Total</th>
                                 <th>Remove</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 items && Displaycartdata ? Displaycartdata : <Spinner animation="border" variant="secondary" />
                              }
                           </tbody>
                        </Table>
                     </div>
                     <div className="cart-clear">
                        <Card.Link onClick={clearCart}>Clear Cart</Card.Link>
                     </div>
                  </div>
               </Col>
               <Col lg={4}>
                  <div className="order-summury-box">
                  <h3 className="order-summary">Order <h3>Summary</h3></h3>
                     <div className="summury-inn">
                        <Table>
                           <tbody>
                              <tr>
                                 <td><h3>Grand Total</h3></td>
                                 <td>
                                    <h3>
                                       AED{
                                          items && Displaycartdata ? items.grandtotal : <Spinner animation="border" variant="secondary" />
                                       }
                                    </h3>
                                 </td>
                              </tr>
                           </tbody>
                        </Table>
                     </div>
                  </div>
                  <div className="checkout-action">
                     <Button onClick={() => {history.push("/checkout?src=0")}} className="fag-btn">Proceed to checkout</Button>
                  </div>
               </Col>
            </Row>
         </Container>
      </section>
      </>
);

}

export default CartDetails;