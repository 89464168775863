import React, { useState } from 'react'
import API_REQUEST from '../../../config/client';
import {notification } from 'antd';
import { uuid } from 'uuidv4';


function HandleCartOnSubmit(props){
    //console.log(props)
    var storeProps = null

    if(props.isLoggedIn){

        async function getCartDataFromDb() {
            var request = { action: 'handle_add_to_cart', id: props.id, customer_id: props.customer_id, isLoggedIn:props.isLoggedIn, qty : props.qty, Slug : props.Slug }
            //console.log(request)
            storeProps = await API_REQUEST('single_product', request).then((response) => response)

            return storeProps;
        }
        getCartDataFromDb()
    }
    else{
        async function getProductDetails() {
            var request = { action: 'singleProduct_details', product_slug: props.Slug}
            storeProps = await API_REQUEST('single_product', request).then((response) => response)
            var obj_store = localStorage.getItem('cartlist')
            //hardcoded values need to write logic
            var cart_item = {id:uuid(),image:storeProps.Data.Image, name:storeProps.Data.Name,price:storeProps.Data.Price, qty:props.qty,pid:storeProps.Data.id,Quantity:storeProps.Data.Quantity,OfferPrice:storeProps.Data.OfferPrice, Slug : props.Slug}
            //console.log(cart_item)
            //console.log(obj_store)
            var obj = {}
            if(obj_store === null){
                obj[cart_item.pid] = cart_item
            }else{
                obj = JSON.parse(obj_store);
                obj[cart_item.pid] = cart_item
            }
            localStorage.setItem('cartlist',JSON.stringify(obj));
        }
        getProductDetails()
    }
}

const OpenNotification = (cartIem) => {
    notification.open({
      message: 'Keep Exploring',
      description:
        `${cartIem} is Added to your Cart`,
      onClick: () => {
        //console.log('Notification Clicked!');
      },
    });
  };

  const NotifyUs = (cartIem) => {
    notification.open({
      message: 'Thanks for Showing Interest',
      description: `${cartIem} `,
      onClick: () => {
        //console.log('Notification Clicked!');
      },
    });
  };

export {
  HandleCartOnSubmit,
  OpenNotification,
  NotifyUs
}