import React, { useEffect, useState } from "react";

import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import logo from '../../assets/img/mcube/logo/logo FINAL-02.png';

import API_REQUEST from '../../config/client';

import { ShoppingCartOutlined ,SearchOutlined, UserOutlined} from '@ant-design/icons';
import { Badge } from 'antd';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Input } from 'antd';



export default function NavBarDesktop() {
  const [items1, setItems] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [list, getList] = useState(null);
  const [count, getCount] = useState(null);
  const [cartCount, setCartCount] = useState(0);

  const [scroll, setScroll] = useState(false);
  const {isLoggedIn} = useSelector(state => state.auth);

  const { user } = useSelector(state => state.auth);

  if (isLoggedIn) {
    var firstname = user.firstname;
    var CustomerID = user.id;
    if (!firstname) {
      firstname = JSON.parse(user).firstname;
      CustomerID = JSON.parse(user).id;
    }
  } else {
    firstname = null;
    CustomerID = 0;
  }

  const dispatch = useDispatch();
  const logoutbtn = () => {
    dispatch(logout());
    window.location.href = "/login";
  }

  const handleSearchSubmit = () => {
    const searchRedirect = "/shop?s1="+ search;
    window.location.href=searchRedirect;
  }

  var items = []

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getProdDetails',
        searchString: "",
        sortString: "",
        categoryString: null,
        priceMin: 10,
        priceMax: 5000000,
        plat1: 0,
        plat2: 0,
        plat3: 0,
        plat4: 0,
        plat5: 0,
        plat6: 0,
        plat7: 0,
        plat8: 0,
        plat9: 0,
        plat10: 0,
        plat11: 0,
        gen1: 0,
        gen2: 0,
        gen3: 0,
        gen4: 0,
        gen5: 0
      }
  
      await API_REQUEST('fetchData', request).then((response) => getList(response)); 
    }
    fetchAddress();
  },[]);

  useEffect(() => {
    if(list != null){ 
      list.Data.map(i => (
          items.push({id:parseInt(i.ProductID),name:i.Name,Slug: i.Slug},)
        ))
        setItems(items);
        setLoading(false);
        getList(null)
    }
    //console.log(items1)
  },[items]);

  useEffect(() => {
    var fetchCount = async () => {
      var request = {
        action: 'getCartCount',
        CustomerID: CustomerID
      }

      await API_REQUEST('fetchData', request).then((response) => getCount(response));
    }
    fetchCount();
  }, [CustomerID]);

  useEffect(() => {
    if (count != null) {
      setCartCount(count.Data.Cnt)  
    }
  }, [count]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearch(string);
    //console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    const link = "/product/" + item.Slug;
    window.location.href=link;

    //console.log(item)
  }

  const handleCtg = async (e) => {
    const link = "/shop?id=" + e.target.id;
    window.location.href = link;
  }

  const handleShop = async (e) => {
    window.location.href = '/shop';
  }

  const handleOnFocus = () => {
    //console.log('Focused')
  }

  const formatResult = (item) => {
   // return item ;
    return (<p className="search-p" dangerouslySetInnerHTML={{__html: '<strong>'+item.name+'</strong>'}}></p>); //To format result as html
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  if (isLoading) {
    return <div className="App">Fetching the Best Experience...</div>;
  }

  return (
    <>
    
    <section className="mcube-logo-section">
      <div className="mcube-logo-mobile">
        <Navbar.Brand as={Link} to="/" >
          <img alt="" src={logo} className=" logo"/>{' '}
        </Navbar.Brand>
        <Nav className="cart-login-mobile">
          {
            (cartCount === 0) ?
              <Nav.Link as={Link} to="/cart" className="cart-mobileview">
                <ShoppingCartOutlined className="cart-mobileview" style={{ fontSize: '30px', color: '#fff' }} />
              </Nav.Link> :
              
              <Nav.Link as={Link} to="/cart" className="cart-mobileview">
                <Badge count={cartCount}>
                <ShoppingCartOutlined className="cart-mobileview" style={{ fontSize: '30px', color: '#fff' }} />
                </Badge>
              </Nav.Link>
          }

        <Nav.Link className="dropdown-mobileview">
            
            <NavDropdown  style={{ fontSize: '30px', color: '#fff' }} className="dropdown-mobileview" title={firstname ? <UserOutlined style={{ fontSize: '30px', color: '#fff' }}/> : "Sign Up/Sign In"} id="collasible-nav-dropdown">
              {!firstname ? <NavDropdown.Item as={Link} to="/register" > Sign Up </NavDropdown.Item> : <NavDropdown.Item as={Link} to="/profile" > Profile </NavDropdown.Item>}
              {!firstname ? <NavDropdown.Item as={Link} to="/login" > Sign In </NavDropdown.Item> : <NavDropdown.Item as={Link} to="/myorders" > My Orders </NavDropdown.Item>}
              {!firstname ? < p > </p> : <NavDropdown.Item as={Link} to="#" onClick={logoutbtn} > Logout </NavDropdown.Item>}
            </NavDropdown>
          </Nav.Link>
          </Nav>
      </div>
    </section>
    <div className="toggle-search-mobile">
      
      <Navbar className={scroll ? "fag-header scroll" : "fag-header"} expand="lg">
        <div className="container">
          <Navbar.Brand as={Link} to="/" className="navbar-brand-logo">
            <img alt="" src={logo} width="140" height="60" className=" logo"/>{' '}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" id="nav-menu-mobile"/>
           { items ? 
            <Nav className="header-searchbar">
              <ReactSearchAutocomplete items={items1}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult} 
                styling={{searchIconMargin: '0 0 0 16px'}}
                placeholder = "Search your product"/>
                <SearchOutlined onClick = {handleSearchSubmit}/> 
            </Nav> : <p></p>} 
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" id="nav-menu-ipad"/>
        
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="header_menu mr-auto" id="menu-dropdown-mobile">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <NavDropdown title="Categories" className="categories-dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item id={23} onClick={handleCtg}>Mcube Exclusive</NavDropdown.Item>
                <NavDropdown.Item id={9} onClick={handleCtg}>Digital Cards</NavDropdown.Item>
                <NavDropdown.Item id={102} onClick={handleCtg}>ps5</NavDropdown.Item>
                <NavDropdown.Item id={46} onClick={handleCtg}>ps4</NavDropdown.Item>
                <NavDropdown.Item id={58} onClick={handleCtg}>switch</NavDropdown.Item>
                <NavDropdown.Item id={71} onClick={handleCtg}>xbox one</NavDropdown.Item>
                <NavDropdown.Item id={65} onClick={handleCtg}>wii</NavDropdown.Item>
                <NavDropdown.Item id={19} onClick={handleCtg}>All Accessories</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link}  onClick={handleShop}>Shop</Nav.Link>
              <Nav.Link as={Link} to="/cart" className="cart-anchor">Cart</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              <Nav.Link as={Link} to="/about">About Us</Nav.Link>
              <Nav.Link className="dropdown-mobile">
                  <NavDropdown className="dropdown-mobile" title={firstname ? firstname : "Sign Up/Sign In"} id="collasible-nav-dropdown">
      
                    {!firstname ? < NavDropdown.Item as={Link} to="/register" > Sign Up </NavDropdown.Item> : < NavDropdown.Item as={Link} to="/profile" > Profile </NavDropdown.Item>}
                    {!firstname ? < NavDropdown.Item as={Link} to="/login" > Sign In </NavDropdown.Item> : < NavDropdown.Item as={Link} to="/myorders" > My Orders </NavDropdown.Item>}
                    {!firstname ? < p > </p> : <NavDropdown.Item as={Link} to="#" onClick={logoutbtn} > Logout </NavDropdown.Item>}
                  </NavDropdown>
              </Nav.Link>
              {/* <Nav className="header-right" id="header-right-mobile">
                <Nav.Link className="dropdown-mobileview">
                  < NavDropdown className="dropdown-mobileview" title={firstname ? firstname : "Sign Up/Sign In"} id="collasible-nav-dropdown">
                    {!firstname ? < NavDropdown.Item as={Link} to="/register" > Sign Up </NavDropdown.Item> : < NavDropdown.Item as={Link} to="/profile" > Profile </NavDropdown.Item>}
                    {!firstname ? < NavDropdown.Item as={Link} to="/login" > Sign In </NavDropdown.Item> : < NavDropdown.Item as={Link} to="/myorders" > My Orders </NavDropdown.Item>}
                    {!firstname ? < p > </p> : <NavDropdown.Item as={Link} to="#" onClick={logoutbtn} > Logout </NavDropdown.Item>}
                  </NavDropdown>
              
                </Nav.Link>
              </Nav> */}
            </Nav>

            

          </Navbar.Collapse>

          <Nav className="header-right" id="header-right-desktop">
            {
              (cartCount === 0) ?

                <Nav.Link as={Link} to="/cart" className="cart-mobileview">
                  <ShoppingCartOutlined className="cart-mobileview" style={{ fontSize: '30px', color: '#fff' }} />
                </Nav.Link> :
                <Nav.Link as={Link} to="/cart" className="cart-mobileview">
                  <Badge count={cartCount}>
                    <ShoppingCartOutlined className="cart-mobileview" style={{ fontSize: '30px', color: '#fff' }} />
                  </Badge>
                </Nav.Link>
            }
            <Nav.Link className="dropdown-mobileview">
              <NavDropdown className="dropdown-mobileview" title={firstname ? firstname : "Sign Up/Sign In"} id="collasible-nav-dropdown">
      
                {!firstname ? < NavDropdown.Item as={Link} to="/register" > Sign Up </NavDropdown.Item> : < NavDropdown.Item as={Link} to="/profile" > Profile </NavDropdown.Item>}
                {!firstname ? < NavDropdown.Item as={Link} to="/login" > Sign In </NavDropdown.Item> : < NavDropdown.Item as={Link} to="/myorders" > My Orders </NavDropdown.Item>}
                {!firstname ? < p > </p> : <NavDropdown.Item as={Link} to="#" onClick={logoutbtn} > Logout </NavDropdown.Item>}
              </NavDropdown>
            </Nav.Link>
          </Nav>

        </div>
      </Navbar>
    </div>
    </>
  );
}


/* <span style={{marginLeft:'10px'}}> $12.00</span> */
