import React, { useState } from "react";
import API_REQUEST from "../../../config/client";


function Changepassword() {

    const [pswResult, getPswResult] = useState(null)
    const [data, setData] = useState({
        oldpassword: "",
        newpassword: "",
        confirmpassword: ""
    });

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    function submit(e) {
        e.preventDefault();
        async function processPswForm() {
            var request = { action: 'process_psw', form_data: data }
            await API_REQUEST('myaccount', request).then((response) => getPswResult(response))
        }
        processPswForm()
    }

    return (
        <div className="container form">
            <form onSubmit={(e) => submit(e)}>
                <br></br>

                <div className="col-sm-5">
                    <label htmlFor="oldpassword">Old Password *</label> <br />
                    <input onChange={(e) => handle(e)} id="oldpassword" value={data.oldpassword} name="oldpassword" className="form-control" type="password" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>New Password *</label>
                    <br />
                    <input onChange={(e) => handle(e)} id="newpassword" value={data.newpassword} type="text" className="form-control" name="newpassword" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Confirm Password *</label> <br />
                    <input onChange={(e) => handle(e)} id="confirmpassword" value={data.confirmpassword} type="text" className="form-control" rows="3" name="confirmpassword" required />
                    <br />
                </div>

                <button className="btn btn-primary button" type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>

            </form>

        </div>
    )
}

export default Changepassword
