import React,{ useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { Image} from 'react-bootstrap';
import "react-alice-carousel/lib/alice-carousel.css";
import Get_image from '../../../config/get_image';
import API_REQUEST from '../../../config/client';
import { LeftOutlined, RightOutlined ,LoadingOutlined} from "@ant-design/icons";
import { Carousel } from 'antd';
import Img from 'react-cool-img';

const contentStyle = {
  height: "300px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79"
};



function Slider() {

  const [items, getItem] = useState(null);
  var count = null;
  var Displaycartdata = null

  useEffect(() => {
    var request = { action: 'getSlider'}
    async function fetchCart() {
      await API_REQUEST('home', request).then((response) => {getItem(response)});
    }

    fetchCart();
  }, []);

  if(items != null){
    ////console.log("mau"+items.Data.length);
    //console.log(items.Data);
   count = parseInt(items.Data.length, 10);
      Displaycartdata =  items.Data.map((item, index) => (
        <Link to={item.linkto}>
            <Img
              style={{display:"block"}}
              className="w-100 resp-img"
              src={Get_image("banner",item.tempcode,item.img_url)}
              debounce={1000} // Default is 300 (ms)
              alt={index + 1}/>
               {/* <Image
              style={{display:"block"}}
              className="w-100 resp-img"
              style={{display: "block"}}
              src={Get_image("banner",item.tempcode,item.img_url)}
              alt={index + 1}/> */}
         </Link>
        
      ));
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  
    return(
      <Carousel
      autoplay 
      arrows
      prevArrow={<LeftOutlined />}
      nextArrow={<RightOutlined />}>
        {       
          Displaycartdata && items  ? Displaycartdata : <Spin indicator={antIcon} />
        }
      </Carousel>
        
  
    );
}

export default Slider;