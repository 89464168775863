import React, {useState, useEffect} from 'react'
import API_REQUEST from '../../config/client';

import { Menu } from 'antd';
import {
    PieChartOutlined,
    FileOutlined,
    UserOutlined,
    SettingOutlined,
    EnvironmentOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    ToolOutlined
  } from '@ant-design/icons';

const { SubMenu } = Menu;

function MenuAPI(props){
    const [menuOptions, getMenuOptions] = useState(null);
    const { handleClick } = props;

    var arrangeMenu = null;

    useEffect( () => {
        async function fetchPanel(){
            await API_REQUEST('nav',{action:'getMenu'}).then((response) => getMenuOptions(response))
        }
        fetchPanel()
    },[])

    if(menuOptions != null){
        const icons = [<PieChartOutlined/>,<ShopOutlined />,<ShoppingCartOutlined />,<EnvironmentOutlined />,<FileOutlined/>,<ToolOutlined />,<UserOutlined/>, <SettingOutlined/>];
        arrangeMenu = menuOptions.data.map((menu, idx) => (menu.hasOwnProperty("submenu")) ? 
            (<SubMenu key={menu.MenuId} icon={icons[idx]} title={menu.Name}>
                {menu.submenu.map(submenu =>(
                    <Menu.Item key={submenu.Name} onClick = {handleClick}>{submenu.Name}</Menu.Item>
                ))}
            </SubMenu>  ):
            (<Menu.Item key={menu.MenuId} icon={<PieChartOutlined />}>{menu.Name}</Menu.Item>)
        );
    }
    
    return(
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            {
                menuOptions && arrangeMenu
            }
        </Menu>
    )
}


export default MenuAPI