import {React} from 'react';
// import Banner from '../components/header/banner';
import UniversalNav from '../components/header/navBar_component';
import ShopMain from '../components/Shop/shopmain';
import 'bootstrap-css-only/css/bootstrap.min.css';
import UniversalFooter from './template/footer';
import { Helmet } from 'react-helmet';

import Jwt_Decode from 'jwt-decode';



if (localStorage.getItem("tok")) {
    const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("tok"));
    if (jwt_Token_decoded.data.exp * 1000 < Date.now()) {
        localStorage.clear();
        window.location.href = "/login";
    }
}

function ShopPage(){
return(
<>
<Helmet>
            <title>Mcube Games Buy PS5, PS4, Xbox, PC, Nintendo games, accessories and Merchendise online at Best Price in UAE</title>‍
            <meta name="description" conttent="Mcube Games Shop the top, latest, upcoming, best ps4, ps5, xbox, pc and nintendo games online and get best deals and offers on video games and accessories only on Mcube Games."/>
            < meta name = "keywords" content = "Mcube Games, Shopping, Gaming Store, Playstation Games, Top Games Online, Xbox Games, PS5 Games Purchase, PS4 Games Online, Nintendo Switch, PC Games, Gaming Accessories, Action Figures, Bestselling Games, Mcube, Mcubegames, Mcube Games Online" / >
    </Helmet>
<UniversalNav />
{/* <Banner header={'Shop Page'}/> */}
<ShopMain />
<UniversalFooter />

</>
);

}

export default ShopPage;