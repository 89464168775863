import React from 'react'
import {Row, Col} from 'react-bootstrap'
import renderHTML from 'react-render-html';
import ShowMore from 'react-show-more-button';

function ProductDescripton(props){
    return (
        <div className="tab-pane fade active show" id="description" role="tabpanel" aria-labelledby="description-tab">
            <div className="tab-gamess-details">
            <Row>
                <Col md={12}>
                    <div className="tab-body">
                        <ShowMore maxHeight={100} backgroundColor={"#0d0e1b"} styleButton={{backgroundColor:"#ff7a21"}}>
                            {renderHTML(props.content)}
                        </ShowMore>
                    </div>
                </Col>
            </Row>
            </div>
        </div>
    )
}

export default ProductDescripton