import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import { Button } from "antd";
import NavCall from "../../menu/main_header";
import DataTable from 'react-data-table-component';
import Title from "antd/lib/skeleton/Title";

function Discount_listing() {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState(null);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sdata, setData] = useState("");
  const [sPage, setSPage] = useState("");
  const [uPage, setUPage] = useState("");
  const [product, setProduct] = useState(null);
  const [displayPage, setDisplayPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  var DisplaySms = null;
  var page = null;

  useEffect(() => {
    async function fetchData() {
      await API_REQUEST("fetch_listing", { action: "getDiscountListing" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, [offset]);

  const columns = [
    {
      name: 'VoucherId',
      selector: row => row.VoucherId,
      sortable: true,
    },
    {
      name: 'VoucherCode',
      selector: row => row.VoucherCode,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row.Title,
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row.Discount,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => <Button value={row.VoucherId} onClick={handleUpdate}>  <i value={row.VoucherId} class="fa fa-edit"></i> </Button>,
      sortable: true,
    },
  ];


  const handleUpdate = async (e) => {
    e.preventDefault();
    page = "updatediscount" + e.currentTarget.value
    setDisplayPage(2);
    setUPage(NavCall(page))
  };


  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setOffset((selectedPage - 1) * perPage);
  };


  const handleList = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(1);
    page = "discount"
    setSPage(NavCall(page))
  });


  const handleBack = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(0);
  });

  const handleClick = (e) => {
    setSearchTerm(e.target.value);
  }


  if (displayPage === 0) {
    return (
      posts &&
      <div>
        <div className="button">
          <button type="submit" onClick={handleList} className="btn btn-primary button" > Add Voucher </button>
        </div>

        <div>
          <input type="text" value={searchTerm} onChange={handleClick} placeholder="Search..." className="searchbox_admin" />
        </div>

        <DataTable
          columns={columns}
          data={posts.data}
          pagination
          highlightOnHover
          data={posts.data.filter((i) => {
            if (searchTerm === "") {
              return i;
            } else if (
              i.VoucherCode.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return i;
            }
          })}
        />
      </div>
    );
  } else if (displayPage === 1) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {sPage ? sPage : <p>No Data </p>}</>
    );
  } else if (displayPage === 2) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {uPage ? uPage : <p>No Data </p>}</>
    );
  }
}

export default Discount_listing;
