import React, { useState } from 'react'
import MenuAPI from './menu_api'
import { Layout, Breadcrumb } from 'antd';
import Attribute from '../forms/attribute/attribute'
import CategoryForm from '../forms/category/category_form'
import GenreForm from "../forms/genre/genre_form"
import EmailForm from '../forms/email_content/Email_content';
import SmsTemplate from '../forms/sms_content/Sms_content';
import Testimonial from '../forms/testimonials/Testimonial';
import Banners from '../forms/banner/banners';
import DiscountForm from '../forms/discount/Discount_form';
import Discount_listing from '../forms/discount/Discount_listing';
// import Discount_listing from '../forms/discount/Discount_listing'
import UpdateCustomer from '../forms/customer/Customer';
import GoogleAnalytics from '../forms/google_analytics/google_analytics';
import Wallet from '../forms/wallet/Wallet';
import Changepassword from '../forms/change_password/Change_password';
import Myprofile from '../forms/my_profile/My_profile';
import Exclusivedeals from '../forms/exclusive_deals/Exclusive_deals';
import Blogs from '../forms/blogs/Blogs';
import EmailListing from '../forms/email_content/email_listing';
import CategoryListing from '../forms/category/category_listing';
import GenreListing from '../forms/genre/genre_lisitng';
import BlogListing from '../forms/blogs/blogs_listing';
import ExclusiveListing from '../forms/exclusive_deals/exclusive_listing';
import WalletListing from '../forms/wallet/wallet_listing';
import TestimonialListing from '../forms/testimonials/testimonial_listing';
import BannerListing from '../forms/banner/banner_listing';
import MyProfileListing from '../forms/my_profile/myprofile_listing';
import GoogleListing from '../forms/google_analytics/google_listing';
import ChangePswListing from '../forms/change_password/changepsw';
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom";
import SmsListing from '../forms/sms_content/sms_listing';
import Product_form from '../forms/product/Product_form';
import ProductListing from '../forms/product/Product_listing';
import OrderListing from '../forms/order/order_listing';
import Customer_listing from '../forms/customer/Customer_listing';
import Excdeals_reports from '../forms/reports/Excdeals_reports'
import BulkUplaod from '../forms/bulk_operation/bulk_operation'
import Newsletter from '../../../components/content-section/home/newsletter';
import News_Letter from '../forms/news_letter/News_letter';
import Update_News from '../forms/news_letter/Update_Newsletter';
import NewsListing from '../forms/news_letter/News_listing';


const { Header, Content, Footer, Sider } = Layout;

function AdminNav() {
    const [collapsed, onClickCollapse] = useState(false)
    const [render, updateRender] = useState('Dashboard');

    const components = {
        // FROM DASHBOARD
        'Dashboard': <div> <Excdeals_reports /> </div>,
        // 'Dashboard': <div> <News_Letter /> </div>,

        // FROM CATALOGUE
        'Category': <CategoryListing />,
        'Genre': <div><GenreListing /></div>,
        'Brand': <div >TO BE DONE</div>,
        'Product': <div> <ProductListing /> </div>,
        'Cart Products': <div >CART PRODUCTS TO BE DONE</div>,
        'Attribute': <Attribute />,
        'Blogs': <div><BlogListing /></div>,
        'Exclusive Deals': <div><ExclusiveListing /></div>,
        'News Letter': <div> <NewsListing /> </div>,


        // FROM ORDERS
        'Order': <div><OrderListing /></div>,
        'Wallet': <div><WalletListing /></div>,
        'Reports': <div> <Excdeals_reports /> </div>,

        // LOCATION SETTING
        // 'Country': <div>COUNTRY TO BE DONE</div>,
        // 'State': <div>STATE TO BE DONE</div>,
        // 'City': <div>CITY TO BE DONE</div>,

        // CMS
        'Pages': <div> TO BE DONE </div>,
        'Email Content': <div><EmailListing /></div>,
        'SMS Template': <div><SmsListing /></div>,


        // OTHERS
        'Contact': <div>CONTACT PAGE TO BE DONE</div>,
        'Reviews': <div>REVIEW PAGE TO BE DONE</div>,
        // 'Testimonial': <div><TestimonialListing /></div>,
        'Banner': <div><BannerListing /></div>,
        'Discount': <div><Discount_listing /></div>,
        'Customer': <div><Customer_listing /></div>,
        'Widget': <div>WIDGET PAGE TO BE DONE</div>,
        'Subscribers': <div>SUBSCRIBER PAGE TO BE DONE</div>,
        'Request': <div>REQUEST PAGE TO BE DONE</div>,
        'Bulk Operation': <div><BulkUplaod /></div>,


        // MY ACCOUNT

        'My Profile': <div><MyProfileListing /></div>,
        'Change Password': <div><Changepassword /></div>,

        // SETTING

        'Role': <div>ROLE IS TO BE DONE</div>,
        'Admin User': <div>ADMIN USER TO BE DONE</div>,
        'Menus': <div>MENUS TO BE DONE</div>,
        'General Settings': <div>GENERAL SETTINGS TO BE DONE</div>,
        'Wallet Settings': <div>WALLET SETTINGS TO BE DONE</div>,
        'Google Analytics': <div><GoogleAnalytics /></div>,
    };

    const handleMenuClick = menu => {
        updateRender(menu.key);
    };

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={() => onClickCollapse(!collapsed)}>
                    <div className="logo" />
                    <MenuAPI handleClick={handleMenuClick} />
                </Sider>
                {/* layout section shift to another component */}
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }} />
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>

                            <Breadcrumb.Item><a href="">Home</a></Breadcrumb.Item>
                            <Breadcrumb.Item>{render}</Breadcrumb.Item>
                            {/* <Breadcrumb.Item>Form</Breadcrumb.Item> */}

                        </Breadcrumb>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            {components[render]}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Mcube Games ©2020</Footer>
                </Layout>
            </Layout>
        </div>
    )
}

export default AdminNav;