import React, { useState } from "react";
import API_REQUEST from "../../../config/client";
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";

function SmsTemplate() {

  const [smsResult, getSmsResult] = useState(null)
  const [data, setData] = useState({
    Title: "",
    SFrom: "",
    Message: "",
    IsActive: true,
    action:"process_smstemplate"
  });

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
}


  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append('request_data', JSON.stringify(data))
    async function processSmsForm() {
        console.log(file_data)
        var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
        var fetchData = axios.post(CONST_URL +'cms.php', file_data).then((response) => getSmsResult(response.data))
        
    }
    processSmsForm()
}

const selectShortlistedApplicant = (e) => {
  const checked = e.target.checked;
  if (checked) {
    setData({ ...data, IsActive: "1" });
  } else {
    setData({ ...data, IsActive: "0" });
  }
};

  return (
    <div className="container_admin admin_body form">
      <form onSubmit={(e) => submit(e)} >
        <br />


        <div className="col-sm-5">
          <label htmlFor="title">Title</label> <br />
          <input onChange={(e) => handle(e)} id="Title" value={data.Title} name="Title" className="form-control" type="text" required placeholder="Order Cancelled" />
          <br />
        </div>


        <div className="col-sm-5">
          <label>From</label>
          <br />
          <input onChange={(e) => handle(e)} id="SFrom" value={data.SFrom} type="text" className="form-control" name="SFrom" required placeholder="9999999999" />
          <br />
        </div>


        <div className="col-sm-5">
          <label>Message</label> <br />
          <textarea onChange={(e) => handle(e)} id="Message" value={data.Message} type="text" className="form-control" rows="3" name="Message" required placeholder="Your Order #OrderNo# Cancelled" />
        </div>
        <br />


        <div className="col-sm-5">
          <label for="scales">IsActive</label>
          {/* {data.IsActive === "1" ? (
            <input
            checked
              type="checkbox"
              className="checkbox team_values"
              checked
              onClick={(e) => {
                selectShortlistedApplicant(e);
              }}
            />
          ) : (
            <input
            checked
              type="checkbox"
              className="checkbox team_values"
              onClick={(e) => {
                selectShortlistedApplicant(e);
              }}
            />
          )} */}

          <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />

        </div>
        <br></br>
        
        
          <button className="btn btn-primary button " type="submit"  style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
          <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
          {
          smsResult ? <Alert message={smsResult.message} type="success"/> : <p></p>
          }
      </form>
    </div>
  );
}

export default SmsTemplate;


// const cancelOrder = async (e) => {
//   e.preventDefault();
//   var request = {
//       action: 'getMenu'
//   }
//   await API_REQUEST('fetch', request).then((response) => setPosts(response));

// };

// return (
//   <>
//     <button type="submit" onClick={cancelOrder} style={{ borderRadius: "10px",marginTop: "5px" }} className="fag-btn">Fetch Data</button>
//   </>
// );
// }
