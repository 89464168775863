import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from "../../../config/client";
import axios from "axios";
import { Alert } from "antd";
import CONST_URL from "../../../config/url";

function EmailForm() {
  const [emailResult, getEmailResult] = useState(null);
  const [data, setData] = useState({
    Title: "",
    FromName: "",
    FromEmail: "",
    MailSubject: "",
    Content: "",
    IsActive: true,
    action: "process_emailcontent",
  });

  function handleClick(e, editor) {
    setData({ ...data, Content: editor.getData() });
    console.log(data);
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
  }

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append("request_data", JSON.stringify(data));
    async function processEmailForm() {
      console.log(file_data);
      var BASE_URL =
        "http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/";
      var fetchData = axios
        .post(CONST_URL + "cms.php", file_data)
        .then((response) => getEmailResult(response.data));
    }
    processEmailForm();
  }

  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setData({ ...data, IsActive: "1" });
    } else {
      setData({ ...data, IsActive: "0" });
    }
  };


  return (
    <div className="container_admin admin_body form">
      <form onSubmit={(e) => submit(e)}>
        <br />
        <div className="col-sm-5">
          <label>Title</label> <br />
          <input
            onChange={(e) => handle(e)}
            id="Title"
            value={data.title}
            name="title"
            className=" form-control"
            type="text"
            placeholder="Order Failed"
            required
          />
          <br />
        </div>

        <div className="col-sm-5">
          <label>From</label>
          <br />
          <input
            onChange={(e) => handle(e)}
            id="FromName"
            value={data.from}
            type="text"
            className="form-control"
            name="from"
            placeholder="Mobile Games"
            required
          />
          <br />
        </div>

        <div className="col-sm-5">
          <label forhtml="exampleInputEmail1">From E-mail</label>
          <input
            onChange={(e) => handle(e)}
            id="FromEmail"
            value={data.fromemail}
            type="email"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Email@example.com"
            name="fromemail"
          />
          <small id="emailHelp" className="form-text text-muted">
            {" "}
            We'll never share your email with anyone else.{" "}
          </small>
          <br />
        </div>
        <br></br>

        <div className="col-sm-5">
          <label>Subject</label> <br />
          <input
            onChange={(e) => handle(e)}
            id="MailSubject"
            value={data.subject}
            name="Subject"
            className="form-control"
            type="text"
            placeholder="Order #OrderID# has failed"
          />
          <br />
        </div>

        <div className="col-sm-5">
          <label>Content</label>
          <CKEditor
            id="Content"
            name="Content"
            editor={ClassicEditor}
            onChange={(e, editor) => {
              handleClick(e, editor);
            }}
          />
          <br /> <br />
        </div>

        <div className="col-sm-5">
          <label for="scales">IsActive</label>

          <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />

        </div>
        <br></br>

        <button
          type="submit"
          className="btn btn-primary button "
          style={{
            marginRight: 1 + "em",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
          }}
        >
          {" "}
          Submit{" "}
        </button>
        <button
          type="reset"
          className="btn btn-primary button "
          style={{
            marginRight: 1 + "em",
            border: "none",
            backgroundColor: "#FF0000",
            color: "white",
          }}
        >
          {" "}
          <i className="fas fa-arrow-left"></i> Cancel{" "}
        </button>
        {emailResult ? (
          <Alert message={emailResult.message} type="success" />
        ) : (
          <p></p>
        )}
      </form>
    </div>
  );
}

export default EmailForm;
