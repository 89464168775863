import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import { Button } from "antd";
import NavCall from "../../menu/main_header";

function MyProfileListing() {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState(null);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sdata, setData] = useState("");
  const [sPage, setSPage] = useState("");
  const [uPage, setUPage] = useState("");
  const [category, setCategory] = useState(null);
  const [displayPage, setDisplayPage] = useState(0);
  var DisplaySms = null;
  var page = null;

  useEffect(() => {
    async function fetchData() {
      await API_REQUEST("fetch", { action: "getMyProfile" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, [offset]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    page = "updatemyprofile" + e.currentTarget.value
    setDisplayPage(2);
    setUPage(NavCall(page))
  };

  if (posts != null) {
    var slices = posts.data.slice(offset, offset + perPage);
    DisplaySms = slices.map((posts) => (
      <tbody>
        <td>{posts.UserID}</td>
        <td>{posts.UserName}</td>
        <td>{posts.FirstName}</td>
        <td>{posts.LastName}</td>
        <td>{posts.EmailId}</td>
        <td>{posts.MobileNo}</td>
        <td>{posts.ImageName}</td>
        <td>
          <Button value={posts.UserID} onClick={handleUpdate}>  <i value={posts.UserID} class="fa fa-edit"></i> </Button>
          <Button> <i class="fas fa-trash-alt"></i> </Button>
        </td>
      </tbody>
    ));
    setPageCount(Math.ceil(posts.data.length / perPage));
    setPosts(null);
    setData(DisplaySms);
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setOffset((selectedPage - 1) * perPage);
  };

  const handleList = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(1);
    var page = "profile"
    setSPage(NavCall(page))
  });

  const handleBack = useCallback((e) => {
    e.preventDefault();
    setDisplayPage(0);
  });

  if (displayPage === 0) {
    return (
      <>

        <div className="button">
          <button type="submit" onClick={handleList} className="btn btn-primary button" > Add Profile </button>
        </div>

        <div className="container">
          <table className="listing-2 table-responsive">
            <thead>
              <tr>
                <th className="id">ID</th>
                <th>UserName</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th>Profile Pic</th>
                <th>Action</th>
              </tr>
            </thead>

            {sdata ? sdata : <p>No Data </p>}
          </table>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            className="fag-paginate"
          />
        </div>
      </>
    );
  } else if (displayPage === 1) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none",  color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {sPage ? sPage : <p>No Data </p>}</>
    );
  } else if (displayPage === 2) {
    return (
      <>
        <div className="button">
          <button style={{ marginRight: 1 + "em", border: "none",  color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
        </div>

        {uPage ? uPage : <p>No Data </p>}</>
    );
  }
}

export default MyProfileListing;
