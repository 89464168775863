import {React} from 'react';
import Forgot from '../components/auth/forgot';
import 'bootstrap-css-only/css/bootstrap.min.css';
import UniversalNav from '../components/header/navBar_component';


function ForgotPage(){
return(
<>
<UniversalNav/>
<Forgot />
</>
);

}

export default ForgotPage;