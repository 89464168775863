import React from 'react'
import renderHTML from 'react-render-html';

function Gameplay(props) {
    if(props.videolink){
        return (
            <>
                {renderHTML(props.videolink)}
                
            </>
        )
    }else{
        return (
            <>
            <p>No gameplay found</p>
            </>
        )
    }

}

export default Gameplay
