import React, { useState } from 'react';
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import CONST_URL from "../../../config/url";



function GoogleAnalytics() {

  const [googleResult, getGlogleResult] = useState(null)
  const [data, setData] = useState({
    Text: "",
    Value: "",
    action:"process_google"
    });

    function handle(e) {
      const newdata = { ...data }
      newdata[e.target.id] = e.target.value
      setData(newdata)
      console.log(newdata);
  }

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append('request_data', JSON.stringify(data))
    async function processGoogleForm() {
        console.log(file_data)
        var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
        var fetchData = axios.post(CONST_URL +'setting.php', file_data).then((response) => getGlogleResult(response.data))
        
    }
    processGoogleForm()
}

  return (
    <div className="container_admin admin_body form">
      <form onSubmit={(e) => submit(e)}>
        <br></br>

        <div className="col-sm-5">
          <label>Google Analytics Text*</label>
          <input onChange={(e) => handle(e)} id="Text" value={data.Text} name="Text" className=" form-control" type="text" placeholder="Google Analytics" required />
        </div>
        <br></br>


        <div className="col-sm-5">
          <label>Value</label>
          <textarea onChange={(e) => handle(e)} id="Value" value={data.Value} name="Value" type="text" className="form-control" placeholder="" required />
          <small id="emailHelp" className="form-text text-muted">
            (Please enter in your google analytics tracking code here. Remember to include the entire script from google, if you just enter your tracking Id it won't work.)
          </small>
        </div>

        <br></br>

        <button className="btn btn-primary button" type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
        <button className="btn btn-primary button" type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>

      </form>
    </div>


  )
}

export default GoogleAnalytics