import React, { useState, useEffect, useCallback } from "react";
import { Form, Button } from 'react-bootstrap';
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";

function Update_Deals(props) {

    const [dealsResult, getDealsResult] = useState(null)
    const [fetchExclusive, getFetchExclusive] = useState("");
    const [selectFiles, setSelectedFiles] = useState([]);

    const { excId } = props;

    const [data, setData] = useState({
        name: "",
        dealLink: "",
        dealDescription: "",
        FileImage: 0,
        IsActive: true,
        action: "process_deals"
    });


    useEffect(() => {
        async function fetchData() {
            var request = {
            action: 'fetchExclusive',
                excId: excId
            }
            await API_REQUEST('fetch', request).then((response) => getFetchExclusive(response));
        }
        fetchData();

    }, []);

    useEffect(() => {
        if (fetchExclusive) {
            console.log(fetchExclusive)
            setData({
                name: fetchExclusive.name,
                dealLink: fetchExclusive.dealLink,
                dealDescription: fetchExclusive.dealDescription,
                IsActive: fetchExclusive.IsActive,
                FileImage: 0,
                action: "updateexclusivedeals",
                excId: excId
            })
        }
    }, [fetchExclusive]);

    console.log(data)


    function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setData(newdata);
        console.log(e.target.value);
        console.log(newdata);
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)
        setData((data) => ({ ...data, FileImage: 1 }));
    }

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        if (selectFiles.length !== 0) {
            file_data.append("file", selectFiles[0]);
        }
        file_data.append('request_data', JSON.stringify(data))
        async function processDealsForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getDealsResult(response.data))

        }
        processDealsForm()
    }

    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setData({ ...data, IsActive: '1' })
        } else {
            setData({ ...data, IsActive: '0' })
        }
    };

    return (
        <div className="container_admin admin_body form">
            <Form onSubmit={(e) => submit(e)}>
                <br />

                <div className="col-sm-5">
                    <label>Deal Name</label> <br />
                    <input onChange={(e) => handle(e)} id="name" name="name" value={data.name} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Deal Image</label> <br />
                    <br></br>
                    <input type="file"  accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange}  />
                    <br></br> <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Deal Link</label> <br />
                    <input onChange={(e) => handle(e)} id="dealLink" value={data.dealLink} name="dealLink" className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Deal Description</label> <br />
                    <textarea onChange={(e) => handle(e)} id="dealDescription" value={data.dealDescription} name="dealDescription" className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label for="scales">IsActive</label>
                    {
                        data.IsActive === '1' ?
                            <input
                                type="checkbox"
                                className="checkbox team_values"
                                checked
                                onClick={(e) => {
                                    selectShortlistedApplicant(e);
                                }
                                } /> :
                            <input
                                type="checkbox"
                                className="checkbox team_values"
                                onClick={(e) => {
                                    selectShortlistedApplicant(e);
                                }} />
                    }
                </div>
                <br></br>


                <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Update </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    dealsResult ? <Alert message={dealsResult.message} type="success" /> : <p></p>
                }
            </Form>
        </div>
    )
}

export default Update_Deals
