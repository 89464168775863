import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import ReactPaginate from "react-paginate";
import { Button,Tabs } from "antd";
import NavCall from "../../menu/main_header";
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

const TextField = styled.input`
height: 32px;
width: 200px;
border-radius: 3px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;

&:hover {
cursor: pointer;
}
`;

const ClearButton = styled(Button)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
height: 34px;
width: 32px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`;

const { TabPane } = Tabs;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

function OrderListing() {

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [selectedTab, updateSelectedTab] = useState("All");
    const [togglePagintation, getToggledPagination] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");

    const [sPage, setSPage] = useState("");
    const [uPage, setUPage] = useState("");
    const [displayPage, setDisplayPage] = useState(0);

    var page = null;

    const fetchUsers = async (page, selectedTab) => {
        console.log("fetchusers")
        console.log(selectedTab);
        let isMounted = true;
        setLoading(true);

        var request = { action: "getOrdersListing", page: page, per_page: perPage, status: selectedTab }
        var fetchData = await API_REQUEST("order_operation", request).then((response) => response);

        setData(fetchData.data)
        setTotalRows(fetchData.total);
        setLoading(false);

        return () => { isMounted = false };

    };


    const handlePageChange = page => {
        fetchUsers(page, selectedTab);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        console.log("handlePerRowsChange")
        console.log(selectedTab);
        setLoading(true);
        console.log("new per page", newPerPage)
        var request = { action: "getOrdersListing", page: page, per_page: newPerPage, status: selectedTab }
        var fetchData = await API_REQUEST("order_operation", request).then((response) => response);

        setData(fetchData.data);
        setPerPage(newPerPage);
        setLoading(false);

    };

    useEffect(() => {
        fetchUsers(1, selectedTab);
    }, [selectedTab]);

    useEffect(() => {
        async function fetchFilterData() {
            var request = {
                action: "getOrdersFilter",
                fileterData: filterText
            }
            var filter_data = await API_REQUEST("order_operation", request).then((response) => response);

            if (filter_data.data !== null) {
                const filteredItems = filter_data.data.filter(
                    item => item.OrderId || item.ShipTo && item.ShipTo.toLowerCase().includes(filterText.toLowerCase()),
                );
                setData(filteredItems);
            }
        }
        if (filterText !== '') {
            fetchFilterData()
        }
    }, [filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const handleClickTab = (e) => {
        updateSelectedTab(e.target.value)
    }

    const handleSumamryview = async (rowid) => {
        // e.preventDefault();
        console.log(rowid)
        page = "order_summary" + rowid
        setDisplayPage(1);
        setSPage(NavCall(page))
    };

    const handleBack = useCallback((e) => {
        e.preventDefault();
        setDisplayPage(0);
    });

     const handleClick = (e) => {
         setSearchTerm(e.target.value);
     }

    const columns = [
        {
            name: 'Order',
            cell: row => (<div className="ordershyperlink" value={row.OrderId} onClick={() => handleSumamryview(row.OrderId)}>
                # {row.OrderId + ' ' + row.ShipTo}
            </div>),
            sortable: true,
        },
        {
            name: 'Date',
            selector: 'OrderDate',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'Status',
            sortable: true,
        },
        {
            name: 'Total',
            selector: 'TotalPrice',
            sortable: true,
        }
    ];
    if (displayPage === 0) {
        return (
            <div>
                <div className="navBtnGroup">
                    <button  className="ordertablebutton" key="All" name="All" value={"All"} onClick={e => handleClickTab(e, "value")}>All</button>
                    <button  className="ordertablebutton" key="Order Placed" name="Order Placed" value={"Order Placed"} onClick={e => handleClickTab(e, "value")}>Order Placed</button>
                    <button  className="ordertablebutton" key="Processing" name="Processing" value={"Processing"} onClick={e => handleClickTab(e, "value")}>Processing</button>
                    <button  className="ordertablebutton" key="Hold" name="Hold" value={"Hold"} onClick={e => handleClickTab(e, "value")}>Hold</button>
                    <button  className="ordertablebutton" key="Shipped" name="Shipped" value={"Shipped"} onClick={e => handleClickTab(e, "value")}>Shipped</button>
                    <button  className="ordertablebutton" key="Cancelled" name="Cancelled" value={"Cancelled"} onClick={e => handleClickTab(e, "value")}>Cancelled</button>
                    <button  className="ordertablebutton" key="Failed" name="Failed" value={"Failed"} onClick={e => handleClickTab(e, "value")}>Failed</button>
                    <button  className="ordertablebutton" key="Delivered" name="Delivered" value={"Delivered"} onClick={e => handleClickTab(e, "value")}>Delivered</button>
                    <button  className="ordertablebutton" key="Completed" name="Completed" value={"Completed"} onClick={e => handleClickTab(e, "value")}>Completed </button>
                    <button  className="ordertablebutton" key="PreOrder" name="Completed" value={"PreOrder"} onClick={e => handleClickTab(e, "value")}>PreOrder</button>
                </div>
              
                <DataTable
                    title="Orders"
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    selectableRows
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}

                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                />
            </div>
        )
    } else if (displayPage === 1) {
        return (
            <>
                <div className="button">
                    <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
                </div>

                {sPage ? sPage : <p>No Data </p>}</>
        );
    } else if (displayPage === 2) {
        return (
            <>
                <div className="button">
                    <button style={{ marginRight: 1 + "em", border: "none", color: "white", }} type="submit" onClick={handleBack} className="btn btn-primary button" > Back </button>
                </div>

                {uPage ? uPage : <p>No Data </p>}</>
        );
    }

}

export default OrderListing