import { React, useCallback, useEffect,useState } from 'react';
import API_REQUEST from '../../config/client';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Rate } from 'antd';
import { useSelector } from 'react-redux';
import {HandleCartOnSubmit, OpenNotification, NotifyUs} from '../utils/mcube_helper/purchase_item';
import Get_image from '../../config/get_image';
import { Card, Row , Button} from 'react-bootstrap';
import HandleBuyNowOnSubmit from '../utils/mcube_helper/buynow_item';

const Cardis = (props) => {
    const { key , Name , ProdImg , desc ,prodId,offerprice, price , DiscountPerc ,RatingAvg, Quantity,CategoryName,CategoryID,Slug} = props;
    const [productQty, getProductQty] = useState(null);
    const [buttonText, setButtonText] = useState('Notify');
    const [notify, setNotify] = useState(null)
    const {
      isLoggedIn
   } = useSelector(state => state.auth);
   const { user } = useSelector(state => state.auth);
   if (isLoggedIn) {
      var customerId = user.id;
      if (!customerId) {
         customerId = JSON.parse(user).id;
      }
   } else {
      customerId = null;
   }

   const handleNotify = async (data) => {
       var request = {
           action: 'setNotification',
           customerId: customerId,
           prodId: prodId
       }
       await API_REQUEST('single_product', request).then((response) => setNotify(response));
       setButtonText('We Will Notify You!');
       NotifyUs('We Will Notify You!')
   };

   useEffect(() => {
       let isMounted = true;
       var fetchQty = async () => {
           await API_REQUEST('jems_db_helper', {
               action: 'get_product_qty',
               id: prodId
           }).then((response) => {
               if (isMounted) getProductQty(response)
           });
       }
       fetchQty();
       return () => {
           isMounted = false
       };
   }, [prodId]);

    const handlebuynow = useCallback((data) => {
        HandleBuyNowOnSubmit(data)
    }, [])

        const handlecart = useCallback((data) => {
            HandleCartOnSubmit(data)
            OpenNotification(Name)
        }, [Name])

    var request = {id: prodId, isLoggedIn: isLoggedIn, customer_id: customerId, qty:1, Slug: Slug }

    return (

        <div className="container1" key={key} >
            <div className="card">
                <div className="imgBx">
                <Link to={'/product/'+Slug}>
                    <img  src={Get_image("products",prodId,ProdImg)} alt="hello"/>
                </Link>    
            </div>
                    <div className="contentBx">
                        <Card.Link className="card-prod-name" style={{ fontSize: '20px', color: '#fff' }} href={'/product/'+Slug}>{Name}</Card.Link>
                        <div className="fag-rating">
                            <Rate disabled='false' allowHalf defaultValue={RatingAvg} style={{ color: '#ffa11a' }} /> 
                        </div>
                        {  productQty > 0 ?
                        <div className="fag-new-cart" >
                            <ShoppingCartOutlined id="fag-card-cart" className="cart-mobileview" onClick={data => handlecart(request)} 
                                style={{ color: '#fff', float: 'left', marginLeft: '5px' }} />
                                {
                                    (CategoryID === "0") ?
                                    <Card.Link onClick={data => handlebuynow(request)} href ={"/checkout?src="+prodId} className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' style={{marginLeft: "120px", marginTop: "-20px"}} > Buy Now! </Card.Link>
                                    :
                                    <Card.Link onClick={data => handlebuynow(request)} href ={"/checkout?src="+prodId} className = 'fag-btn-outline buy-now-btn' id='fag-shop-buy-btn' style={{marginLeft: "120px", marginTop: "-20px"}} > Pre Order! </Card.Link>
                                }
                        </div> : <div className="fag-new-cart" >
                                <Card.Link onClick={data => handleNotify(request)}className = 'fag-btn-outline' id='fag-shop-buy-btn' style={{marginLeft: "120px", marginTop: "-20px"}} > Notify! </Card.Link></div>
                        }
                    </div>
                    < p className = "fag-card-price"   style={{fontWeight: "500"}}>AED {(offerprice > 0) ? offerprice : price}</p>
                        {
                            DiscountPerc ? <p className="fag-card-price" style={{color: "#f34f4f",fontStyle: "italic", fontWeight: "500"}}>{DiscountPerc + '% Off'}</p>
                            : <div></div>
                        }
                </div>
        </div>
);
}
export default Cardis;