import React, { useState, useEffect, useCallback } from "react";
import { Image } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from "../../../config/client";
import CONST_URL from "../../../config/url";
import Get_direct_image from "../../../config/get_direct_image";
import axios from "axios";
import { Alert } from "antd";
import { Select, Option } from 'antd';

function UpdateProduct(props) {
  const [productResult, getProductResult] = useState(null);
  const [items, getItem] = useState(null);
  const [list1, getList] = useState(null);
  const [posts, setPosts] = useState();
  const [genre, setGenre] = useState();
  const [skey, setSKey] = useState();
  const [selectFiles, setSelectedFiles] = useState([]);
  const [selectFiles1, setSelectedFiles1] = useState([]);
  const [formData, setFormData] = useState("");
  const [formData1, setFormData1] = useState("");
  const [checked, setChecked] = useState([]);
  const [checked1, setChecked1] = useState([]);
  const [fetchProd, getFetchProd] = useState("");
  const [availstatus, getAvailStatus] = useState(false);
  const [chk, setChk] = useState(false);
  const [image, getImage] = useState([]);
  const [ci, setCi] = useState();
  const [ce, setCe] = useState();
  const [cd, setCd] = useState();
  const [prod, setProd] = useState(null);
  const [prod1, setProd1] = useState(null);
  const [prod2, setProd2] = useState(null);
  const { ProdId } = props;
  var DisplaySms = null;
  var DisplaySingle = null;
  var DisplayMulti = null;
  var DisplayGenre = null;
  var status = null;
  var all1 = [...checked];
  var all2 = [...checked1];

   var item1 = [];
   var item = [];
   var item4 = [];
   var item2 = [];
   var item3 = [];
   const {
     Option
   } = Select;

  const [data, setData] = useState({
    VariableProduct: false,
    Name: "",
    SKU: "",
    Slug: "",
    Price: "",
    OfferPrice: "",
    ShortDescription: "",
    Description: "",
    TabTitle: "",
    TabContent: "",
    MetaTitle: "",
    MetaKeyword: "",
    MetaDescription: "",
    Quantity: "",
    IsActive: 1,
    Category: checked,
    Genre: checked1,
    Image: "",
    ProdID: ProdId,
    FileImage: 0,
    FileGallery: 0,
    ReleaseDate: "",
    VideoLink: "",
    RatingAvg: "",
    HsnNo: "",
    action: "updateproduct",
  });

  useEffect(() => {
    async function fetchData() {
      var request = {
        action: "fetchProd",
        ProdId: ProdId,
      };
      await API_REQUEST("fetch", request).then((response) =>
        getFetchProd(response)
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (fetchProd) {
      setData({
        VariableProduct: fetchProd.smsData.VariableProduct,
        Name: fetchProd.smsData.Name,
        SKU: fetchProd.smsData.SKU,
        Slug: fetchProd.smsData.Slug,
        BARCODE: fetchProd.smsData.BARCODE,
        COMMENT: fetchProd.smsData.COMMENT,
        Price: fetchProd.smsData.Price,
        OfferPrice: fetchProd.smsData.OfferPrice,
        ShortDescription: fetchProd.smsData.ShortDescription,
        Description: fetchProd.smsData.Description,
        TabTitle: fetchProd.smsData.TabTitle,
        TabContent: fetchProd.smsData.TabContent,
        MetaTitle: fetchProd.smsData.MetaTitle,
        MetaKeyword: fetchProd.smsData.MetaKeyword,
        MetaDescription: fetchProd.smsData.MetaDescription,
        Quantity: fetchProd.smsData.Quantity,
        IsActive: fetchProd.smsData.IsActive,
        Category: fetchProd.smsData.Category,
        Genre: fetchProd.smsData.Genre,
        Image: fetchProd.smsData.Image,
        ReleaseDate: fetchProd.smsData.ReleaseDate,
        VideoLink: fetchProd.smsData.VideoLink,
        RatingAvg: fetchProd.smsData.RatingAvg,
        HsnNo: fetchProd.smsData.HsnNo,
        action: "updateproduct",
        ProdId: ProdId,
        FileImage: 0,
        FileGallery: 0,
      });

      getAvailStatus(true);
    }
  }, [fetchProd]);

  console.log(data)

   useEffect(() => {
     if (fetchProd) {
       fetchProd.category_checklist.map(i => {
         item1.push(i.CategoryID);
       });
       setCi(item1);
       setData(data => ({
         ...data,
         Category: item1
       }));
     }
   }, [fetchProd]);

    useEffect(() => {
        if (fetchProd) {
          fetchProd.genre_checklist.map(i => {
            item.push(i.TagID);
          });
          setCe(item);
          setData(data => ({
            ...data,
            Genre: item
          }));
        }
      }, [fetchProd]);

   useEffect(() => {
     if (fetchProd) {
       fetchProd.skey_checklist.map(i => {
         item4.push(i.skey);
       });
       setCd(item4);
       setData(data => ({
         ...data,
         SKey: item4
       }));
     }
   }, [fetchProd]);

  useEffect(() => {
    if (fetchProd) {
      fetchProd.category_checklist.map((i) => {
        all1.push(i.CategoryID);
      });
      setChecked(all1);
      setData((data) => ({ ...data, Category: all1 }));
      formData.set("categories", all1);

      fetchProd.genre_checklist.map((i) => {
        all2.push(i.TagID);
      });
      setChecked(all2);
      setData((data) => ({ ...data, Genre: all2 }));
      formData1.set("genre", all2);
    }
  }, [fetchProd]);

  useEffect(() => {
    var getimageResults = async () => {
      await API_REQUEST("fetch", {
        action: "get_images_path",
        prodID: ProdId,
      }).then((response) => getItem(response));
    };
    getimageResults();
    console.log(items);
  }, [ProdId]);

  var list = [];

  useEffect(() => {
    if (items != null) {
      items.Data.map((i) =>
        list.push({
          original: Get_direct_image("products/" + ProdId, i.path),
          thumbnail: Get_direct_image("products/" + ProdId, i.path),
          single: Get_direct_image("products/" + ProdId, i.Image),
        })
      );

      getList(list);
      getItem(null);
    }
  }, [items]);

  if (list1 != null) {
    DisplaySingle = list1.map((p) => (
      <Image style={{ width: "40px" }} src={p.original} />
    ));
  }

  useEffect(() => {
    setFormData(new FormData());
    async function fetchData() {
      await API_REQUEST("fetch", { action: "getProduct", prodId: ProdId }).then(
        (response) => setPosts(response)
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    setFormData1(new FormData());
    async function fetchGenre() {
      await API_REQUEST("fetch", {
        action: "getgenree",
        prodId: ProdId,
      }).then((response) => setGenre(response));
    }
    fetchGenre();
  }, []);

  const handleToggle = (c) => () => {
    const clickedCategory = checked.indexOf(c);
    //  console.log(clickedCategory)
    const all = [...checked];
    if (clickedCategory === -1) {
      all.push(c);
    } else {
      all.splice(clickedCategory, 1);
    }
    setChecked(all);
    setData({ ...data, Category: all });
    formData.set("categories", all);
  };


   useEffect(() => {
    if (posts != null) {
      posts.data.map(i => (
        item2.push(<Option key={
          i.CategoryID
          } > {
            i.CategoryName
          } </Option>)))

      setProd(item2);
      setPosts(null);
    }
  }, [item2]);

  useEffect(() => {
    if (genre != null) {
      genre.data.map(i => (
        item3.push(<Option key={
          i.ID} > {
            i.Name
          } </Option>)))

      setProd1(item3);
      setGenre(null);
    }
  }, [item]);


  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData(newdata);
  }

  function handleClick(e, editor) {
    setData({ ...data, Description: editor.getData() });
  }

  function handleClicked(e, editor) {
    setData({ ...data, ShortDescription: editor.getData() });
  }

  function handleClicker(e, editor) {
    setData({ ...data, TabContent: editor.getData() });
  }

  function handleChange(value2) {
    setData(data => ({ ...data, Category: value2 }));
  }

    function handleChange2(value1) {
    setData(data => ({ ...data, Genre: value1 }));
  }

  function handleChange3(value3) {
    setData(data => ({ ...data, SKey: value3 }));
  }

  const fileChange = (e) => {
    let files = e.target.files;
    setSelectedFiles(files);
    setData((data) => ({ ...data, FileImage: 1 }));
  };

  const fileChange1 = (e) => {
    let files = e.target.files;
    setSelectedFiles1(files);
    setData((data) => ({ ...data, FileGallery: 1 }));
  };

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    if (selectFiles.length !== 0) {
      file_data.append("file", selectFiles[0]);
    }
    if (selectFiles1.length !== 0) {
      for (let i = 0; i < selectFiles1.length; i++) {
        file_data.append("files[]", selectFiles1[i]);
      }
    }
    file_data.append("request_data", JSON.stringify(data));
    async function processProductForm() {
      console.log(file_data);
      // var BASE_URL = "http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/"; 
      var BASE_URL = "http://localhost/admin_mcube/api/Database/";
      var fetchGenre = axios
        .post(CONST_URL + "catalogue.php", file_data)
        .then((response) => getProductResult(response.data));
    }
    processProductForm();
  }

  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setData({ ...data, IsActive: "1" });
    } else {
      setData({ ...data, IsActive: "0" });
    }
  };

  const selectShortlistedApplicant1 = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setData({ ...data, VariableProduct: "1" });
    } else {
      setData({ ...data, VariableProduct: "0" });
    }
  };

  function handle2(e) {
    setData(data => ({ ...data, HsnNo: e }));
  }


  return (
    availstatus && (
      <div className="container_admin form admin_body">
        <Form onSubmit={(e) => submit(e)}>
          <div className="container_admin row">
            <div className="column leftt">
              
              <div className="col-sm-5">
                
                <label for="scales">VariableProduct</label>
                {data.VariableProduct === "1" ? (
                  <input
                    type="checkbox"
                    className="checkbox team_values"
                    checked
                    onClick={(e) => {
                      selectShortlistedApplicant1(e);
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="checkbox team_values"
                    onClick={(e) => {
                      selectShortlistedApplicant1(e);
                    }}
                  />
                )}
              </div>

              <br></br>

              <div className="col-sm-5">
                <label htmlFor="Name">Product Name</label>
                <input
                  onChange={(e) => handle(e)}
                  id="Name"
                  value={data.Name}
                  name="Name"
                  className="form-control"
                  type="text"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
                <label>Description</label>
                <CKEditor
                  id="Description"
                  editor={ClassicEditor}
                  name="Description"
                  onChange={(e, editor) => {
                    handleClick(e, editor);
                  }}
                  onReady={(editor) => {
                    editor.setData(data.Description);
                  }}
                />
                <br />
                <br />
              </div>

              <div className="col-sm-5">
                <label htmlFor="SKU">Product SKU *</label>
                <input
                required
                  onChange={(e) => handle(e)}
                  id="SKU"
                  value={data.SKU}
                  name="SKU"
                  className="form-control"
                  type="text"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
                <label htmlFor="Slug">Product Slug </label>
                <input
                
                  onChange={(e) => handle(e)}
                  id="Slug"
                  value={data.Slug}
                  name="Slug"
                  className="form-control"
                  type="text"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
                <label htmlFor="BARCODE">Product Barcode</label>
                <input
                  onChange={(e) => handle(e)}
                  id="BARCODE"
                  value={data.BARCODE}
                  name="BARCODE"
                  className="form-control"
                  type="text"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
              <label>Hsn No</label>
              <br></br>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onChange={handle2}
                value={data.HsnNo}
                defaultValue={data.HsnNo}
                name="HsnNo"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="85238090">85238090</Option>
                <Option value="95049090">95049090</Option>
                <Option value="95045000">95045000</Option>
                <Option value="998439">998439</Option> 
                <Option value="997331">997331</Option>
              </Select>
            </div>
            <br></br>

              <div className="col-sm-5">
                <label htmlFor="COMMENT">Comment</label>
                <input
                  onChange={(e) => handle(e)}
                  id="COMMENT"
                  value={data.COMMENT}
                  name="COMMENT"
                  className="form-control"
                  type="text"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
              <label htmlFor="HSN">HSN No</label>
              <input
                onChange={(e) => handle(e)}
                id="HSN"
                value={data.COMMENT}
                name="HSN"
                className="form-control"
                type="text"
              />
            </div>
            <br></br>

              <div className="col-sm-5">
                <label htmlFor="Price">Price *</label>
                <input
                required
                  onChange={(e) => handle(e)}
                  id="Price"
                  value={data.Price}
                  name="Price"
                  className="form-control"
                  type="number"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
                <label htmlFor="OfferPrice">Offer Price</label>
                <input
                  onChange={(e) => handle(e)}
                  id="OfferPrice"
                  value={data.OfferPrice}
                  defaultValue={0}
                  name="OfferPrice"
                  className="form-control"
                  type="number"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
                <label htmlFor="Quantity">Stock Quantity *</label>
                <input
                required
                  onChange={(e) => handle(e)}
                  id="Quantity"
                  value={data.Quantity}
                  name="Quantity"
                  className="form-control"
                  type="number"
                />
              </div>
              <br></br>

              <div className="col-sm-5">
                <label>ShortDescription</label>
                <CKEditor
                  id="ShortDescription"
                  name="ShortDescription"
                  editor={ClassicEditor}
                  onChange={(e, editor) => {
                    handleClicked(e, editor);
                  }}
                  onReady={(editor) => {
                    editor.setData(data.ShortDescription);
                  }}
                />
              </div>

              <br></br>

              <div className="col-sm-5">
                <label>Release Date </label>
                <input
                  onChange={(e) => handle(e)}
                  id="ReleaseDate"
                  className="form-control"
                  type="date"
                  name="ReleaseDate"
                  value={data.ReleaseDate}
                  style={{width:"200px"}}
                />
                <br></br>
              </div>

              <div className="col-sm-5">
                <label>Video Link </label>
                <input
                  onChange={(e) => handle(e)}
                  id="VideoLink"
                  className="form-control"
                  name="VideoLink"
                  value={data.VideoLink}
                />
                <br></br>
              </div>

              <div className="col-sm-5">
              <label htmlFor="RatingAvg"> Rating Average</label>
              <input
                onChange={(e) => handle(e)}
                id="RatingAvg"
                value={data.RatingAvg}
                name="RatingAvg"
                className="form-control"
                type="number"
                min="1" max="5"
              />
            </div>
            
              <br></br>
              {/* <br></br>
              <br></br>

              {/* <div className="col-sm-5">
                <label htmlFor="TabTitle">Custom Tab Title</label>
                <input
                  onChange={(e) => handle(e)}
                  id="TabTitle"
                  value={data.TabTitle}
                  name="TabTitle"
                  className="form-control"
                  type="text"
                />
              </div> */}

              {/* <div className="col-sm-5">
                <label>Custom Tab Content</label>
                <CKEditor
                  id="TabContent"
                  name="TabContent"
                  editor={ClassicEditor}
                  onChange={(e, editor) => {
                    handleClicker(e, editor);
                  }}
                  onReady={(editor) => {
                    editor.setData(data.TabContent);
                  }}
                />
              </div>
              <br></br> */}
           

              <h4>SEO SECTION</h4>

              <br></br>

              <div className="col-sm-5">
                <label>Meta Title</label>
                <input
                  onChange={(e) => handle(e)}
                  id="MetaTitle"
                  value={data.MetaTitle}
                  type="text"
                  className="form-control"
                  name="MetaTitle"
                />
                <br></br>
              </div>

              <div className="col-sm-5">
                <label>Meta Keyword</label>
                <input
                  onChange={(e) => handle(e)}
                  id="MetaKeyword"
                  value={data.MetaKeyword}
                  type="text"
                  className="form-control"
                  name="MetaKeyword"
                />
                <br></br>
              </div>

              <div className="col-sm-5">
                <label>Meta Description </label>
                <textarea
                  onChange={(e) => handle(e)}
                  id="MetaDescription"
                  className="form-control"
                  rows="3"
                  name="MetaDescription"
                  value={data.MetaDescription}
                />
                <br></br>
              </div>
            </div>

            {/* LEFT COLUM ENDS HERE */}

            <div class="column rightt">
              <div className="col-sm-5">
                <label>Product Category</label>
              </div>

        
              <Select mode="tags" style={{ width: '100%' }} placeholder="Product Cateogies"  defaultValue={ci} onChange={handleChange}>
                    {prod}
              </Select>

              <br></br>
              <br></br>

              <div className="col-sm-5">
                <label>Product Genre</label>
              </div>

              <Select mode="tags" style={{ width: '100%' }} placeholder="Product Genres"  defaultValue={ce} onChange={handleChange2 }>
                    {prod1}
              </Select>

              <br></br>
              <br></br>

              <div className="col-sm-5">
                <label>Search Keywords</label>
              </div>

              <Select mode="tags" style={{ width: '100%' }} placeholder="Product Search Keys"  defaultValue={cd} onChange={handleChange3}>
                    <Option>{cd}</Option>
              </Select>

              <br></br>
              <br></br>

              <div className="col-sm-5">
                <label>Product Image</label>
                <br></br>
                <input
                
                  type="file"
                  name="file"
                  id="image_upload"
                  onChange={fileChange}
                  accept="image/png,  image/jpeg, image/.webp"
                />
                {list1 ? (
                  <Image style={{ width: "40px" }} src={list1[0].single} />
                ) : (
                  <p></p>
                )}
                <br></br> <br></br>
              </div>

              <div className="col-sm-5">
                <label>Product Gallery</label>
                <br></br>
                <input
                
                  multiple
                  type="file"
                  name="files"
                  id="image_upload"
                  accept="image/png,  image/jpeg, image/.webp"
                  onChange={fileChange1}
                />
                {DisplaySingle ? DisplaySingle : <p></p>}
                <br></br> <br></br>
              </div>


              <div className="col-sm-5">
                <label for="scales">IsActive</label>
                {data.IsActive === 1 ? (
                  <input
                    type="checkbox"
                    className="checkbox team_values"
                    checked
                    onClick={(e) => {
                      selectShortlistedApplicant(e);
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="checkbox team_values"
                    onClick={(e) => {
                      selectShortlistedApplicant(e);
                    }}
                  />
                )}
              </div>
              <br></br>

              <button
                type="submit"
                className="btn btn-primary button "
                style={{
                  marginRight: 1 + "em",
                  border: "none",
                  backgroundColor: "#4CAF50",
                  color: "white",
                }}
              >
                Update
              </button>
              <button
                className="btn btn-primary button "
                type="reset"
                style={{
                  marginRight: 1 + "em",
                  border: "none",
                  backgroundColor: "#FF0000",
                  color: "white",
                }}
              >
                <i className="fas fa-arrow-left"></i> Cancel
              </button>
              {productResult ? (
                <Alert message={productResult.message} type="success" />
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </Form>
      </div>
    )
  );
}

export default UpdateProduct;
