import React from 'react';
import UniversalNav from '../components/header/navBar_component';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Thankyou() {
    return (
        <>
            <UniversalNav />
            {/* <Banner header={'Checkout Page'}/> */}
            <section className="checkout-page-area section_100">
                <Container>
                    <h2 data-shadow='THANK YOU FOR SHOPPING WITH US ! :)' className="thankyou"> THANK YOU FOR SHOPPING WITH US ! :) </h2>
                    <button className="btn-shop mobbutton"><Link to={"/shop"}>SHOP</Link>  </button>
                    <button className="btn-home"><Link to={"/"}>HOME</Link> </button>
                    <button className="btn-order"><Link to={"/myorders"}>MY ORDER </Link></button>
                </Container>
            </section>
        </>
    )
}

export default Thankyou;
