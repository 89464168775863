import { React } from 'react';
import { Button } from 'react-bootstrap';
import {Link} from "react-router-dom";

function Error () {
return(
<div class="page-404 section--full-bg">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="page-404__wrap">
                     <div class="page-404__content">
                        <h1 class="page-404__title">404</h1>
                        <p class="page-404__text">The page you are looking for not available!</p>
                        <Button className="fag-btn" as={Link} to="/">back to home</Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
);
}

export default Error;