import React,{ useState , useEffect } from 'react';

import {Row , Col , Card , Image} from 'react-bootstrap';

import API_REQUEST from '../../../config/client';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Get_direct_image from '../../../config/get_direct_image';

function ExclusiveDeals() {
    // SLEEPER FUNCTION ------------------------------------------------------------------------------
    function sleeper(ms) {
        return function(x) {
          return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }
    // VARIABLES ------------------------------------------------------------------------------
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [excDeal,getExclusiveDeals] = useState(null);
    var Displaypdata = null;

    // API REQUEST ------------------------------------------------------------------------------
    useEffect(() => {
        var fetchexclusiveDeals = async () => {
        await API_REQUEST('exclusiveDeals', {action:'getexcDeals'}).then(sleeper(1)).then((response) => getExclusiveDeals(response));
        }
        fetchexclusiveDeals();
    }, []);
    //console.log(excDeal);

    // IF STATEMENT ------------------------------------------------------------------------------
    if(excDeal != null){
        
        Displaypdata =  excDeal.Data.map(item => (

            <div className="exc-deals-image"  key={item.id}>
                <Card.Link href={item.link}>
                    {/* <Image src={item.image} alt="exc-image" /> */}
                   <Image src={Get_direct_image("deals" ,item.image)} />
                </Card.Link>
                <h4 className="exc-deals-name">{item.name}</h4>
            </div>

        ));
    }

    // RETURN STATEMENT ------------------------------------------------------------------------------
    return (
        <>
            <div id="gradient-top"></div>
            <section className="exclusive-deals-area">
                    <Col lg={12} className="excdeals-col-12">
                        <div className="site-heading site-head-mobile">
                            <h3 className="heading_animation">Exclusive <span>Deals</span></h3>
                        </div>
                    </Col>
                    <Col lg={12} className="col-12">
                        <div className="exc-deals-items">
                            <Row xs={2} md={4} lg={4} className="g-6-up">
                                {
                                    Displaypdata  ? Displaypdata : <Spin indicator={antIcon} />
                                }
                            </Row>
                        </div>
                    </Col>
            </section>
            <div id="gradient-bottom"></div>
        </>
    )
}

export default ExclusiveDeals;