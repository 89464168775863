import React, { useState } from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";


function Categoryform() {

    const [categoryResult, getCategoryResult] = useState(null)
    const [data, setData] = useState({
        CategoryName: "",
        Slug: "",
        Description: "",
        ShippingCharges: 0,
        MetaTitle: "",
        MetaKeyword: "",
        MetaDescription: "",
        FileImage: 0,
        IsActive: true,
        action: "process_category"
    });


    const [selectFiles, setSelectedFiles] = useState([])

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)
        setData((data) => ({ ...data, FileImage: 1 }));
    }


    function handleClick(e, editor) {
        setData({ ...data, Description: editor.getData() })
    }


    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        if (selectFiles.length !== 0) {
            file_data.append("file", selectFiles[0]);
        }
        file_data.append('request_data', JSON.stringify(data))
        async function processCatForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            // var BASE_URL = 'http://localhost/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getCategoryResult(response.data))     

        }
        processCatForm()
    }

    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setData({ ...data, IsActive: '1' })
        } else {
            setData({ ...data, IsActive: '0' })
        }
    };

    return (

        <div className="container_admin admin_body form">
            <form onSubmit={(e) => submit(e)} >
                <br></br>


                <div className="col-sm-5">
                    <label htmlFor="CategoryName">Category Name</label>
                    <input onChange={(e) => handle(e)} id="CategoryName" value={data.CategoryName} name="CategoryName" className="form-control" type="text" required />
                    <br></br>

                </div>

                <div className="col-sm-5">
                    <label htmlFor="Slug">Slug</label>
                    <input onChange={(e) => handle(e)} id="Slug" value={data.Slug} name="Slug" className="form-control" type="text" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description" name="Description" editor={ClassicEditor} onChange={(e, editor) => { handleClick(e, editor) }} />
                    <br /><br />
                </div>


                <div className="col-sm-5">
                    <label>Category Image</label>
                    <br></br> <br></br>
                    <input type="file" name="file" id="image_upload" accept="image/png,  image/jpeg, image/.webp" onChange={fileChange} />
                    <br></br> <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Shipping Charges</label>
                    <br></br>
                    <input onChange={(e) => handle(e)} id="ShippingCharges" value={data.ShippingCharges} type="number" className="form-control" name="ShippingCharges" />
                    <br></br>
                </div>


                <div className="col-sm-5">
                    <label>Meta Title</label>
                    <input onChange={(e) => handle(e)} id="MetaTitle" value={data.MetaTitle} type="text" className="form-control" name="MetaTitle" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Meta Keyword</label>
                    <input onChange={(e) => handle(e)} id="MetaKeyword" value={data.MetaKeyword} type="text" className="form-control" name="MetaKeyword" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Meta Description </label>
                    <textarea onChange={(e) => handle(e)} id="MetaDescription" className="form-control" rows="3" name="MetaDescription" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label for="scales">IsActive</label>
                    {/* {
                        data.IsActive === '1' ?
                            <input
                                checked
                                type="checkbox"
                                className="checkbox team_values"
                                checked
                                onClick={(e) => {
                                    selectShortlistedApplicant(e);
                                }
                                } /> :
                            <input
                                checked
                                type="checkbox"
                                className="checkbox team_values"
                                onClick={(e) => {
                                    selectShortlistedApplicant(e);
                                }} />
                    } */}

                    <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />
                </div>
                <br></br>


                <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }}> Submit </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    categoryResult ? <Alert message={categoryResult.message} type="success" /> : <p></p>
                }
            </form>
        </div>
    )

}
export default Categoryform