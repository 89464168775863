import {React} from 'react';
// import Banner from '../components/header/banner';
// import {MDBIcon } from "mdbreact";
// import '../assets/css/style.css';
// import '../assets/css/responsive.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import UniversalNav from "../components/header/navBar_component";
import { MDBFooter } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import {
  CaretRightOutlined,
  EnvironmentOutlined,
  EditOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import logo from "../assets/img/mg-1.png";
import { Link } from "react-router-dom";
import { BackTop } from "antd";
import SocialIcons from "../components/utils/social/social_icon";
import { Helmet } from 'react-helmet';

import Jwt_Decode from 'jwt-decode';



if (localStorage.getItem("tok")) {
  const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("tok"));
  if (jwt_Token_decoded.data.exp * 1000 < Date.now()) {
    localStorage.clear();
    window.location.href = "/login";
  }
}


function About(){
return(
<>
  <Helmet>
            <title>Buy PS5, PS4, Xbox, PC, Nintendo games and accessories online at Best Price in UAE</title>‍
            <meta name="description" conttent="Shop the top, latest, upcoming, best ps4, ps5, xbox, pc and nintendo games online and get best deals and offers on video games and accessories only on Mcube Games."/>
            <meta name="keywords" content="Shopping, Gaming Store, Playstation Games, Top Games Online, Xbox Games, PS5 Games Purchase, PS4 Games Online, Nintendo Switch, PC Games, Gaming Accessories, Action Figures, Bestselling Games, Mcube, Mcubegames, Mcube Games Online"/>
    </Helmet>
 <UniversalNav />
      {/* <Banner header={"About Us"} /> */}
      <section className="fag-about-area section_200  col-12 col-sm-12 col-md-12 .col-lg-12 .col-xl-12  mt-70">
        <div className="container col-12 col-sm-7 col-md-12 .col-lg-12 .col-xl-12 ">
          <div className="row1 col-12 col-sm-7 col-md-7 .col-lg-12 .col-xl-12">
            <div className="about-top  ">
              <p className="left col-md-12 .col-lg-7 .col-xl-7">
              <p className="about_us col-md-12 .col-lg-7 .col-xl-7">ABOUT US</p>
               <h2 className="left_1 col-md-12 .col-lg-7 .col-xl-7">Get to know us better</h2>  
                Like all things in life, gaming too continuously keeps on
                evolving. With this we bring you one of the biggest revolution
                the UAE gaming space has ever seen, Cube Game, a one-stop
                shop for the best PS4 games, XBOX, Nintendo Switch and PC Games.
                Get ready for all new updates about games to be brought to you
                first through us. Your constant search for something as trivial
                as socks for your controllers to the release date and
                availability of the next Mario ends here.
               <br></br><br></br>
                What sets us apart from the rest of the internet’s conjecture on gaming? We actually
                know it! 
                <br></br><br></br>
                 <a href="#">MCUBE GAMES</a> is a leading name in gaming industry in the
                UAE and Middle East with great after sales service. 
                <br></br>
                 We work to make your life more enjoyable. 
                 <br></br>
                Live in your world. Play in ours. 
               <br></br>
                We sell only top quality products.
              </p>
            </div>
          </div>
        </div>         
         {/* <div class="about-top">
         <h2><span>Interesting</span>Facts</h2>
         <div className="container">
            <div className="row">
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     <MDBIcon icon="map" />
                     
                     </div>
                     <div className="contact-head">
                        <h4>Location</h4>
                     </div>
                     <div className="contact-text">
                        <p>Deira, Dubai - UAE</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     <MDBIcon icon="phone" />
                     </div>
                     <div className="contact-head">
                        <h4>Phones</h4>
                     </div>
                     <div className="contact-text">
                        <p>Office:0564273652</p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <div className="single-contact-box">
                     <div className="contact-icon">
                     <MDBIcon icon="envelope" />
                     </div>
                     <div className="contact-head">
                        <h4>Email</h4>
                     </div>
                     <div className="contact-text">
                        <p>letsplay@mcubegames.ae</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div> */}
      </section>

 <MDBFooter className="fag-footer">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={4} md={6} xs={12}>
              <div className="single-footer">
                <h3>About us</h3>
                <p>
                  Mcube Games offers a wide variety of gaming products,
                  including consoles, accessories, headphones, bundles, and the
                  latest releases.In addition, we offer the most competitive
                  prices. We are present at all of UAE's most popular
                  e-commerce sites, including Amazon, Flipkart, Snapdeal, among
                  others.
                </p>
              </div>
            </Col>
            <Col lg={5} md={6} xs={12}>
              <div></div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="single-footer">
                <h3>Contact Us</h3>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <EnvironmentOutlined
                      style={{ color: "#ff7a21"}}
                    /> Address{" "}
                  </h4>
                  <p>
                    Deira, Dubai - UAE
                  </p>
                </div>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <EditOutlined style={{ color: "#ff7a21" }} /> Email Address
                  </h4>
                  <p>letsplay@mcubegames.ae</p>
                </div>
                <div className="footer-contact">
                  <h4 className="title">
                    {" "}
                    <PhoneOutlined style={{ color: "#ff7a21" }} /> Phone{" "}
                  </h4>
                  <p>0564273652</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="footer-bottom-inn">
                <div className="footer-logo">
                  <img
                    alt=""
                    src={logo}
                    width="240px"
                    height="160px"
                    className="navbar-brand logo"
                  />{" "}
                </div>
                <SocialIcons />
                <div className="copyright">
                  <p>&copy; Copyrights 2020 McubeGames - All Rights Reserved</p>
                </div>
              </div>
            </Col>
          </Row>

          <BackTop>
            <div className="mybtn"><i class="fa fa-chevron-up" aria-hidden="true"></i></div>
          </BackTop>

        </Container>
      </div>
    </MDBFooter>
      
</>
);

}
export default About;