import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from "../../../config/client";
import axios from "axios";
import { Alert } from "antd";
import CONST_URL from "../../../config/url";

function UpdateBanner(props) {
    const [bannerResult, getBannerResult] = useState(null);
    const [fetchBanner, getFetchBanner] = useState("");
    const [selectFiles, setSelectedFiles] = useState([]);
    const { BanId } = props;

    const [data, setData] = useState({
        Title: "",
        Link: "",
        FileImage: 0,
        IsActive: true,
        action: "process_banner",
    });

    useEffect(() => {
        async function fetchData() {
            var request = {
                action: "fetchBanner",
                BanId: BanId,
            };
            await API_REQUEST("fetch", request).then((response) =>
                getFetchBanner(response)
            );
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (fetchBanner) {
            console.log(fetchBanner);
            setData({
                Title: fetchBanner.Title,
                Link: fetchBanner.Link,
                IsActive: fetchBanner.IsActive,
                FileImage: 0,
                action: "updatebanner",
                BanId: BanId,
            });
        }
    }, [fetchBanner]);

    function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setData(newdata);
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)
        setData((data) => ({ ...data, FileImage: 1 }));
    }

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        if (selectFiles.length !== 0) {
            file_data.append("file", selectFiles[0]);
        }
        file_data.append("request_data", JSON.stringify(data));
        async function processBannerForm() {
            console.log(file_data);
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            // var BASE_URL = "http://localhost/admin_mcube/api/Database/";
            var fetchData = axios
                .post(CONST_URL + "others.php", file_data)
                .then((response) => getBannerResult(response.data));
        }
        processBannerForm();
    }

    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setData({ ...data, IsActive: "1" });
        } else {
            setData({ ...data, IsActive: "0" });
        }
    };

    return (
        <div className="container_admin admin_body form">
            <br></br>
            <form onSubmit={(e) => submit(e)}>
                <div className="col-sm-5">
                    <label>Banner Title*</label>
                    <input
                        onChange={(e) => handle(e)}
                        id="Title"
                        value={data.Title}
                        name="banner"
                        className=" form-control"
                        type="text"
                        placeholder="Silver Case 2425"
                        required
                    />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Banner Image*</label>
                    <br></br>
                    <input
                     accept="image/png,  image/jpeg, image/.webp"
                        type="file"
                        name="file"
                        id="image_upload"
                        onChange={fileChange}
                    />
                    <br></br>
                </div>

                <br></br>
                <div className="col-sm-5">
                    <label>Banner Link</label>
                    <input
                        onChange={(e) => handle(e)}
                        id="Link"
                        value={data.Link}
                        name="Link"
                        className="form-control"
                        type="text"
                        required
                    />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label for="scales">IsActive</label>
                    {data.IsActive === "1" ? (
                        <input
                            type="checkbox"
                            className="checkbox team_values"
                            checked
                            onClick={(e) => {
                                selectShortlistedApplicant(e);
                            }}
                        />
                    ) : (
                        <input
                            type="checkbox"
                            className="checkbox team_values"
                            onClick={(e) => {
                                selectShortlistedApplicant(e);
                            }}
                        />
                    )}
                </div>

                <br></br>

                <button
                    className="btn btn-primary button "
                    type="submit"
                    style={{
                        marginRight: 1 + "em",
                        border: "none",
                        backgroundColor: "#4CAF50",
                        color: "white",
                    }}
                >
                    {" "}
                    Update{" "}
                </button>
                <button
                    className="btn btn-primary button "
                    type="reset"
                    style={{
                        marginRight: 1 + "em",
                        border: "none",
                        backgroundColor: "#FF0000",
                        color: "white",
                    }}
                >
                    {" "}
                    <i className="fas fa-arrow-left"></i> Cancel{" "}
                </button>
                {bannerResult ? (
                    <Alert message={bannerResult.message} type="success" />
                ) : (
                    <p></p>
                )}
            </form>
        </div>
    );
}

export default UpdateBanner;
