import React, { useState, useEffect, useCallback } from "react";
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import CONST_URL from "../../../config/url";

function UpdateProfile(props) {

    const [profileResult, getProfileResult] = useState(null)
    const [fetchProfile, getFetchProfile] = useState("");
    const [selectFiles, setSelectedFiles] = useState();
    const { profileId } = props;


    const [data, setData] = useState({
        FirstName: "",
        LastName: "",
        UserName: "",
        EmailId: "",
        MobileNo: "",
        action: "process_myprofile"
    });

    useEffect(() => {
        async function fetchData() {
            var request = {
                action: 'fetchProfile',
                profileId: profileId
            }
            await API_REQUEST('fetch', request).then((response) => getFetchProfile(response));
        }
        fetchData();

    }, []);


    useEffect(() => {
        if (fetchProfile) {
            console.log(fetchProfile)
            setData({
                FirstName: fetchProfile.FirstName,
                LastName: fetchProfile.LastName,
                UserName: fetchProfile.UserName,
                EmailId: fetchProfile.EmailId,
                MobileNo: fetchProfile.MobileNo,
                ImageName: fetchProfile.ImageName,
                action:"updatemyprofile",
                profileId: profileId
            })


        }
    }, [fetchProfile]);

    console.log(data)



    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata);
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)

    }

    // function submit(e) {
    //     e.preventDefault();
    //     async function processProfileForm() {
    //         var request = { action: 'process_myprofile', form_data: data }
    //         await API_REQUEST('myaccount', request).then((response) => getProfileResult(response))
    //     }
    //     processProfileForm()
    // }

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles[0])
        file_data.append('request_data', JSON.stringify(data))
        async function processProfileForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'myaccount.php', file_data).then((response) => getProfileResult(response.data))

        }
        processProfileForm()
    }


    return (
        <div className="container form">
            <form onSubmit={(e) => submit(e)}>

                <br></br>

                <div className="col-sm-5">
                    <label>First Name *</label>
                    <input onChange={(e) => handle(e)} id="FirstName" value={data.FirstName} name="FirstName" className=" form-control" type="text" placeholder="Admin" required />
                </div>
                <br></br>


                <div className="col-sm-5">
                    <label>Last Name *</label>
                    <input onChange={(e) => handle(e)} id="LastName" value={data.LastName} name="LastName" className=" form-control" type="text" placeholder="admin" required />
                </div>

                <br></br>


                <div className="col-sm-5">
                    <label>User Name </label>
                    <input onChange={(e) => handle(e)} id="UserName" value={data.UserName} name="UserName" className=" form-control" type="text" placeholder="admin" />
                </div>

                <br></br>


                <div className=" col-sm-1">
                    <label>Email Address* :</label>
                    <input onChange={(e) => handle(e)} id="EmailId" value={data.EmailId} name="EmailId" className=" form-control" type="email" placeholder="letsplay@mcubegames.ae" required />
                </div>
                <br></br>



                <div className=" col-sm-1">
                    <label>Mobile Number* :</label>
                    <input onChange={(e) => handle(e)} id="MobileNo" value={data.MobileNo} name="MobileNo" className=" form-control" type="number" placeholder="9898989898" required />
                </div>
                <br></br>


                <div className=" col-sm-1">
                    <label>Profile Picture</label>
                    <br></br> <br></br>
                    <input type="file" name="file" id="image_upload" onChange={fileChange} />
                </div>
                <br></br>

                <button type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Update </button>

            </form>
        </div>
    )
}


export default UpdateProfile
