import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import avatar from "../../assets/img/mg-1.png";
import API_REQUEST from '../../config/client';

function Forgot() {

    const [email, setEmail] = useState("");
    const [forgot, setForget] = useState("");
    const [successful, setSuccessful] = useState(false);

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const handleForgot = async (e) => {
    e.preventDefault();
    
    var request = {action:'forgot', 'email':email}
    await API_REQUEST('forgot', request).then((response) => setForget(response));
     setSuccessful(true);
    }
    
        return (
        <div class="page-404 section--full-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-404__wrap">
                   <div className="login-wrapper">
                       <div  className="form-row">
                         <img className="login_user" src={avatar} alt="login user" />
                       </div>
                        
                        <Form  onSubmit={handleForgot}>
                            <Form.Group controlId="formBasicEmail">
                                <div  className="form-row">
                                    <Form.Control className='inpText' type="name" name="email" value={email} onChange={onChangeEmail}  placeholder="Enter your Registered Email" />
                                </div>  
                            </Form.Group>
                            
                            <div  className="form-row">
                                <Button className="fag-btn"  type="submit">
                                    Get Password!
                                </Button>
                            </div>
                        </Form>

                        {forgot.message && (
                          <div className="form-group">
                            <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                              {forgot.message}
                            </div>
                          </div>
                        )}

                    </div>
                  </div>
               </div>
            </div>
         </div>
        </div>    
        );
    
}

export default Forgot;