import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Included from "./included";
import API_REQUEST from "../../../config/client";
import axios from "axios";
import Example from "./exp";
import 'antd/dist/antd.css';
import { Select, Option } from 'antd';
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";


function Discount_form() {
  const [discountResult, getDiscountResult] = useState(null);
  const [selectFiles, setSelectedFiles] = useState([]);
  const [selectFiles1, setSelectedFiles1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState("");
  const [checked, setChecked] = useState([]);
  const [posts, setPosts] = useState();

  const [options, setOptions] = useState(null);
  const [options1, setOptions1] = useState(null);
  const [options2, setOptions2] = useState(null);
  const [product, getProduct] = useState(null);
  const [excproduct, getExcProduct] = useState(null);
  const [include, setInclude] = useState(null);
  const [exclude, setExclude] = useState(null);
  const [prodcat, setProdCat] = useState(null);
  const [prod, setProd] = useState(null);


  var items = [];
  var item = [];
  const { Option } = Select;


  // // // INCLUDED PRODUCTS

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getProdDetails',
      }

      await API_REQUEST('fetch', request).then((response) => getProduct(response));
    }
    fetchAddress();
  }, []);

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getExcludedProd',
      }

      await API_REQUEST('fetch', request).then((response) => getExcProduct(response));
    }
    fetchAddress();
  }, []);


  useEffect(() => {
    if (excproduct != null) {
      excproduct.data.map(i => (
        items.push(<Option key={i.ProductID}>{i.Name}</Option>)
      ))
      console.log("ibrahim");
      setInclude(items);
      setExclude(items);
      setLoading(false);
      getExcProduct(null);
    }
  }, [items]);

  const handleOnchange1 = val1 => {
    setOptions1(val1)
  }

  console.log(excproduct);



  // // // // // // // // // // // // // // // /// PRODUCT CATEGORY

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getProdCategory',
      }

      await API_REQUEST('fetch', request).then((response) => setProdCat(response));
    }
    fetchAddress();
  }, []);


  useEffect(() => {
    if (prodcat != null) {
      prodcat.data.map(i => (
        items.push(<Option key={i.CategoryID}>{i.CategoryName}</Option>)
      ))
      console.log("ibrahim");

      setProd(items);
      setLoading(false);
      setProdCat(null);
    }
  }, [items]);

  const handleOnchange2 = val2 => {
    setOptions2(val2)
  }
  console.log(prodcat);


  function handleChange(event) {
    getProduct(event.target.value);
  }



  const [data, setData] = useState({
    VoucherCode: "",
    Title: "",
    Discount: "",
    AmountType: "",
    // DiscountType: "",
    MinPrice: "",
    MaxPrice: "",
    StartDate: "",
    EndDate: "",
    max_use: "",
    product_categories: [],
    include_product_ids: [],
    exclude_product_ids: [],
    exclude_category_ids: [],
    discount_applied_type: "",
    Description: "",
    KnowMore: "",
    IsActive: true,
    IncludedProducts: checked,
    FileImage: 0,
    action: "process_discount",
  });
  var DisplaySms = null;
  //  const { Option } = Select;

  useEffect(() => {
    setFormData(new FormData());
    async function fetchData() {
      await API_REQUEST("fetch", { action: "getDiscount" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, []);

  function handleChange(value) {
    setData(data => ({ ...data, include_product_ids: value }));
  }

  function handleChange1(value1) {
    setData(data => ({ ...data, exclude_product_ids: value1 }));
  }

  function handleChange2(value2) {
    setData(data => ({ ...data, product_categories: value2 }));
  }

  function handleChange3(value3) {
    setData(data => ({ ...data, exclude_category_ids: value3 }));
  }

  const handleToggle = (c) => () => {
    const clickedCategory = checked.indexOf(c);
    const all = [...checked];
    if (clickedCategory === -1) {
      all.push(c);
    } else {
      all.splice(clickedCategory, 1);
    }
    setChecked(all);
    setData({ ...data, IncludedProducts: all });
    formData.set("discount", all);
  };

  function handleClick(e, editor) {
    setData({ ...data, Description: editor.getData() });
    console.log(data);
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
  }

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();

    if (selectFiles.length !== 0) {
      file_data.append("file", selectFiles[0]);

    }
    if (selectFiles1.length !== 0) {
      for (let i = 0; i < selectFiles1.length; i++) {
        file_data.append("files[]", selectFiles1[i]);
      }

    }
    file_data.append("request_data", JSON.stringify(data));
    async function processDiscountForm() {
      console.log(file_data);
      var BASE_URL = "http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/";
      var fetchData = axios.post(CONST_URL + "others.php", file_data).then((response) => getDiscountResult(response.data));
    }
    processDiscountForm();
  }

  function handle2(e) {
    setData(data => ({ ...data, AmountType: e }));
  }

  function handle3(e) {
    setData(data => ({ ...data, discount_applied_type: e }));
  }

  if (loading) {
    return (
      <div><p>Loading...</p></div>
    )
  }

  const fileChange = (e) => {
    let files = e.target.files;
    setSelectedFiles(files);
    setData((data) => ({ ...data, FileImage: 1 }));
  };


  return (
    <div className="container_admin admin_body form">
      <form onSubmit={(e) => submit(e)}>
        <br></br>
        <div className="col-sm-5">
          <label>Voucher Code* :</label>
          <input
            onChange={(e) => handle(e)}
            id="VoucherCode"
            value={data.VoucherCode}
            name="VoucherCode"
            className=" form-control"
            type="text"
            placeholder="MCUBE1"
            required
          />
          <br></br>
        </div>
        <div className="col-sm-5">
          <label>Title* :</label>
          <input
            onChange={(e) => handle(e)}
            id="Title"
            value={data.Title}
            name="Title"
            className=" form-control"
            type="text"
            placeholder="mcube"
            required
          />
          <br></br>
        </div>
       
        <div className="col-sm-5">
        <label>Select Percentage / Amount</label>
        <br></br>
        <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onChange={handle2}
                value={data.AmountType}
                defaultValue={data.AmountType}
                name="AmountType"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="1">1 -  Percentage</Option>
                <Option value="2">2 -  Amount</Option>
              </Select>
              </div>
              <br></br>

        <div className="col-sm-5">
          <label>Discount* :</label>
          <input
            onChange={(e) => handle(e)}
            id="Discount"
            value={data.Discount}
            name="Discount"
            className=" form-control"
            type="number"
            placeholder="19"
            min="1"
            max="1000"
            required
          />
          <br></br>
        </div>


        <div className="col-sm-5">
          <label>Minimun Cart Value* :</label>
          <input
            onChange={(e) => handle(e)}
            id="MinPrice"
            value={data.MinPrice}
            name="MinPrice"
            className=" form-control"
            type="number"
            placeholder="300"
            required
          />
          <br></br>
        </div>

        <div className="col-sm-5">
          <label>Maximum Discount Amount* :</label>
          <input
            onChange={(e) => handle(e)}
            id="MaxPrice"
            value={data.MaxPrice}
            name="MaxPrice"
            className=" form-control"
            type="number"
            placeholder="600"
            required
          />
          <br></br>
        </div>


        <div className="col-2">
          <label>Start Date* :</label>
          <input
            onChange={(e) => handle(e)}
            id="StartDate"
            value={data.StartDate}
            name="StartDate"
            className="form-control"
            type="date"
            required
          />
          <br></br>
        </div>
        <div className="col-2">
          <label>End Date* :</label>
          <input
            onChange={(e) => handle(e)}
            id="EndDate"
            value={data.EndDate}
            name="EndDate"
            className="form-control"
            type="date"
            required
          />
          <br></br>
        </div>
        <div className="col-sm-5">
          <label>Limit number of use* :</label>
          <input
            onChange={(e) => handle(e)}
            id="max_use"
            value={data.max_use}
            name="max_use"
            className="form-control"
            type="number"
            placeholder="1"
            required
          />
          <br></br>
        </div>


        <div className="col-sm-5">
          <label>Include Categories</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Include Categories" onChange={handleChange2}>
            {prod}
          </Select>
        </div>
        <br></br>

        <div className="col-sm-5">
          <label>Exclude Categories</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Exclude Categories" onChange={handleChange3}>
            {prod}
          </Select>
        </div>
        <br></br>


        <div className="col-sm-5">
          <label>Included Products</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Included Products" onChange={handleChange}>
            {include}
          </Select>
        </div>

        <br></br>

        <div className="col-sm-5">
          <label>Excluded Products</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Excluded Products" onChange={handleChange1}>
            {exclude}
          </Select>
        </div>
        <br></br>

        <div className="col-sm-5">
        <label>How Discounts Will Be Applied</label>
        <br></br>
        <Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp="children"
                onChange={handle3}
                value={data.discount_applied_type}
                defaultValue={data.discount_applied_type}
                name="discount_applied_type"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="as_cashback">Cashback</Option>
                <Option value="as_deducted_amount">Deducted Amount</Option>
              </Select>
              </div>
              <br></br>


        <div className="col-sm-5">
          <label>Description</label>
          <CKEditor
            id="Description"
            name="Description"
            editor={ClassicEditor}
            onChange={(e, editor) => {
              handleClick(e, editor);
            }}
          />
          <br></br>
        </div>


        <div className="col-sm-5">
          <label>Know More</label>
          <textarea
            onChange={(e) => handle(e)}
            id="KnowMore"
            value={data.KnowMore}
            name="KnowMore"
            className=" form-control"
            type="text"
            required
          />
          <br></br>
        </div>

        <div className="col-sm-5">
          <label>Discount Image</label>
          <br></br> <br></br>
        </div>
        <input type="file"  accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} />
        <br></br> <br></br>


        <div className="col-sm-5">
          <label for="scales">IsActive</label>
          {/* {
            data.IsActive === '1' ?
              <input
                checked
                type="checkbox"
                className="checkbox team_values"
                checked
                onClick={(e) => {
                  selectShortlistedApplicant(e);
                }
                } /> :
              <input
                checked
                type="checkbox"
                className="checkbox team_values"
                onClick={(e) => {
                  selectShortlistedApplicant(e);
                }} />
          } */}

          <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />

        </div>
        <br></br>


        <button type="submit" className="btn btn-primary button " style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }}>Submit</button>
        <button type="reset" className="btn btn-primary button " style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }}><i className="fas fa-arrow-left"></i> Cancel</button>
        {
          discountResult ? <Alert message={discountResult.message} type="success" /> : <p></p>
        }
      </form>
    </div>
  );
}

export default Discount_form;
