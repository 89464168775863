import React, { useState, useEffect } from "react";
import API_REQUEST from "../../../config/client";
import Report from 'bv-react-data-report';
import 'bv-react-data-report/dist/index.css';
import { CSVLink } from "react-csv";
import { Select } from 'antd';

function ExclusiveListing() {
  
  const [posts, setPosts] = useState(null);

  const [displayPage, setDisplayPage] = useState(0);
  const [displayPage1, setDisplayPage1] = useState(0);
  const [report, setReport] = useState(null);
  const [action, setAction] = useState(null);
  const [opt, setOpt] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const items = [];

  const [data, setData] = useState({
    FromDate: "",
    ToDate: "",
    OrderBy: "",
    action: null,
  })

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getReport',
      }
      await API_REQUEST('reports', request).then((response) => setReport(response));
    }
    fetchAddress();
  }, []);


  useEffect(() => {
    if (report != null) {
      report.data.map(i => (
        items.push(<Option key={i.reportName}>{i.reportName}</Option>)
      ))
      setOpt(items)
      setReport(null);
    }
  }, [items]);


  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
    setData(data => ({ ...data, action: value }))
    setAction(value);
  }

  function onSearch(val) {
    console.log('search:', val);
  }


  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
  }

  const handleSubmit = async (e) => {
    setPosts(null)
    setDisplayPage1(0)
    e.preventDefault();
    var request = {
      action: action,
      data: data
    }
    await API_REQUEST('reports', request).then((response) => setPosts(response));
    setDisplayPage1(1)
  };

  if (displayPage === 0) {
    return (
      <div className="container_admin admin_body form">
        <form onSubmit={(e) => handleSubmit(e)}>
          <Select
            className="col-4"
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >{opt ? opt : <p>No Data Found </p>}
          </Select>

          <br></br>
          <br></br>

            <div className="col-2">
            <label>Parameters
            <br></br>
            <br></br>
              <label>From Date :</label>
              <input
                onChange={(e) => handle(e)}
                id="FromDate"
                value={data.FromDate}
                name="FromtDate"
                className="form-control"
                type="date"/>
              <br></br>

              <label>To Date :</label>
              <input
                onChange={(e) => handle(e)}
                id="ToDate"
                value={data.ToDate}
                name="ToDate"
                className="form-control"
                type="date"/>
              <br></br>
              <br></br>

              <label>Latest Limit :</label>
              <input
                onChange={(e) => handle(e)}
                id="OrderBy"
                value={data.OrderBy}
                name = "OrderBy"
                className="form-control"
                type="number"/>
              <br></br>
          </label>
            </div>
            <br></br>
          <button type="submit" className="btn btn-primary button " style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
        </form>



        {displayPage1 !== 0 ? posts.status !== 422 ? <div> <CSVLink  style={{ marginRight: 1 + "em", marginTop: 40, border: "none", backgroundColor: "darkorange", color: "white", }}  class="btn btn-primary button" data={posts.data}>Export to CSV</CSVLink>

          <Report data={posts.data} />  </div> : <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
          {posts.message}
        </div> : <p></p>
        }
      </div>

    );
  }
}
export default ExclusiveListing;
