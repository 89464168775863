import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Included from "./included";
import API_REQUEST from "../../../config/client";
import axios from "axios";
import Example from "./exp";
import 'antd/dist/antd.css';
import { Select, Option } from 'antd';
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";


function Update_Discount(props) {
  const [discountResult, getDiscountResult] = useState(null);
  const [selectFiles, setSelectedFiles] = useState([]);
  const [selectFiles1, setSelectedFiles1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState("");
  const [checked, setChecked] = useState([]);
  const [posts, setPosts] = useState();

  const [options, setOptions] = useState(null);
  const [options1, setOptions1] = useState(null);
  const [options2, setOptions2] = useState(null);
  const [product, getProduct] = useState(null);
  const [excproduct, getExcProduct] = useState(null);
  const [include, setInclude] = useState(null);
  const [exclude, setExclude] = useState(null);
  const [prodcat, setProdCat] = useState(null);
  const [prod, setProd] = useState(null);
  const [ci, setCi] = useState();
  const [ce, setCe] = useState();
  const [pi, setPi] = useState();
  const [pe, setPe] = useState();
  const [fetchDiscount, getFetchDiscount] = useState("");
  const [availstatus, getAvailStatus] = useState(false);
  var all = []
  var all1 = []
  var all2 = []
  var all3 = []
  const { discountId } = props;


  var items = [];
  var item = [];
  const { Option } = Select;


  useEffect(() => {
    async function fetchData() {
      var request = {
        action: 'fetchDiscount',
        discountId: discountId,
      }
      await API_REQUEST('fetch', request).then((response) => getFetchDiscount(response));
    }
    fetchData();

  }, []);

  useEffect(() => {
    if (fetchDiscount) {
      setData({
        VoucherCode: fetchDiscount.data.VoucherCode,
        Title: fetchDiscount.data.Title,
        Discount: fetchDiscount.data.Discount,
        AmountType: fetchDiscount.data.AmountType,
        // DiscountType: fetchDiscount.data.DiscountType,
        MinPrice: fetchDiscount.data.MinPrice,
        MaxPrice: fetchDiscount.data.MaxPrice,
        StartDate: fetchDiscount.data.StartDate,
         ImageName: fetchDiscount.data.ImageName,
        FileImage : 0,
        EndDate: fetchDiscount.data.EndDate,
        max_use: fetchDiscount.data.max_use,
        discount_applied_type: fetchDiscount.data.discount_applied_type,
        Description: fetchDiscount.data.Description,
        KnowMore: fetchDiscount.data.KnowMore,
        IsActive: fetchDiscount.data.IsActive,
        action: "updatediscount",
        discountId: discountId
      })
      getAvailStatus(true)
    }
  }, [fetchDiscount]);

  useEffect(() => {
    if (fetchDiscount) {
      fetchDiscount.ctg_inc.map(i => {
        all.push(i.CategoryID);
      });
      setCi(all);
      setData(data => ({ ...data, product_categories: all }));
    }
  }, [fetchDiscount]);

  useEffect(() => {
    if (fetchDiscount) {
      fetchDiscount.prod_inc.map(i => {
        all1.push(i.ProductID);
      });
      setPi(all1);
      setData(data => ({ ...data, include_product_ids: all1 }));
    }
  }, [fetchDiscount]);

  useEffect(() => {
    if (fetchDiscount) {
      fetchDiscount.prod_exc.map(i => {
        all2.push(i.ProductID);
      });
      setPe(all2);
      setData(data => ({ ...data, exclude_product_ids: all2 }));
    }
  }, [fetchDiscount]);

  useEffect(() => {
    if (fetchDiscount) {
      fetchDiscount.ctg_exc.map(i => {
        all3.push(i.CategoryID);
      });
      setCe(all3);
      setData(data => ({ ...data, exclude_category_ids: all3 }));
    }
  }, [fetchDiscount]);




  // // // INCLUDED PRODUCTS
  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getProdDetails',
      }
      await API_REQUEST('fetch', request).then((response) => getProduct(response));
    }
    fetchAddress();
  }, []);




  // // // // // // // // // //  EXCLUDED PRODUCT

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getExcludedProd',
      }
      await API_REQUEST('fetch', request).then((response) => getExcProduct(response));
    }
    fetchAddress();
  }, []);


  useEffect(() => {
    if (excproduct != null) {
      excproduct.data.map(i => (
        items.push(<Option key={i.ProductID}>{i.Name}</Option>)
      ))
      console.log("ibrahim");
      setInclude(items);
      setExclude(items);
      setLoading(false);
      getExcProduct(null);
    }
  }, [items]);
  const handleOnchange1 = val1 => {
    setOptions1(val1)
  }

  console.log(excproduct);

  // // // // // // // // // // // // // // // /// PRODUCT CATEGORY

  useEffect(() => {
    var fetchAddress = async () => {
      var request = {
        action: 'getProdCategory',
      }

      await API_REQUEST('fetch', request).then((response) => setProdCat(response));
    }
    fetchAddress();
  }, []);

  useEffect(() => {
    if (prodcat != null) {
      prodcat.data.map(i => (
        items.push(<Option key={i.CategoryID}>{i.CategoryName}</Option>)
      ))
      setProd(items);
      setLoading(false);
      setProdCat(null);
    }
  }, [items]);

  const handleOnchange2 = val2 => {
    setOptions2(val2)
  }
  console.log(prodcat);


  function handleChange(event) {
    getProduct(event.target.value);
  }


  const [data, setData] = useState({
    VoucherCode: "",
    Title: "",
    Discount: "",
    AmountType: "",
    DiscountType: "",
    MinPrice: "",
    MaxPrice: "",
    StartDate: "",
    EndDate: "",
    max_use: "",
    product_categories: [],
    include_product_ids: [],
    exclude_product_ids: [],
    exclude_category_ids: [],
    discount_applied_type: "",
    Description: "",
    KnowMore: "",
    IsActive: true,
    FileImage: 0,
    IncludedProducts: checked,
    action: "process_discount",
  });
  var DisplaySms = null;
  //  const { Option } = Select;

  useEffect(() => {
    setFormData(new FormData());
    async function fetchData() {
      await API_REQUEST("fetch", { action: "getDiscount" }).then((response) =>
        setPosts(response)
      );
    }
    fetchData();
  }, []);

  function handleChange(value) {
    setData(data => ({ ...data, include_product_ids: value }));
  }

  function handleChange1(value1) {
    setData(data => ({ ...data, exclude_product_ids: value1 }));
  }

  function handleChange2(value2) {
    setData(data => ({ ...data, product_categories: value2 }));
  }

  function handleChange3(value3) {
    setData(data => ({ ...data, exclude_category_ids: value3 }));
  }


  // const fileChange = (e) => {
  //   let files = e.target.files;
  //   setSelectedFiles(files);
  // };

  const fileChange = (e) => {
    let files = e.target.files;
    setSelectedFiles(files);
    setData((data) => ({ ...data, FileImage: 1 }));
  };

  function handleClick(e, editor) {
    setData({ ...data, Description: editor.getData() });
    console.log(data);
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
  }

  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();

    if (selectFiles.length !== 0) {
      file_data.append("file", selectFiles[0]);

    }
    if (selectFiles1.length !== 0) {
      for (let i = 0; i < selectFiles1.length; i++) {
        file_data.append("files[]", selectFiles1[i]);
      }

    }

    // file_data.append("file", selectFiles[0]);
    file_data.append("request_data", JSON.stringify(data));
    async function processDiscountForm() {
      console.log(file_data);
      var BASE_URL = "http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/";
      var fetchData = axios
        .post(CONST_URL + "others.php", file_data)
        .then((response) => getDiscountResult(response.data));
    }
    processDiscountForm();
  }


  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setData({ ...data, IsActive: '1' })
    } else {
      setData({ ...data, IsActive: '0' })
    }
  };

  function handle2(e) {
    setData(data => ({ ...data, AmountType: e }));
  }

  function handle3(e) {
    setData(data => ({ ...data, discount_applied_type: e }));
  }

  return (
    availstatus &&

    <div className="container_admin admin_body form">
      <form onSubmit={(e) => submit(e)}>
        <br></br>
        <div className="col-sm-5">
          <label>Voucher Code* :</label>
          <input
            onChange={(e) => handle(e)}
            id="VoucherCode"
            value={data.VoucherCode}
            name="VoucherCode"
            className=" form-control"
            type="text"
            placeholder="MCUBE1"
            required
          />
          <br></br>
        </div>
        <div className="col-sm-5">
          <label>Title* :</label>
          <input
            onChange={(e) => handle(e)}
            id="Title"
            value={data.Title}
            name="Title"
            className=" form-control"
            type="text"
            placeholder="mcube"
            required
          />
          <br></br>
        </div>
        <div className="col-sm-5">
        <label>Select Percentage / Amount</label>
        <br></br>

        <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onChange={handle2}
                value={data.AmountType}
                defaultValue={data.AmountType}
                name="AmountType"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="1">1 -  Percentage</Option>
                <Option value="2">2 -  Amount</Option>
              </Select>
              </div>
              <br></br>

        <div className="col-sm-5">
          <label>Discount* :</label>
          <input
            onChange={(e) => handle(e)}
            id="Discount"
            value={data.Discount}
            name="Discount"
            className=" form-control"
            type="number"
            placeholder="19"
            min="1"
            max="1000"
            required
          />
          <br></br>
        </div>


        <div className="col-sm-5">
          <label>Minimun Cart Value* :</label>
          <input
            onChange={(e) => handle(e)}
            id="MinPrice"
            value={data.MinPrice}
            name="MinPrice"
            className=" form-control"
            type="number"
            placeholder="300"
            required
          />
          <br></br>
        </div>

        <div className="col-sm-5">
          <label>Maximum Discount Amount* :</label>
          <input
            onChange={(e) => handle(e)}
            id="MaxPrice"
            value={data.MaxPrice}
            name="MaxPrice"
            className=" form-control"
            type="number"
            placeholder="300"
            required
          />
          <br></br>
        </div>


        <div className="col-2">
          <label>Start Date* :</label>
          <input
            onChange={(e) => handle(e)}
            id="StartDate"
            value={data.StartDate}
            name="StartDate"
            className="form-control"
            type="date"
            required
          />
          <br></br>
        </div>
        <div className="col-2">
          <label>End Date* :</label>
          <input
            onChange={(e) => handle(e)}
            id="EndDate"
            value={data.EndDate}
            name="EndDate"
            className="form-control"
            type="date"
            required
          />
          <br></br>
        </div>
        <div className="col-sm-5">
          <label>Limit number of use* :</label>
          <input
            onChange={(e) => handle(e)}
            id="max_use"
            value={data.max_use}
            name="max_use"
            className="form-control"
            type="number"
            placeholder="1"
            required
          />
          <br></br>
        </div>


        <div className="col-sm-5">
          <label>Include Categories</label>
        </div>

        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" defaultValue={ci} onChange={handleChange2}>
            {prod}
          </Select>
        </div>
        <br></br>

        <div className="col-sm-5">
          <label>Exclude Categories</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" defaultValue={ce} onChange={handleChange3}>
            {prod}
          </Select>
        </div>
        <br></br>


        <div className="col-sm-5">
          <label>Included Products</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" defaultValue={pi} onChange={handleChange}>
            {include}
          </Select>
        </div>

        <br></br>

        <div className="col-sm-5">
          <label>Excluded Products</label>
        </div>
        <div className="preview-values">
          {options}
        </div>
        <div className="col-sm-5">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" defaultValue={pe} onChange={handleChange1}>
            {exclude}
          </Select>
        </div>
        <br></br>


        <div className="col-sm-5">
        <label>How Discounts Will Be Applied</label>
        <br></br>
        <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onChange={handle3}
                value={data.discount_applied_type}
                defaultValue={data.discount_applied_type}
                name="discount_applied_type"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="as_cashback">Cashback</Option>
                <Option value="as_deducted_amount">Deducted Amount</Option>
              </Select>
              </div>
              <br></br>


        <div className="col-sm-5">
          <label>Description</label>
          <CKEditor id="Description"
            editor={ClassicEditor}
            name="Description" onChange={(e, editor) => { handleClick(e, editor); }}
            onReady={editor => { editor.setData(data.Description) }} />
          <br /><br />
        </div>

        <div className="col-sm-5">
          <label>Know More</label>
          <textarea
            onChange={(e) => handle(e)}
            id="KnowMore"
            value={data.KnowMore}
            name="KnowMore"
            className=" form-control"
            type="text"
            placeholder="Know more"
            required
          />
          <br></br>
        </div>

        <div className="col-sm-5">
          <label>Discount Image</label>
          <br></br> <br></br>
        </div>
        <input type="file"  accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} />
        <br></br> <br></br>


        <div className="col-sm-5">
          <label for="scales">IsActive</label>
          {
            data.IsActive === '1' ?
              <input
                type="checkbox"
                className="checkbox team_values"
                checked
                onClick={(e) => {
                  selectShortlistedApplicant(e);
                }
                } /> :
              <input
                type="checkbox"
                className="checkbox team_values"
                onClick={(e) => {
                  selectShortlistedApplicant(e);
                }} />
          }
        </div>
        <br></br>


        <button type="submit" className="btn btn-primary button" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }}>Update</button>
        <button type="reset" className="btn btn-primary button" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }}><i className="fas fa-arrow-left"></i> Cancel</button>
        {
          discountResult ? <Alert message={discountResult.message} type="success" /> : <p></p>
        }
      </form>
    </div>
  );
}

export default Update_Discount;
