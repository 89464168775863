import React, { useState } from "react";
import API_REQUEST from "../../../config/client";
import axios from 'axios'
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";


function Exclusivedeals() {

    const [dealsResult, getDealsResult] = useState(null)
    const [data, setData] = useState({
        name: "",
        dealLink: "",
        dealDescription: "",
        IsActive: true,
        action: "process_deals"
    });

    const [selectFiles, setSelectedFiles] = useState()

    function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setData(newdata);
        console.log(e.target.value);
        console.log(newdata);
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)
    }

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles[0])
        file_data.append('request_data', JSON.stringify(data))
        async function processDealsForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getDealsResult(response.data))

        }
        processDealsForm()
    }

    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setData({ ...data, IsActive: '1' })
        } else {
            setData({ ...data, IsActive: '0' })
        }
    };


    return (
        <div className="container_admin admin_body form">
            <form onSubmit={(e) => submit(e)}>
                <br />

                <div className="col-sm-5">
                    <label>Deal Name</label> <br />
                    <input onChange={(e) => handle(e)} id="name" name="name" value={data.name} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Deal Image</label> <br />
                    <br></br>
                    <input type="file" accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} required />
                    <br></br> <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Deal Link</label> <br />
                    <input onChange={(e) => handle(e)} id="dealLink" value={data.dealLink} name="dealLink" className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Deal Description</label> <br />
                    <textarea onChange={(e) => handle(e)} id="dealDescription" value={data.dealDescription} name="dealDescription" className=" form-control" type="text" />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label for="scales">IsActive</label>
                    <input type="checkbox" defaultChecked={true} onChange={(e) => handle(e)} value={data.IsActive} name="IsActive" id="IsActive" />
                </div>
                <br></br>


                <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    dealsResult ? <Alert message={dealsResult.message} type="success" /> : <p></p>
                }
            </form>
        </div>
    )
}

export default Exclusivedeals
