import React  from "react";
import {  Button } from 'react-bootstrap';

import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";

function Logout()  {

    const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };
        return (
          <div>
              <Button  href="/login" onClick={logOut}>Logout</Button>
         </div>
        );
    
};

export default Logout;