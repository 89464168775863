import React, { useState, useEffect, useCallback, Component } from 'react'
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap'
import { Rate, Spin } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import renderHTML from 'react-render-html';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import logo from '../../../assets/img/master-card.jpg'

import { GetProductCategoriesData, GetProductPriceHtml, CheckProductQuantity } from '../../utils/mcube_helper/jems_db_helper'
import { HandleCartOnSubmit, OpenNotification } from '../../utils/mcube_helper/purchase_item'
import HandleBuyNowOnSubmit from '../../utils/mcube_helper/buynow_item';

import QuantityButton from '../../utils/quantity/qty_btn'
import OptionTabs from './option_tabs'
import ProductImageGallery from './image_galley'
// import DrawerForCart from './../../utils/cartDraw/cart_drawer'

import API_REQUEST from '../../../config/client';
import { Helmet } from 'react-helmet';

import { useLocation, useParams } from 'react-router-dom';

import Get_direct_image from '../../../config/get_direct_image';




function ProductDetails() {
    const { slugurl } = useParams();
    // current product state
    const [SingleProductDetails, getProductDetails] = useState(null)
    const [productId, getProductId] = useState(null);
    const [title, getTitle] = useState(null);
    const [desc, getDesc] = useState(null);
    const [keyword, getKeyWord] = useState(null);
    const [productReview, getProductReview] = useState(null)
    const [notify, setNotify] = useState(null)
    const [counter, getCounter] = useState(1)
    const [buttonText, setButtonText] = useState('Notify');
    const [stock, setStock] = useState("In Stock");
    const [productQty, getProductQty] = useState(null);
    const [offers, getOffers] = useState("");
    const [urllist, getUrlList] = useState(null);
    const [ImageUrlList, getImageUrlList] = useState(null);


    var Displayoffer = null;

     function sleeper(ms) {
         return function (x) {
             return new Promise(resolve => setTimeout(() => resolve(x), ms));
         };
     }

     // Scroll to top when page renders
     useEffect(() => {
         window.scrollTo(0, 0);
     }, []);

    //usesel#tor of logged in users
    const { isLoggedIn } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth);
    if (isLoggedIn) {
        var customerId = user.id;
        if (!customerId) {
            customerId = JSON.parse(user).id;
        }
    } else {
        customerId = null;
    }

    // if empty for temo REMOVE and replace by loader
    var productLoader = <LoadingOutlined style={{ fontSize: 24 }} spin />;

   // fetch product api
    useEffect(() => {
        let isMounted = true;
        var request = { action: 'singleProduct_details', product_slug: slugurl }
        async function fetchSingleProductDetails() {
            await API_REQUEST('single_product', request).then((response) => {
                getProductDetails(response);
                getProductId(response.Data.id); 
                getTitle(response.Data.MetaTitle);
                getDesc(response.Data.MetaDescription);
                getKeyWord(response.Data.MetaKeyword);
            })
        }
        fetchSingleProductDetails()
        return () => { isMounted = false };
    }, []);

    //console.log("testItem ",typeof(parseInt(productId)));


    useEffect(() => {
        let isMounted = true;

        var fetchQty = async () => {
            await API_REQUEST('jems_db_helper', {
                action: 'get_product_qty',
                id: productId
            }).then((response) => {
                if (isMounted) getProductQty(response)
            });
        }

        var request_review = { action: 'get_review', product_id: productId, CustomerID: customerId }
        async function getproductreview() {
            await API_REQUEST('single_product', request_review).then((response) => { if (isMounted) getProductReview(response) })
        }

        fetchQty();
        getproductreview();

        return () => {
            isMounted = false
        };
    }, [productId]);


    const handlecart = useCallback((data) => {
        HandleCartOnSubmit(data)
        OpenNotification(SingleProductDetails.Data.Name)
    }, [SingleProductDetails])

    const handleNotify = async (data) => {
        var request = {
            action: 'setNotification',
            customerId: customerId,
            prodId: productId
        }
        await API_REQUEST('single_product', request).then((response) => setNotify(response));
        setButtonText('We Will Notify You!')
    };

    const handlebuynow = useCallback((data) => {
        HandleBuyNowOnSubmit(data)
    }, [productId])


    useEffect(() => {
        var fetchOffers = async () => {
            await API_REQUEST('singleProductOffer', { action: 'fetchOffer', 'ProductID': productId }).then((response) => getOffers(response));
        }
        fetchOffers();
    }, [SingleProductDetails]);

    useEffect(() => {
        var getimageResults = async () => {
            await API_REQUEST('single_product', { action: 'get_images_path', product_id: productId }).then((response) => getUrlList(response))
        }
        getimageResults()
        //console.log("items ",urllist);
    }, [productId])

    var list = [];

    useEffect(() => {
        if (urllist != null) {
            //console.log(urllist)
            urllist.Data.map(i => (

                list.push(Get_direct_image("products/" + productId, i.path))
            ))

            getImageUrlList(list);
            getUrlList(null);

        }
        //console.log("Pranav")
        //console.log(ImageUrlList)
    }, [urllist]);



    if (offers.message === "Inclusive Success") {
        Displayoffer = offers.Data.map((item) =>
            <p><span className="margin-5">Offers:</span><br />({item.vouchercode}) {item.title}
                <Card.Link href={"/know-more?id=" + item.vid}> Know More</Card.Link><br /></p>

        );
    }

    if (offers.message === "Exclusive Success") {
        Displayoffer = offers.Data.map((item) =>
            <p><span className="margin-5">Disclaimer:</span><br />({item.vouchercode}) {item.title} <br /></p>
        );
    }


    const handleParentCounter = (count) => {
        // //console.log(count)
        getCounter(count)
    }

    const LoadSingleProductComponent = () => (
        <Container>
            <Row>
                <Col lg={6}>
                    <ProductImageGallery product_id={productId} />
                </Col>
                <Col lg={6}>
                    <div className="product-details-text positions">
                        <h3>{SingleProductDetails.Data.Name}</h3>
                        {/* <div className="tour-rating">
                            <Rate
                                disabled
                                allowHalf
                                defaultValue={productReview.Data.ratings}
                                style={{ color: '#ffa11a' }}
                            />                                                                                                                            
                        </div> */}
                        <div className="single-pro-shop-price">
                            <p className="flex_display"><span className="price_span margin-5 fs-20">Price:</span> <GetProductPriceHtml productId={productId} /></p>
                            <p className="flex_display"><span className="available_span margin-5">Available:
                            </span><CheckProductQuantity productId={productId} /></p>
                            <p><span className="sku_span margin-5">SKU:</span>{SingleProductDetails.Data.SKU}</p>
                            {/* <p><span className = "spacing_span">Add to wishlist</span><HeartOutlined /></p> */}
                            {
                                offers && Displayoffer ? Displayoffer : <p></p>
                            }
                            {
                                (SingleProductDetails.Data.Barcode !== "") ?
                                    <p><span className="ean_span margin-5">UPC/EAN:</span>{SingleProductDetails.Data.Barcode}</p> : <p></p>
                            }
                            {
                                (SingleProductDetails.Data.Comment !== "") ?
                                    <p>{SingleProductDetails.Data.Comment}</p> : <p></p >
                            }

                            {
                                (SingleProductDetails.Data.ReleaseDate != "") ? <p className='release_date'> <span className='release'> Release Date: </span> {SingleProductDetails.Data.ReleaseDate} </p> : <p> </p>
                            }
                        </div>

                        <div className="quantity">
                            <p className="quantity-p">Quantity: </p>
                            <div className="num-block skin-2">
                                {/* <QuantityButton id = {id} Quantity = {0} ItemsInStock = {SingleProductDetails.Data.Quantity}/> */}
                                <QuantityButton handleParentCounter={handleParentCounter} id={productId} Quantity={counter} ItemsInStock={SingleProductDetails.Data.Quantity} />
                            </div>
                        </div>
                        {
                            productQty > 0 ?
                                <Row>
                                    <Col md={6}>
                                        <div className="single-shop-page-btn">
                                            <Button className="fag-btn" onClick={data => handlecart({ id: productId, isLoggedIn: isLoggedIn, customer_id: customerId, qty: counter, Slug: slugurl })}>
                                                add to cart
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        {SingleProductDetails.Data.PreOrderExists === "0" ?
                                            <div className="single-shop-page-btn">
                                                <Button className="fag-btn" onClick={data => handlebuynow({ id: productId, isLoggedIn: isLoggedIn, customer_id: customerId, qty: counter })} href={"/checkout?src=" + productId}>
                                                    Buy Now
                                                </Button>
                                            </div>
                                            :
                                            <div className="single-shop-page-btn">
                                                <Button className="fag-btn" onClick={data => handlebuynow({ id: productId, isLoggedIn: isLoggedIn, customer_id: customerId, qty: counter })} href={"/checkout?src=" + productId}>
                                                    Pre Order
                                                </Button>
                                            </div>
                                        }
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col md={6}>
                                        <div className="single-shop-page-btn">
                                            <Button className="fag-btn" onClick={data => handleNotify({ id: productId, isLoggedIn: isLoggedIn, customer_id: customerId, qty: counter })}>
                                                {buttonText}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                        }

                        <div className="single-pro-shop-price">

                            <p className="flex_display product_category_list"><span className="spacing_span">CATEGORIES:</span><GetProductCategoriesData productId={productId} /></p>
                        </div>
                        <div className="single-pro-page-para">

                            {renderHTML(SingleProductDetails.Data.ShortDescription)}

                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12} >
                    <div className="product-panel-list">
                        <div className="product-tab">
                            <OptionTabs activeTab={1} tabsData={SingleProductDetails.Data} reviewData={productReview} meta_info={{ id: productId, isLoggedIn: isLoggedIn, customer_id: customerId, qty: counter }} />
                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    );

    return (
        <>
         <Helmet>
            <title>{title}</title>‍
            <meta name="description" content={desc}/>
            <meta name="keywords" content={keyword}/>
        </Helmet>
        <div className="fag-product-details section_100_single_prod mt-70">
            {
                (SingleProductDetails && productReview) &&
                    <LoadSingleProductComponent /> ?
                    <LoadSingleProductComponent /> :
                    <Spin indicator={productLoader} />
            }
        </div>
         </>
    )
}

export default ProductDetails;