import React, {useState, useEffect} from 'react'
import API_REQUEST from '../../../config/client'

function GetProductCategoriesData(props){
    var id = props.productId;
    const [category, setcategory] = useState(null);
    var cat_array = 'AED';
    useEffect(() => {
        let isMounted = true;

        var fetchCategory = async () => {
            await API_REQUEST('jems_db_helper', {action:'get_product_categories_data', id:id}).then((response) => { if (isMounted) setcategory(response)});
         }
      
         fetchCategory();

        return () => { isMounted = false };
    },[id]);

    if (category){
        cat_array = category.map(item =>(<li>{item}</li>) )
    }
   
    return (
        <div className="flex_display category_display">{cat_array}</div>
    )
}

function GetProductPriceHtml(props){
    var id = props.productId;
    //console.log(id + ' Id Jems Db Helper')
    const [offerPrice, SetOfferPrice] = useState(null)
    var price = ""
    var currency_unit = <span style= {{fontSize:20}}>AED</span>;

    useEffect(() => {
        let isMounted = true;

        var fetchCategory = async () => {
            await API_REQUEST('jems_db_helper', {action:'get_product_price_html', id:id}).then((response) => { if (isMounted) SetOfferPrice(response)});
         }
      
        fetchCategory();
        
        return () => { isMounted = false };

    },[id]);

    if(offerPrice){
      if(offerPrice.disc_price !== null){
        price = (<div><div className = "prev-price"><del>{offerPrice.current}</del></div> <div className = "current-price">{offerPrice.disc_price}</div></div>)
      }else{
        price = (<div className = "current-price">{offerPrice.current}</div>)
      }
    }
    return (
        <div className="flex_display">
            <p className="price-p">{currency_unit} {price}</p>
        </div>
    )
}

function CheckProductQuantity(props){
    var id = props.productId;
    const [productQty, getProductQty] = useState(null);
    var qty = "";
    useEffect(() => {
        let isMounted = true;

        var fetchQty = async () => {
            await API_REQUEST('jems_db_helper', {action:'get_product_qty', id:id}).then((response) =>{ if (isMounted) getProductQty(response)});
         }
        fetchQty();

        return () => { isMounted = false };
    },[id]);
    if(productQty){
        if(productQty > 0){
            qty = "In Stock"
            return (
                <div className="flex_display">
                    <p style= {{marginTop:2,fontSize:20,color:'green'}}>{qty}</p>
                </div>
            )
        }else{
            qty = "Out of Stock"
            return (
                <div className="flex_display">
                    <p style= {{marginTop:2,fontSize:20,color:'red'}}>{qty}</p>
                </div>
            )
        }
    }else{
        return (
            <div className="flex_display">
                <p style= {{marginTop:2,fontSize:20,color:'gray'}}>Loading...</p>
            </div>
        )
    }
}

export {
    GetProductCategoriesData, 
    GetProductPriceHtml,
    CheckProductQuantity
}