import React from 'react';
// import "../../../assets/css/style.css";
import Topic from "../../ui/topic";

function Contact_form(){
    return(
        <>
        
         
            <Topic name1={'Contact'} name2={'Here'}/>
            <div class="row">
               <div class="col-lg-12">
                  <div class="contact-form-inn">
                     <form>
                        <div class="row">
                           <div class="col-lg-6">
                              <div class="comment-field">
                                 <input class="ns-com-name" name="name" placeholder="Name" type="text" />
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="comment-field">
                                 <input class="ns-com-name" name="email" placeholder="Email" type="email" />
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-lg-12">
                              <div class="comment-field">
                                 <textarea class="comment" placeholder="Write your message here..." name="comment"></textarea>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-lg-12">
                              <div class="comment-field form-action">
                                 <button type="submit" class="fag-btn">Send Message </button>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

     
        </>
    );
}

export default Contact_form;