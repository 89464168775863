import React, { useState, useEffect, useCallback } from "react";
import { Form, Button } from 'react-bootstrap';
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";


function UpdateBlogs(props) {
    const [blogsResult, getBlogsResult] = useState(null)
    const [fetchBlog, getFetchBlog] = useState("");
    const [selectFiles, setSelectedFiles] = useState();
    const [availstatus, getAvailStatus] = useState(false)
    const { BlogId } = props;

    const [data, setData] = useState({
        BlogName: "",
        Slug: "",
        Description: "",
        BlogLink: "",
        action: "process_blogs"
    });

    useEffect(() => {
        async function fetchData() {
            var request = {
                action: 'fetchBlog',
                BlogId: BlogId
            }
            await API_REQUEST('fetch', request).then((response) => getFetchBlog(response));
        }
        fetchData();

    }, []);


    useEffect(() => {
        if (fetchBlog) {
            console.log(fetchBlog)
            setData({
                BlogName: fetchBlog.BlogName,
                Slug: fetchBlog.Slug,
                Description: fetchBlog.Description,
                BlogLink: fetchBlog.BlogLink,
                action: "updateblog",
                BlogId: BlogId
            })
            getAvailStatus(true)

        }
    }, [fetchBlog]);

    console.log(data);

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)

    }

    function handleClick(e, editor) {
        setData({ ...data, Description: editor.getData() })
    }

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles[0])
        file_data.append('request_data', JSON.stringify(data))
        async function processBlogsForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getBlogsResult(response.data))

        }
        processBlogsForm()
    }

    return (
        availstatus &&
        <div className="container_admin admin_body form">
            <Form onSubmit={(e) => submit(e)}>
                <br />


                <div className="col-sm-5">
                    <label>Blog Name</label> <br />
                    <input onChange={(e) => handle(e)} id="BlogName" name="BlogName" value={data.BlogName} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Slug</label> <br />
                    <input onChange={(e) => handle(e)} id="Slug" name="Slug" value={data.Slug} className=" form-control" type="text" required />
                    <br />
                </div>

                <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description"
                        editor={ClassicEditor}
                        name="Description" onChange={(e, editor) => { handleClick(e, editor); }}
                        onReady={editor => {
                            editor.setData(data.Description)
                        }}
                    />
                    <br /><br />
                </div>


                <div className="col-sm-5">
                    <label>Blog Link</label> <br />
                    <input onChange={(e) => handle(e)} id="BlogLink" value={data.BlogLink} name="BlogLink" className=" form-control" type="text" required />
                    <br />
                </div>


                <div className="col-sm-5">
                    <label>Blog Thumbnail</label> <br />
                    <br></br>
                    <input type="file" accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} required />
                    <br></br> <br></br>
                </div>
                <br></br>


                <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Update </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    blogsResult ? <Alert message={blogsResult.message} type="success" /> : <p></p>
                }
            </Form>
        </div>
    )
}

export default UpdateBlogs
