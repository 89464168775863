import React,{useState,useEffect} from 'react'
import { Row, Col , Image } from 'react-bootstrap'
import {useLocation} from 'react-router-dom';
import API_REQUEST from '../../../config/client';
import Get_direct_image from '../../../config/get_direct_image';
import renderHTML from 'react-render-html';
import UniversalNav from "../../../pages/template/header"
import UniversalFooter from "../../../pages/template/footer"

function KnowMore() {

    const loc = useLocation().search
    const id = new URLSearchParams(loc).get('id')

    const [knowMore,getKnowMore] = useState(null)
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState({image : "" , knowmore: ""});

    useEffect(() => {
        var fetchOffers = async () => {
           await API_REQUEST('voucher', {action:'fetchKnowMore','VoucherId':id}).then((response) => getKnowMore(response));
        }
        fetchOffers();
    },[]);

useEffect(() => {
    if (knowMore){
        setData({image: knowMore.Data.image, knowmore: knowMore.Data.KnowMore}) 
       setLoading(true)
}
}, [knowMore]);

//console.log(data)
    return (

        
        loading &&
        <>
        <UniversalNav />
        <div className="knowContainer">
            <Row className="knowRow">
                <Col>
                    <div className="knowImg">
                        <Image src={Get_direct_image("voucher" , data.image )} />
                    </div>
                </Col>
                <Col>
                    <div className="know-more">
                        {renderHTML(data.knowmore)}
                    </div>
                </Col>
            </Row>
        </div>
        <UniversalFooter />
        </>
    )

}

export default KnowMore
