import React,{ useState , useEffect } from 'react';
import {Row , Col , Card , Image} from 'react-bootstrap';
import API_REQUEST from '../../../config/client';
import Get_image from '../../../config/get_image';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import renderHTML from 'react-render-html';
import Get_direct_image from '../../../config/get_direct_image';
import Img from 'react-cool-img';

function Blogs() {
    // SLEEPER FUNCTION ------------------------------------------------------------------------------
    function sleeper(ms) {
        return function(x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }
    // VARIABLES ------------------------------------------------------------------------------
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [blog,getBlogs] = useState(null);
    var Displaypdata = null;

    // API REQUEST ------------------------------------------------------------------------------
    useEffect(() => {
        var fetchBlogs = async () => {
        await API_REQUEST('blog', {action:'getBlogs'}).then(sleeper(1)).then((response) => getBlogs(response));
        }
        fetchBlogs();
    }, []);


    // IF STATEMENT ------------------------------------------------------------------------------
    if(blog != null){
        
        Displaypdata =  blog.Data.map(item => (

            <div className="blogs-section-up" key={item.id}>
                <Row className = "blog-item-up" >
                    <div className = "blog-image-up col-12 col-md col-xl" >
                        <Card.Link href={item.link}>
                            {/* <Image src={Get_image("products",item.id,item.img_url)} alt="blog" /> */}
                            <Img src={Get_direct_image("blogs" ,item.image)} alt="blog" debounce={1000} />
                        </Card.Link>
                    </div>
                    <Col   className="blog-text-up">
                        <h3><Card.Link href={item.link}>{item.title}</Card.Link></h3>
                        {renderHTML(item.desc)}
                    </Col>
                </Row>
            </div>

        ));
    }

    // RETURN STATEMENT ------------------------------------------------------------------------------
    return (
        <>
            <section class="fag-blog-area section_100 blog-area-up">
                <div className="container">
                    <Row className="row news-row">
                        <Col lg={12} className="col-12">
                            <div className="site-heading news-site-heading">
                                <h3 className="heading_animation blog-heading-up"><span>BLOGS</span></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="row blogs-row">
                        <Col  lg={2}></Col>
                        <Col sm={12} md={12} lg={8} className="col-12">
                            
                            {
                                Displaypdata  ? Displaypdata : <Spin indicator={antIcon} />
                            }

                        </Col>
                        <Col  lg={2}></Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default Blogs;
