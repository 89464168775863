import React, { useState, useEffect, useCallback } from "react";
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Alert } from 'antd';
import CONST_URL from "../../../config/url";

function Update_News(props) {
    const [newsResult, getNewsResult] = useState(null)
    const [fetchNews, getFetchNews] = useState("");
    const [selectFiles, setSelectedFiles] = useState();
    const [availstatus, getAvailStatus] = useState(false)
    const { NewsId } = props;


    const [data, setData] = useState({
        Title: "",
        Headline: "",
        Description: "",
        NewsLink: "",
        CreateDate: "",
        Priority: "",
        IsActive: true,
        action: "process_news"
    });

    useEffect(() => {
        async function fetchData() {
            var request = {
                action: 'fetchNews',
                NewsId: NewsId
            }
            await API_REQUEST('fetch', request).then((response) => getFetchNews(response));
        }
        fetchData();

    }, []);


    useEffect(() => {
        if (fetchNews) {
            console.log(fetchNews)
            setData({
                Title: fetchNews.Title,
                Headline: fetchNews.Headline,
                Description: fetchNews.Description,
                NewsLink: fetchNews.NewsLink,
                CreateDate: fetchNews.CreateDate,
                Priority: fetchNews.Priority,
                IsActive: fetchNews.IsActive,
                action: "updatenews",
                NewsId: NewsId
            })
            getAvailStatus(true)

        }
    }, [fetchNews]);

    console.log(data);

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)

    }

    function handleClick(e, editor) {
        setData({ ...data, Description: editor.getData() })
    }

    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
        if (checked) {
          setData({ ...data, IsActive: "1" });
        } else {
          setData({ ...data, IsActive: "0" });
        }
      };

    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles[0])
        file_data.append('request_data', JSON.stringify(data))
        async function processNewsForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getNewsResult(response.data))

        }
        processNewsForm()
    }



    return (
        availstatus &&
        <>
            <div className="container_admin admin_body form">
                <form onSubmit={(e) => submit(e)}>
                    <br />

                    <div className="col-sm-5">
                        <label>Title</label> <br />
                        <input onChange={(e) => handle(e)} id="Title" name="Title" value={data.Title} className=" form-control" type="text" required />
                        <br />
                    </div>

                    <div className="col-sm-5">
                        <label>Headline</label> <br />
                        <input onChange={(e) => handle(e)} id="Headline" name="Headline" maxlength="45" value={data.Headline} className=" form-control" type="text" required />
                        <br />
                    </div>

                    <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description"
                        editor={ClassicEditor}
                        name="Description" onChange={(e, editor) => { handleClick(e, editor); }}
                        onReady={editor => {
                            editor.setData(data.Description)
                        }}
                    />
                    <br /><br />
                </div>


                    <div className="col-sm-5">
                        <label>News Link</label> <br />
                        <input onChange={(e) => handle(e)} id="NewsLink" value={data.NewsLink} name="NewsLink" className=" form-control" type="text" required />
                        <br />
                    </div>

                    <div className="col-sm-5">
                        <label>Priority</label> <br />
                        <input onChange={(e) => handle(e)} id="Priority" value={data.Priority} name="Priority" className=" form-control" type="text" required />
                        <br />
                    </div>


                    <div className="col-sm-5">
                        <label>News Thumbnail</label> <br />
                        <br></br>
                        <input type="file" accept="image/png,  image/jpeg, image/.webp" name="file" id="image_upload" onChange={fileChange} required />
                        <br></br> <br></br>
                    </div>
                    <br></br>

                    <div className="col-sm-5">
                        <label>Date </label>
                        <input
                            onChange={(e) => handle(e)}
                            id="Date"
                            className="form-control"
                            type="date"
                            name="Date"
                            value={data.CreateDate}
                            style={{ width: "200px" }}
                        />
                        <br></br>
                    </div>

                    <div className="col-sm-5">
                <label for="scales">IsActive</label>
                {data.IsActive === "1" ? (
                  <input
                    type="checkbox"
                    className="checkbox team_values"
                    checked
                    onClick={(e) => {
                      selectShortlistedApplicant(e);
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="checkbox team_values"
                    onClick={(e) => {
                      selectShortlistedApplicant(e);
                    }}
                  />
                )}
              </div>
              <br></br>


                    <button className="btn btn-primary button " type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Update </button>
                    <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                    {
                        newsResult ? <Alert message={newsResult.message} type="success" /> : <p></p>
                    }
                </form>
            </div>
        </>
    )
}

export default Update_News