import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from "../../../config/client";
import axios from 'axios'
import CONST_URL from "../../../config/url";



function Testimonial() {

  const [testimonialResult, getTestimonialResult] = useState(null)
  const [data, setData] = useState({
    Title: "",
    Content: "",
    AuthorName: "",
    Designation: "",
    IsActive: true,
    action: "process_testimonial"
  });

  const [selectFiles, setSelectedFiles] = useState()

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setData(newdata);
    console.log(e.target.value);
    console.log(newdata);
  }

  const fileChange = (e) => {
    let files = e.target.files
    setSelectedFiles(files)

  }

  function handleClick(e, editor) {
    setData({ ...data, Content: editor.getData() })
    console.log(data);
}


  function submit(e) {
    e.preventDefault();
    const file_data = new FormData();
    file_data.append("file", selectFiles[0])
    file_data.append('request_data', JSON.stringify(data))
    async function processTestimonialForm() {
      console.log(file_data)
      var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
      var fetchData = axios.post(CONST_URL + 'others.php', file_data).then((response) => getTestimonialResult(response.data))

    }
    processTestimonialForm()
  }

  return (

    <div className="container form">
      <form onSubmit={(e) => submit(e)}>
        <br></br>

        <div className="col-sm-5">
          <label>Title*</label>
          <input onChange={(e) => handle(e)} id="Title" value={data.Title} name="Title" className=" form-control" type="text" placeholder="Test testimonial" required />
        </div>
        <br></br>


        <div className="col-sm-5">
          <label>Content</label>
          <CKEditor id="Content" name="Content" editor={ClassicEditor} onChange={(e, editor) => { handleClick(e, editor) }} />
          <br></br>
        </div>

        <div className="col-sm-5">
          <label>Author Image</label>
          <br></br> <br></br>
          <input type="file" name="file" id="image_upload" onChange={fileChange} />
          <br></br> <br></br>
        </div>


        <div className="col-sm-5">
          <label>Author Name</label>
          <input onChange={(e) => handle(e)} id="AuthorName" value={data.AuthorName} name="AuthorName" className="form-control" type="text" required placeholder="Arjun" />
          <br></br>
        </div>



        <div className="col-sm-5">
          <label>Designation</label>
          <input onChange={(e) => handle(e)} id="Designation" value={data.Designation} name="Designation" className="form-control" type="text" required placeholder="Customer" />
          <br></br>
        </div>


        <div className="form-check">
          <input type="checkbox" name="IsActive" id="IsActive" onChange={(e) => handle(e)} value={data.checked} />
          <label>IsActive</label>
          <br></br> <br></br>
        </div>

        <br></br>


        <button type="submit" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }} > Submit </button>
        <button type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>

      </form>
    </div>
  )
}

export default Testimonial
