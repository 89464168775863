import React, { useState, useEffect, useCallback } from "react";
import { Form, Button } from 'react-bootstrap';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API_REQUEST from '../../../config/client';
import axios from 'axios'
import { Alert, Checkbox } from 'antd';
import CONST_URL from "../../../config/url";


function UpdateCtg(props) {
    const [categoryResult, getCategoryResult] = useState(null)
    const [fetchCtg, getFetchCtg] = useState("");
    const [selectFiles, setSelectedFiles] = useState();
    const [availstatus, getAvailStatus] = useState(false)
    const { ctgId } = props;



    const [cdata, setData] = useState({
        CategoryName: "",
        Slug: "",
        Description: "",
        ShippingCharges: "",
        MetaTitle: "",
        MetaKeyword: "",
        MetaDescription: "",
        FileImage: 0,
        action: "process_category"
    });

    useEffect(() => {
        async function fetchData() {
            var request = {
                action: 'fetchCtg',
                ctgId: ctgId
            }
            await API_REQUEST('fetch', request).then((response) => getFetchCtg(response));
        }
        fetchData();

    }, []);


    useEffect(() => {
        if (fetchCtg.Description !== null) {
            console.log(fetchCtg)
            setData({
                CategoryName: fetchCtg.CategoryName,
                Slug: fetchCtg.Slug,
                Description: fetchCtg.Description,
                ShippingCharges: fetchCtg.ShippingCharges,
                MetaTitle: fetchCtg.MetaTitle,
                MetaKeyword: fetchCtg.MetaKeyword,
                MetaDescription: fetchCtg.MetaDescription,
                IsActive: fetchCtg.IsActive,
                FileImage: 0,
                action: "updatecategory",
                ctgId: ctgId
            })
            getAvailStatus(true)

        }
    }, [fetchCtg]);

    console.log(cdata)

    function handle(e) {
        const newdata = { ...cdata }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const fileChange = (e) => {
        let files = e.target.files
        setSelectedFiles(files)
        setData((data) => ({ ...data, FileImage: 1 }));
    }


    function handleClick(e, editor) {
        setData({ ...cdata, Description: editor.getData() })
    }


    function submit(e) {
        e.preventDefault();
        const file_data = new FormData();
        file_data.append("file", selectFiles)
        console.log(file_data)
        file_data.append('request_data', JSON.stringify(cdata))
        async function processCatForm() {
            console.log(file_data)
            var BASE_URL = 'http://ec2-65-2-147-162.ap-south-1.compute.amazonaws.com/admin_mcube/api/Database/'
            // var BASE_URL = 'http://localhost/admin_mcube/api/Database/'
            var fetchData = axios.post(CONST_URL + 'catalogue.php', file_data).then((response) => getCategoryResult(response.data))
        }
        processCatForm()
    }

    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setData({ ...cdata, IsActive: '1' })
        } else {
            setData({ ...cdata, IsActive: '0' })
        }
    };

    return (
        availstatus &&
        <div className="container_admin admin_body form">

            <Form>
                <br></br>

                <div className="col-sm-5">
                    <label htmlFor="CategoryName">Category Name</label>
                    <input onChange={(e) => handle(e)} id="CategoryName" value={cdata.CategoryName} name="CategoryName" className="form-control" type="text" required />
                    <br></br>

                </div>

                <div className="col-sm-5">
                    <label htmlFor="Slug">Slug</label>
                    <input onChange={(e) => handle(e)} id="Slug" value={cdata.Slug} name="Slug" className="form-control" type="text" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Description</label>
                    <CKEditor id="Description"
                        editor={ClassicEditor}
                        name="Description" onChange={(e, editor) => { handleClick(e, editor); }}
                        onReady={editor => {
                            editor.setData(cdata.Description)
                        }} />
                    <br /><br />
                </div>


                <div className="col-sm-5">
                    <label>Category Image</label>
                    <br></br> <br></br>
                    <input type="file" name="file" accept="image/png,  image/jpeg, image/.webp" id="image_upload" onChange={fileChange}  />
                    <br></br> <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Shipping Charges</label>
                    <br></br>
                    <input onChange={(e) => handle(e)} id="ShippingCharges" value={cdata.ShippingCharges} type="number" className="form-control" name="ShippingCharges" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Meta Title</label>
                    <input onChange={(e) => handle(e)} id="MetaTitle" value={cdata.MetaTitle} type="text" className="form-control" name="MetaTitle" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Meta Keyword</label>
                    <input onChange={(e) => handle(e)} id="MetaKeyword" value={cdata.MetaKeyword} type="text" className="form-control" name="MetaKeyword" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label>Meta Description </label>
                    <textarea onChange={(e) => handle(e)} value={cdata.MetaDescription} id="MetaDescription" className="form-control" rows="3" name="MetaDescription" />
                    <br></br>
                </div>

                <div className="col-sm-5">
                    <label for="scales">IsActive</label>
                    {
                        cdata.IsActive === '1' ?
                            <input
                                type="checkbox"
                                className="checkbox team_values"
                                checked
                                onClick={(e) => {
                                    selectShortlistedApplicant(e);
                                }
                                } /> :
                            <input
                                type="checkbox"
                                className="checkbox team_values"
                                onClick={(e) => {
                                    selectShortlistedApplicant(e);
                                }} />
                    }
                </div>
                <br></br>

                <button className="btn btn-primary button " type="submit" onClick={(e) => submit(e)} style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#4CAF50", color: "white", }}> Update </button>
                <button className="btn btn-primary button " type="reset" style={{ marginRight: 1 + "em", border: "none", backgroundColor: "#FF0000", color: "white", }} > <i className="fas fa-arrow-left"></i> Cancel </button>
                {
                    categoryResult ? <Alert message={categoryResult.message} type="success" /> : <p></p>
                }

            </Form>
        </div>
    )

}
export default UpdateCtg