var CONST_JSON = [{
    "label": "ProductID",
    "key": "ProductID"
}, {
    "label": "Name",
    "key": "Name"
}, {
    "label": "SKU",
    "key": "SKU"
}, {
    "label": "Price",
    "key": "Price"
}, {
    "label": "OfferPrice",
    "key": "OfferPrice"
}, {
    "label": "SellingPrice",
    "key": "SellingPrice"
}, {
    "label": "Image",
    "key": "Image"
}, {
    "label": "Status",
    "key": "Status"
}, {
    "label": "RatingAvg",
    "key": "RatingAvg"
}, {
    "label": "MetaTitle",
    "key": "MetaTitle"
}, {
    "label": "MetaKeyword",
    "key": "MetaKeyWord"
}, {
    "label": "MetaDescription",
    "key": "MetaDescription"
}, {
    "label": "NumberOfView",
    "key": "NumberOfView"
}, {
    "label": "Quantity",
    "key": "Quantity"
}, {
    "label": "IsActive",
    "key": "IsActive"
}, {
    "label": "Barcode",
    "key": "Barcode"
}, {
    "label": "Comment",
    "key": "Comment"
}, {
    "label": "VideoLink",
    "key": "VideoLink"
}, {
    "label": "HsnNo",
    "key": "HsnNo"
}, {
    "label": "cod_charge",
    "key": "cod_charge"
}]

export default CONST_JSON