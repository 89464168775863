import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';
import API_REQUEST from '../../../config/client';
import Get_image from '../../../config/get_image';
import renderHTML from 'react-render-html';
import Get_direct_image from '../../../config/get_direct_image';
import { LoadingOutlined } from '@ant-design/icons';
import ListGroup from 'react-bootstrap/ListGroup';
import { Spin } from 'antd';
import Img from 'react-cool-img';

function Newsletter() {

    // VARIABLES ------------------------------------------------------------------------------
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [news, getNews] = useState(null);
    var Displaypdata = null;
    const [sdata, setData] = useState("");
    const [availstatus, getAvailStatus] = useState(false)
    const [fetchNews, getFetchNews] = useState("");

    // API REQUEST ------------------------------------------------------------------------------

    useEffect(() => {
        var fetchNews = async () => {
            await API_REQUEST('news', {
                action: 'getNews'
            }).then((response) => getNews(response));
        }
        fetchNews();
        getAvailStatus(true)
    }, []);
    if(news != null){
        console.log(news)
        Displaypdata = news.Item.map(i => (
            
            <div className="blog-item">
                <div className="blog-image">
                    <Card.Link href={i.Newslink}>
                        <Img src={Get_direct_image("news", i.NewsThumbnail)} alt="news" debounce={1000}  />
                    </Card.Link>
                </div>
                <div className="blog-text right-blog-text">
                    <p className="blog-cat"><Card.Link href={i.Newslink}>{i.Title}</Card.Link></p>
                    <h3 className="right-blog-h3"><Card.Link target="_blank" href={i.Newslink}>{i.Headline}</Card.Link></h3>
                    <p> {i.Description} </p>
                    <ListGroup horizontal className="post-info">
                        <ListGroup.Item className="post-date">
                            <Card.Link href="#"><span className="fa fa-calendar"></span>{i.CreateDate}</Card.Link>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
        ));
    }

    useEffect(() => {
        var fetchNews = async () => {
            await API_REQUEST('news', {
                action: 'getNews'
            }).then((response) => getNews(response));
        }
        fetchNews();
        getAvailStatus(true)
    }, []);
    


    useEffect(() => {
        if (news !== null) {
            setData({
                Title: news.Data.BigData.Title,
                Headline: news.Data.BigData.Headline,
                Description: renderHTML(news.Data.BigData.Description),
                Newslink: news.Data.BigData.Newslink,
                CreateDate: news.Data.BigData.CreateDate,
                Priority: news.Data.BigData.Priority,
                IsActive: news.Data.BigData.IsActive,
                NewsThumbnail: news.Data.BigData.NewsThumbnail,
                action: "getNews"
            })
            getAvailStatus(true)
        }
        // setData(null);
    }, [news]);


    return (
        availstatus &&
        <>
            <section class="fag-blog-area section_100">


                <div className="container">
                    <Row className="row news-row">
                        <Col lg={12} className="col-12">
                            <div className="site-heading news-site-heading">
                                <h3 className="heading_animation">Latest <span>News</span></h3>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row">
                    <Col lg={6} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-ms-12 col-xs-12 left-col-md-up">
                        <div className="post-large">
                            <div className="blog-item">
                                <div className="blog-image">
                                    <Card.Link href={sdata.Newslink}>
                                        <Img src={Get_direct_image("news", sdata.NewsThumbnail)} alt="hello" debounce={1000} />
                                    </Card.Link>
                                </div>
                                <div className="blog-text left-blog-text">
                                    <p className="blog-cat"><Card.Link href={sdata.Newslink}>{sdata.Title}</Card.Link></p>
                                    <h3 className="left-blog-h3"><Card.Link href={sdata.Newslink}>{sdata.Headline}</Card.Link></h3>
                                    <p className = "news-shortDesc" > {sdata.Description} </p>
                                    <ListGroup horizontal className="post-info border-btm">
                                        <ListGroup.Item className="post-date">
                                            <Card.Link href={sdata.Newslink}><span className="fa fa-calendar"></span>{sdata.CreateDate}</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </Col>

                    
                        <Col lg={6} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-ms-12 col-xs-12 right-col-md-up">
                            <div className="post-small">
                            {
                                Displaypdata  ? Displaypdata : <Spin indicator={antIcon} />
                            }
                            </div>
                        </Col>
                    </Row>


                </div>
            </section>
        </>
    )
}

export default Newsletter;

