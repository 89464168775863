import {React} from 'react';
import Login from '../components/auth/login';
import UniversalNav from '../components/header/navBar_component';
import 'bootstrap-css-only/css/bootstrap.min.css';
import {
    Helmet
} from 'react-helmet';



function LoginPage(){
return(
<>
  <Helmet>
            <title>Buy PS5, PS4, Xbox, PC, Nintendo games and accessories online at Best Price in UAE</title>‍
            <meta name="description" conttent="Shop the top, latest, upcoming, best ps4, ps5, xbox, pc and nintendo games online and get best deals and offers on video games and accessories only on Mcube Games."/>
            <meta name="keywords" content="Shopping, Gaming Store, Playstation Games, Top Games Online, Xbox Games, PS5 Games Purchase, PS4 Games Online, Nintendo Switch, PC Games, Gaming Accessories, Action Figures, Bestselling Games, Mcube, Mcubegames, Mcube Games Online"/>
    </Helmet>
<UniversalNav/>
<Login />
</>
);

}

export default LoginPage;