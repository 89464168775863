import React from "react";
// import "../../assets/css/style.css"



const Topic=(props) =>{
    
    return(

    
        <div class="tab-content">   
        
        <div class="site-heading">
            <h3 class="heading_animation">{props.name1} <span>{props.name2}</span></h3>
        </div>
        </div>


    );
}

export default Topic;